import React from "react";
import Styles from './styles.module.scss';
import Logo from "../_UI/logo";


const AuthHeader = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} $`}>
                <Logo/>
            </div>
        </section>
    );
};

export default AuthHeader;