import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";



const MainHello = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    const btnHandler = () => {
        if (authStatus.phrase){
            if (authStatus.server){
                navigate('/honee');
            } else {
                navigate('/server');
            }
        } else {
            if (authStatus.authorization){
                navigate('/confirmation');
            } else {
                navigate('/sign-in');
            }
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title1}`}>
                    <span>HoneeAI 2.0</span><br/>
                    {t('Простота прибыли для всех')}
                </div>
                <div className={`${Styles.button}`} onClick={()=>{btnHandler()}}>{t('Сила HoneeAI')}</div>
            </div>
        </section>
    );
};

export default MainHello;