import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    setHoneechizeAffiliateSettingsModalStatus,
    setHoneechizeAffiliateTableModalStatus
} from "../../store/cabinet/action";



const HoneechizeAffiliateSettings = () => {
    const honeechizeAffiliateSettings = useSelector(store => store.cabinet.honeechizeAffiliateSettings);
    const userData = useSelector(store => store.main.userData);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const changeModalHandler = (type) =>{
        if (honeechizeAffiliateSettings){
            if (type === 'income_from_revenue_percent'){
                dispatch(setHoneechizeAffiliateSettingsModalStatus(
                    {
                        status: true,
                        data: {
                            type: type,
                            available: honeechizeAffiliateSettings?.settings?.left_percent,
                            min: honeechizeAffiliateSettings?.settings?.[type]?.min,
                            max: honeechizeAffiliateSettings?.settings?.[type]?.max,
                            value: honeechizeAffiliateSettings?.settings?.[type]?.value
                        }
                    }
                ))
            }
            if (type === 'partners_first_deposit_percent'){
                dispatch(setHoneechizeAffiliateSettingsModalStatus(
                    {
                        status: true,
                        data: {
                            type: type,
                            available: honeechizeAffiliateSettings?.settings?.left_percent,
                            min: honeechizeAffiliateSettings?.settings?.[type]?.min,
                            max: honeechizeAffiliateSettings?.settings?.[type]?.max,
                            value: honeechizeAffiliateSettings?.settings?.[type]?.value
                        }
                    }
                ))
            }
            if (type === 'partners_investment_percent'){
                dispatch(setHoneechizeAffiliateSettingsModalStatus(
                    {
                        status: true,
                        data: {
                            type: type,
                            available: honeechizeAffiliateSettings?.settings?.left_percent,
                            min: honeechizeAffiliateSettings?.settings?.[type]?.min,
                            max: honeechizeAffiliateSettings?.settings?.[type]?.max,
                            value: honeechizeAffiliateSettings?.settings?.[type]?.value
                        }
                    }
                ))
            }
        }

    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Настройки партнерской программы')}:</div>
                        {userData ? userData?.honeechize_partner ? <div/> : <div className={`${Styles.available}`}>{honeechizeAffiliateSettings ? honeechizeAffiliateSettings?.settings?.left_percent : '0'}% {t('доступно')}</div> : <div/>}
                        <div className={`${Styles.tableBtn}`}>{t('Чтобы увидеть таблицу')}, <span onClick={()=>{dispatch(setHoneechizeAffiliateTableModalStatus(true))}}>{t('нажмите здесь')}</span></div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div className={`${Styles.contentBoxItem}`}>
                            <div className={`${Styles.contentBoxItemName}`}>{userData ? userData?.honeechize_partner ? `${t('Процент дохода от выручки')}:` : `${t('Настроить процент дохода от выручки')}:` : null}</div>
                            <div className={`${Styles.contentBoxItemValue}`}>{honeechizeAffiliateSettings ? honeechizeAffiliateSettings?.settings?.income_from_revenue_percent?.value : '0'}%</div>
                            {userData ? userData?.honeechize_partner ? null : <div className={`${Styles.contentBoxItemBtn}`} onClick={()=>{changeModalHandler('income_from_revenue_percent')}}>{t('Изменить')}</div> : null}
                        </div>
                        <div className={`${Styles.contentBoxItem}`}>
                            <div className={`${Styles.contentBoxItemName}`}>{userData ? userData?.honeechize_partner ? `${t('Выплата с первого депозита партнёра')}:` : `${t('Настроить выплату с первого депозита партнёра')}:` : null}</div>
                            <div className={`${Styles.contentBoxItemValue}`}>{honeechizeAffiliateSettings ? honeechizeAffiliateSettings?.settings?.partners_first_deposit_percent?.value : '0'}%</div>
                            {userData ? userData?.honeechize_partner ? null : <div className={`${Styles.contentBoxItemBtn}`} onClick={()=>{changeModalHandler('partners_first_deposit_percent')}}>{t('Изменить')}</div> : null}
                        </div>
                        <div className={`${Styles.contentBoxItem}`}>
                            <div className={`${Styles.contentBoxItemName}`}>{userData ? userData?.honeechize_partner ? `${t('Выплата от инвестиций партнёров')}:` : `${t('Настроить дополнительную прибыль HoneeAI')}:` : null}</div>
                            <div className={`${Styles.contentBoxItemValue}`}>{honeechizeAffiliateSettings ? honeechizeAffiliateSettings?.settings?.partners_investment_percent?.value : '0'}%</div>
                            {userData ? userData?.honeechize_partner ? null : <div className={`${Styles.contentBoxItemBtn}`} onClick={()=>{changeModalHandler('partners_investment_percent')}}>{t('Изменить')}</div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HoneechizeAffiliateSettings;
