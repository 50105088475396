import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import NewsLatest from "../../components/newsLatest";
import NewsMain from "../../components/newsMain";
import {getLatestNews, getMainNews, getOmtPrice} from "../../store/main/action";


const NewsPage = () => {
    const dispatch = useDispatch();
    let {page} = useParams();


    
    useEffect(()=>{
        dispatch(getOmtPrice());
        dispatch(getLatestNews());
        dispatch(getMainNews(page));
    },[dispatch, page])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'News'}/>
            <NewsLatest/>
            <NewsMain/>
        </section>
    );
};

export default NewsPage;