import React, {useCallback, useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    changeHoneechizeAffiliatePercent,
    changeHoneechizeAffiliatePercentSuccess,
    setHoneechizeAffiliateSettingsModalStatus
} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";


const HoneechizeAffiliateSettingsModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const honeechizeModalStatus = useSelector(store => store.cabinet.honeechizeAffiliateSettingsModalStatus);
    const changePercentStatus = useSelector(store => store.cabinet.changeHoneechizeAffiliatePercent);

    const [currentPercent, setCurrentPercent] = useState(honeechizeModalStatus?.data?.value || honeechizeModalStatus?.data?.value === 0 ? String(honeechizeModalStatus?.data?.value) : '')

    const closeModal = useCallback(() =>{
        dispatch(setHoneechizeAffiliateSettingsModalStatus({status: false, data: false}))
    }, [dispatch])

    const clamp = (min, max, value)=> {
        if (value === ''){
            return ''
        } else {
            value = Math.round(value);
            return String(value);
        }
    }

    const sendValueStatusHandler = () =>{
        return currentPercent !== '' && currentPercent !== String(honeechizeModalStatus?.data?.value) && Number(currentPercent) >= Number(honeechizeModalStatus?.data?.min) && Number(currentPercent) <= Number(honeechizeModalStatus?.data?.max)
    }

    const sendHandler = () =>{
        if (sendValueStatusHandler()){
            dispatch(changeHoneechizeAffiliatePercent(honeechizeModalStatus?.data?.type, currentPercent));
        }
    }
    
    useEffect(()=>{
        if (changePercentStatus){
            closeModal();
            dispatch(changeHoneechizeAffiliatePercentSuccess(false));
        }
    },[changePercentStatus, closeModal, dispatch])
    
    useEffect(()=>{
        if (honeechizeModalStatus?.data?.value || honeechizeModalStatus?.data?.value === 0){
            setCurrentPercent(String(honeechizeModalStatus?.data?.value))
        }
    },[honeechizeModalStatus])


    return (
        <Popup
            open={honeechizeModalStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Установить сумму выплаты')}:</div>
                    <div className={`${Styles.available}`}>{honeechizeModalStatus?.data?.available}% {t('доступно')}</div>
                    <div className={`${Styles.inputWrap}`}>
                        <input
                            type="text"
                            onChange={(e)=>{setCurrentPercent(clamp(honeechizeModalStatus?.data?.min, honeechizeModalStatus?.data?.max, e.target.value.replace(/\D/g, '')))}}
                            value={currentPercent}
                        />
                        <div className={`${Styles.inputNote}`}>{t('Мин')}: <span>{honeechizeModalStatus?.data?.min}%</span> / {t('Макс')}: <span>{honeechizeModalStatus?.data?.max}%</span></div>
                    </div>
                    <div
                        className={`${Styles.saveBtn} ${!sendValueStatusHandler() ? Styles.disBtn : null}`}
                        onClick={()=>{sendHandler()}}
                    >
                        {t('Установить')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default HoneechizeAffiliateSettingsModal;
