import React from "react";
import Styles from './styles.module.scss';

import stockBinanceIcon from '../../../theme/assets/stocks/h2_stock_exchange_binance_icon.svg';
import stockByBitIcon from '../../../theme/assets/stocks/h2_stock_exchange_bybit_icon.svg';

import stockArrow from '../../../theme/assets/svg/h2_stock_arrow.svg';

import stock3exIcon from '../../../theme/assets/stocks/h2_stock_3ex_icon.svg';
import stock4eIcon from '../../../theme/assets/stocks/h2_stock_4e_icon.svg';

import stockApolloxIcon from '../../../theme/assets/stocks/h2_stock_apollox_icon.svg';
import stockBalancerIcon from '../../../theme/assets/stocks/h2_stock_balancer_icon.svg';
import stockBikaIcon from '../../../theme/assets/stocks/h2_stock_bika_icon.svg';
import stockBikingIcon from '../../../theme/assets/stocks/h2_stock_biking_icon.svg';
import stockBitgetIcon from '../../../theme/assets/stocks/h2_stock_bitget_icon.svg';
import stockBitrueIcon from '../../../theme/assets/stocks/h2_stock_bitrue_icon.svg';
import stockBlofinIcon from '../../../theme/assets/stocks/h2_stock_blofin_icon.svg';
import stockBluebitIcon from '../../../theme/assets/stocks/h2_stock_bluebit_icon.svg';
import stockBtccIcon from '../../../theme/assets/stocks/h2_stock_btcc_icon.svg';
import stockBvoxIcon from '../../../theme/assets/stocks/h2_stock_bvox_icon.svg';
import stockBydfiIcon from '../../../theme/assets/stocks/h2_stock_bydfi_icon.svg';
import stockCamelotIcon from '../../../theme/assets/stocks/h2_stock_camelot-v3_icon.svg';
import stockCoin8Icon from '../../../theme/assets/stocks/h2_stock_coin8-exchange_icon.svg';
import stockCoinupIcon from '../../../theme/assets/stocks/h2_stock_coinup-io_icon.svg';
import stockCoinWIcon from '../../../theme/assets/stocks/h2_stock_coinw_icon.svg';
import stockCryptocomIcon from '../../../theme/assets/stocks/h2_stock_crypto-com-exchange_icon.svg';
import stockCurveIcon from '../../../theme/assets/stocks/h2_stock_curve-finance_icon.svg';
import stockDeepcoinIcon from '../../../theme/assets/stocks/h2_stock_deepcoin_icon.svg';
import stockDigifinexIcon from '../../../theme/assets/stocks/h2_stock_digifinex_icon.svg';
import stockDoexIcon from '../../../theme/assets/stocks/h2_stock_doex_icon.svg';

import stockDydxIcon from '../../../theme/assets/stocks/h2_stock_exchange_dydx_icon.svg';
import stockWoofiIcon from '../../../theme/assets/stocks/h2_stock_exchange_woofi_icon.svg';
import stockZedcexIcon from '../../../theme/assets/stocks/h2_stock_exchange_zedcex-exchange_icon.svg';
import stockZedxionIcon from '../../../theme/assets/stocks/h2_stock_exchange_zedxion-exchange_icon.svg';
import stockZoomexIcon from '../../../theme/assets/stocks/h2_stock_exchange_zoomex_icon.svg';
import stockHotcoinIcon from '../../../theme/assets/stocks/h2_stock_hotcoin-global_icon.svg';
import stockHyperliquidIcon from '../../../theme/assets/stocks/h2_stock_hyperliquid_icon.svg';
import stockInjectiveIcon from '../../../theme/assets/stocks/h2_stock_injective-protocol_icon.svg';

import stockJupiterIcon from '../../../theme/assets/stocks/h2_stock_jupiter_icon.svg';
import stockKcexIcon from '../../../theme/assets/stocks/h2_stock_kcex_icon.svg';
import stockKineIcon from '../../../theme/assets/stocks/h2_stock_kine-protocol_icon.svg';
import stockLbankIcon from '../../../theme/assets/stocks/h2_stock_lbank_icon.svg';
import stockMeteoraIcon from '../../../theme/assets/stocks/h2_stock_meteora-vd_icon.svg';
import stockOkxIcon from '../../../theme/assets/stocks/h2_stock_okx_icon.svg';
import stockOrcaIcon from '../../../theme/assets/stocks/h2_stock_orca_icon.svg';
import stockPancakeIcon from '../../../theme/assets/stocks/h2_stock_pancake_icon.svg';
import stockPerpetualIcon from '../../../theme/assets/stocks/h2_stock_perpetual-protocol_icon.svg';
import stockQuickswapIcon from '../../../theme/assets/stocks/h2_stock_quickswap_icon.svg';
import stockRamsesIcon from '../../../theme/assets/stocks/h2_stock_ramses_icon.svg';
import stockRaydiumIcon from '../../../theme/assets/stocks/h2_stock_raydium_icon.svg';
import stockSunfuturesIcon from '../../../theme/assets/stocks/h2_stock_synfutures-v3_icon.svg';
import stockToobitIcon from '../../../theme/assets/stocks/h2_stock_toobit_icon.svg';
import stockTraderjoeIcon from '../../../theme/assets/stocks/h2_stock_trader-joe-v2-1-avalanche_icon.svg';
import stockTrubitIcon from '../../../theme/assets/stocks/h2_stock_TruBit_icon.svg';
import stockUniswapIcon from '../../../theme/assets/stocks/h2_stock_uniswap_icon.svg';
import stockVertexIcon from '../../../theme/assets/stocks/h2_stock_vertex-protocol_icon.svg';
import stockWeexIcon from '../../../theme/assets/stocks/h2_stock_weex_icon.svg';
import stockWisebitcoinIcon from '../../../theme/assets/stocks/h2_stock_wisebitcoin_icon.svg';
import stockFameexIcon from '../../../theme/assets/stocks/h2_stock_fameex_icon.svg';
import stockBxtenIcon from '../../../theme/assets/stocks/h2_stock_bxten_icon.svg';
import stockCetusIcon from '../../../theme/assets/stocks/h2_stock_cetus_icon.svg';
import stockThorchainIcon from '../../../theme/assets/stocks/h2_stock_fameex_icon.svg';



const StocksContent = (props) => {
    const {firstStock, lastStock, firstCategory, lastCategory, firstNameStock, lastNameStock} = props;


    const stockIconHandler = (stockName) =>{
        switch (stockName.toLowerCase()){
            case 'pancakeswap-v2':
                return (
                    <img src={stockPancakeIcon} alt="pancakeswap-v2"/>
                );
            case 'raydium-clmm':
                return (
                    <img src={stockRaydiumIcon} alt="raydium-clmm"/>
                );
            case 'raydium':
                return (
                    <img src={stockRaydiumIcon} alt="raydium"/>
                );
            case 'vertex-protocol':
                return (
                    <img src={stockVertexIcon} alt="vertex-protocol"/>
                );
            case 'synfutures-v3':
                return (
                    <img src={stockSunfuturesIcon} alt="synfutures-v3"/>
                );
            case 'weex':
                return (
                    <img src={stockWeexIcon} alt="weex"/>
                );
            case 'dydx-v4':
                return (
                    <img src={stockDydxIcon} alt="dydx-v4"/>
                );
            case 'dydx':
                return (
                    <img src={stockDydxIcon} alt="dydx"/>
                );
            case 'binance':
                return (
                    <img src={stockBinanceIcon} alt="Binance"/>
                );
            case 'bybit':
                return (
                    <img src={stockByBitIcon} alt="Bybit"/>
                );
            case 'zedxion-exchange':
                return (
                    <img src={stockZedxionIcon} alt="zedxion-exchange"/>
                );
            case 'zedcex-exchange':
                return (
                    <img src={stockZedcexIcon} alt="zedcex-exchange"/>
                );
            case 'zoomex':
                return (
                    <img src={stockZoomexIcon} alt="zoomex"/>
                );
            case 'woofi':
                return (
                    <img src={stockWoofiIcon} alt="woofi"/>
                );
            case 'uniswap-v3-polygon':
                return (
                    <img src={stockUniswapIcon} alt="uniswap-v3-polygon"/>
                );
            case 'uniswap-v3':
                return (
                    <img src={stockUniswapIcon} alt="uniswap-v3"/>
                );
            case 'uniswap-v3-arbitrum':
                return (
                    <img src={stockUniswapIcon} alt="uniswap-v3-arbitrum"/>
                );
            case 'uniswap-v2':
                return (
                    <img src={stockUniswapIcon} alt="uniswap-v2"/>
                );
            case 'trubit-exchange':
                return (
                    <img src={stockTrubitIcon} alt="trubit-exchange"/>
                );
            case 'trader-joe-v2-1-avalanche':
                return (
                    <img src={stockTraderjoeIcon} alt="trader-joe-v2-1-avalanche"/>
                );
            case 'toobit':
                return (
                    <img src={stockToobitIcon} alt="toobit"/>
                );
            case 'perpetual-protocol':
                return (
                    <img src={stockPerpetualIcon} alt="perpetual-protocol"/>
                );
            case 'quickswap-v3':
                return (
                    <img src={stockQuickswapIcon} alt="quickswap-v3"/>
                );
            case 'ramses-v2':
                return (
                    <img src={stockRamsesIcon} alt="ramses-v2"/>
                );
            case 'pancakeswap-v3':
                return (
                    <img src={stockPancakeIcon} alt="pancakeswap-v3"/>
                );
            case 'kine-protocol':
                return (
                    <img src={stockKineIcon} alt="kine-protocol"/>
                );
            case 'kcex':
                return (
                    <img src={stockKcexIcon} alt="kcex"/>
                );
            case 'jupiter':
                return (
                    <img src={stockJupiterIcon} alt="jupiter"/>
                );
            case 'pancake-v3-arbitrum':
                return (
                    <img src={stockPancakeIcon} alt="pancake-v3-arbitrum"/>
                );
            case 'orca':
                return (
                    <img src={stockOrcaIcon} alt="orca"/>
                );
            case 'hyperliquid':
                return (
                    <img src={stockHyperliquidIcon} alt="hyperliquid"/>
                );
            case 'hotcoin':
                return (
                    <img src={stockHotcoinIcon} alt="hotcoin"/>
                );
            case 'hotcoin-global':
                return (
                    <img src={stockHotcoinIcon} alt="hotcoin-global"/>
                );
            case 'okx':
                return (
                    <img src={stockOkxIcon} alt="okx"/>
                );
            case 'meteora-vd':
                return (
                    <img src={stockMeteoraIcon} alt="meteora-vd"/>
                );
            case 'lbank':
                return (
                    <img src={stockLbankIcon} alt="lbank"/>
                );
            case 'injective-protocol':
                return (
                    <img src={stockInjectiveIcon} alt="injective-protocol"/>
                );
            case 'digifinex':
                return (
                    <img src={stockDigifinexIcon} alt="digifinex"/>
                );
            case 'deepcoin':
                return (
                    <img src={stockDeepcoinIcon} alt="deepcoin"/>
                );
            case 'doex':
                return (
                    <img src={stockDoexIcon} alt="doex"/>
                );
            case 'crypto-com-exchange':
                return (
                    <img src={stockCryptocomIcon} alt="crypto-com-exchange"/>
                );
            case 'curve-finance':
                return (
                    <img src={stockCurveIcon} alt="curve-finance"/>
                );
            case 'coinw':
                return (
                    <img src={stockCoinWIcon} alt="coinw"/>
                );
            case 'coinup-io':
                return (
                    <img src={stockCoinupIcon} alt="coinup-io"/>
                );
            case 'coin8-exchange':
                return (
                    <img src={stockCoin8Icon} alt="coin8-exchange"/>
                );
            case 'camelot-v3':
                return (
                    <img src={stockCamelotIcon} alt="camelot-v3"/>
                );
            case 'bluebit':
                return (
                    <img src={stockBluebitIcon} alt="bluebit"/>
                );
            case 'blofin':
                return (
                    <img src={stockBlofinIcon} alt="blofin"/>
                );
            case 'bitrue':
                return (
                    <img src={stockBitrueIcon} alt="bitrue"/>
                );
            case 'bitget':
                return (
                    <img src={stockBitgetIcon} alt="bitget"/>
                );
            case 'biking':
                return (
                    <img src={stockBikingIcon} alt="biking"/>
                );
            case 'wisebitcoin':
                return (
                    <img src={stockWisebitcoinIcon} alt="wisebitcoin"/>
                );
            case 'balancer-v2':
                return (
                    <img src={stockBalancerIcon} alt="balancer-v2"/>
                );
            case 'bydfi':
                return (
                    <img src={stockBydfiIcon} alt="bydfi"/>
                );
            case 'bvox':
                return (
                    <img src={stockBvoxIcon} alt="bvox"/>
                );
            case 'btcc':
                return (
                    <img src={stockBtccIcon} alt="btcc"/>
                );
            case 'bika':
                return (
                    <img src={stockBikaIcon} alt="bika"/>
                );
            case 'apollox-v2-bsc':
                return (
                    <img src={stockApolloxIcon} alt="apollox-v2-bsc"/>
                );
            case 'apollox':
                return (
                    <img src={stockApolloxIcon} alt="apollox"/>
                );
            case '4e':
                return (
                    <img src={stock4eIcon} alt="4e"/>
                );
            case '3ex':
                return (
                    <img src={stock3exIcon} alt="3ex"/>
                );
            case 'fameex':
                return (
                    <img src={stockFameexIcon} alt="fameex"/>
                );
            case 'bxten':
                return (
                    <img src={stockBxtenIcon} alt="bxten"/>
                );
            case 'uniswap-v3-base':
                return (
                    <img src={stockUniswapIcon} alt="uniswap-v3-base"/>
                );
            case 'cetus':
                return (
                    <img src={stockCetusIcon} alt="etus"/>
                );
            case 'thorchain-erc20':
                return (
                    <img src={stockThorchainIcon} alt="thorchain-erc20"/>
                );
            default:
                return (
                    <img src={stockByBitIcon} alt="sol"/>
                );
        }
    }

    return (
        <div className={`${Styles.container}`}>
            <div className={`${Styles.stockWrap}`}>
                <div className={`${Styles.stock}`}>
                    <div className={`${Styles.stockIcon}`}>{stockIconHandler(firstStock)}</div>
                    <div className={`${Styles.stockNameWrap}`}>
                        <div className={`${Styles.stockName}`}>{firstNameStock}</div>
                        <div className={`${Styles.stockCategory}`}>{firstCategory}</div>
                    </div>

                </div>
                <div className={`${Styles.category}`}>
                    {firstCategory}
                </div>
            </div>
            <div className={`${Styles.separator}`}>
                <img src={stockArrow} alt="arrow"/>
            </div>
            <div className={`${Styles.stockWrap}`}>
                <div className={`${Styles.stock}`}>
                    <div className={`${Styles.stockIcon}`}>{stockIconHandler(lastStock)}</div>
                    <div className={`${Styles.stockNameWrap}`}>
                        <div className={`${Styles.stockName}`}>{lastNameStock}</div>
                        <div className={`${Styles.stockCategory}`}>{lastCategory}</div>
                    </div>
                </div>
                <div className={`${Styles.category}`}>
                    {lastCategory}
                </div>
            </div>
        </div>
    );
};

export default StocksContent;
