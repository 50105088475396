import React from "react";
import Styles from './styles.module.scss'
import logo from "../../theme/assets/svg/honee_logo.svg";
// import nyLogo from '../../theme/assets/svg/omt_ny_logo.svg';
import { useTranslation } from "react-i18next";

import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setMobileWpNavStatus} from "../../store/main/action";
import liIcon from "../../theme/assets/svg/honee_main_soc_li.svg";
import instIcon from "../../theme/assets/svg/honee_main_soc_inst.svg";
import twIcon from "../../theme/assets/svg/honee_main_soc_tw.svg";
import telIcon from "../../theme/assets/svg/honee_main_soc_tel.svg";
import ytIcon from "../../theme/assets/svg/honee_main_soc_yt.svg";
import disIcon from "../../theme/assets/svg/honee_main_soc_dis.svg";


const WhitePaperNavigation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mobileNavStatus = useSelector(store => store.main.mobileWpNavStatus);
    const { t } = useTranslation();

    const pageHandler = (page) =>{
        return window.location.pathname.indexOf(page) !== -1
    }

    const titlePageHandler = (page) =>{
        return window.location.pathname.indexOf(page) !== -1 && window.location.pathname.length - 1 === window.location.pathname.lastIndexOf('/') + page.length
    }

    return (
        <section className={`${Styles.container} ${mobileNavStatus ? Styles.containerActive : null}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.logoWrap}`} onClick={()=>{navigate('/')}}>
                    <img src={logo} alt="honee2 logotype"/>
                </div>
                <div className={`${Styles.navWrap}`}>
                    <div
                        className={`${Styles.navItem} ${pageHandler('executive-summary') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/executive-summary');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        Executive Summary
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('mission') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/mission');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        Mission of OMT
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('history') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/history');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        History of OMT
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('growth-strategy') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/growth-strategy');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        Growth Strategy of OMT
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('community') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/community');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        The OMT community
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('market-challenges') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/market-challenges');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        Market Challenges
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('roadmap') ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/white-paper/roadmap');
                            dispatch(setMobileWpNavStatus(false));
                        }}
                    >
                        Roadmap
                    </div>
                    <div className={`${Styles.navBoxWrap}`}>
                        <div className={`${Styles.navBox}`}>
                            <div
                                className={`${Styles.navBoxTitle} ${titlePageHandler('honee-ai') ? Styles.navItemActive : null}`}
                                onClick={()=>{
                                    navigate('/white-paper/honee-ai');
                                    dispatch(setMobileWpNavStatus(false));
                                }}
                            >
                                HoneeAi
                            </div>
                            <div className={`${Styles.navBoxContent}`}>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('technical-part') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/technical-part');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Technical part
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('analysing') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/analysing');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Analysing
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('money-flow-index') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/money-flow-index');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Money Flow index (MFI)
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('parabolic-sar') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/parabolic-sar');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Parabolic SAR
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('method-of-trading') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/method-of-trading');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Method of trading
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('relative-average-conditions') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/relative-average-conditions');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Relative-average conditions
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('on-balance-volume') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/on-balance-volume');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    On Balance Volume (OBV)
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('ichimoku-kinko-hyo') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/ichimoku-kinko-hyo');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Ichimoku Kinko Hyo (IKH)
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('elders-force-index') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/honee-ai/elders-force-index');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Elder’s Force Index (EFI)
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.navBox}`}>
                            <div
                                className={`${Styles.navBoxTitle} ${titlePageHandler('market-analysis') ? Styles.navItemActive : null}`}
                                onClick={()=>{
                                    navigate('/white-paper/market-analysis');
                                    dispatch(setMobileWpNavStatus(false));
                                }}
                            >
                                Market Analysis
                            </div>
                            <div className={`${Styles.navBoxContent}`}>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('market-status') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/market-analysis/market-status');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Current Market Status
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('advantages') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/market-analysis/advantages');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Advantages of Crypto
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('development-trends') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/market-analysis/development-trends');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Market Development Trends
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('forecast') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/market-analysis/forecast');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Forecast
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.navBox}`}>
                            <div
                                className={`${Styles.navBoxTitle} ${titlePageHandler('omt-ecosystem') ? Styles.navItemActive : null}`}
                                onClick={()=>{
                                    navigate('/white-paper/omt-ecosystem');
                                    dispatch(setMobileWpNavStatus(false));
                                }}
                            >
                                OMT Ecosystem
                            </div>
                            <div className={`${Styles.navBoxContent}`}>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('overview') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/omt-ecosystem/overview');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Overview
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('omt-staking') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/omt-ecosystem/omt-staking');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    OMT Staking
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('staking-of-third-party-crypto') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/omt-ecosystem/staking-of-third-party-crypto');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Staking of Third-Party Crypto
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('index-omt20') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/omt-ecosystem/index-omt20');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Index OMT20
                                </div>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('omt-ecosystem/honee-ai') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/omt-ecosystem/honee-ai');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    HoneeAi
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.navBox}`}>
                            <div
                                className={`${Styles.navBoxTitle} ${titlePageHandler('tokenomics') ? Styles.navItemActive : null}`}
                                onClick={()=>{
                                    navigate('/white-paper/tokenomics');
                                    dispatch(setMobileWpNavStatus(false));
                                }}
                            >
                                Tokenomics
                            </div>
                            <div className={`${Styles.navBoxContent}`}>
                                <div
                                    className={`${Styles.navBoxContentItem} ${pageHandler('deflationary-model') ? Styles.navItemActive : null}`}
                                    onClick={()=>{
                                        navigate('/white-paper/tokenomics/deflationary-model');
                                        dispatch(setMobileWpNavStatus(false));
                                    }}
                                >
                                    Deflationary Model
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.socialsWrap}`}>
                    <div className={`${Styles.socialsTitle}`}>{t('Социальные сети')}</div>
                    <div className={`${Styles.socialsBox}`}>
                        <div className={`${Styles.socItem}`}>
                            <img src={liIcon} alt="LinkedIn"/>
                        </div>
                        <div className={`${Styles.socItem}`}>
                            <img src={instIcon} alt="Instagram"/>
                        </div>
                        <div className={`${Styles.socItem}`}>
                            <img src={twIcon} alt="Twitter"/>
                        </div>
                        <div className={`${Styles.socItem}`}>
                            <img src={telIcon} alt="Telegram"/>
                        </div>
                        <div className={`${Styles.socItem}`}>
                            <img src={ytIcon} alt="YouTube"/>
                        </div>
                        <div className={`${Styles.socItem}`}>
                            <img src={disIcon} alt="Discord"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperNavigation;
