import React from "react";
import Styles from './styles.module.scss';

import earnedIcon from '../../theme/assets/other/honee_cabinet_bot_earned_icon.png';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {floorToDecimals} from "../../helpers/floorHandler";


const CabinetBotStatistic = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const botMainData = useSelector(store => store.cabinet.botMainData);

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.titleWSF}`}>{t('Статистика')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>{t('Баланс')}:</div>
                        <div className={`${Styles.contentBoxItemTotal}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={earnedIcon} alt="Balance"/>
                            </div>
                            <div className={`${Styles.value}`}>{botMainData?.wallet?.amount ? floorToDecimals(Number(botMainData?.wallet?.amount), 2) : '0'}</div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>{t('В работе')}:</div>
                        <div className={`${Styles.contentBoxItemTotal}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={earnedIcon} alt="In work"/>
                            </div>
                            <div className={`${Styles.value}`}>{botMainData?.bot?.amount ? floorToDecimals(Number(botMainData?.bot?.amount), 2) : '0'}</div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.button}`} onClick={()=>{navigate('/exchange')}}>{t('Обмен')}</div>
            </div>
        </section>
    );
};

export default CabinetBotStatistic;