import React from "react";
import Styles from './styles.module.scss'
import Slider from "react-slick";
import {useDispatch, useSelector} from "react-redux";
import {
    setCurrentWallet,
} from "../../store/cabinet/action";

import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import WalletBtns from "../_UI/walletBtns";

// import importIcon from '../../theme/assets/svg/h2_import_icon.svg';
import {floorToDecimals} from "../../helpers/floorHandler";


const WalletMain = (props) => {
    const dispatch = useDispatch();
    const {posStatus} = props
    const availableWallets = useSelector(store => store.cabinet.availableWallets);

    const { t } = useTranslation();

    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        beforeChange: (current, next) => dispatch(setCurrentWallet(next)),
        // afterChange: current => console.log({ activeSlide2: current })
    };

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody} ${posStatus ? Styles.contentWrapP : null}`}>
                <div className={`${Styles.cardWrap}`}>
                    {availableWallets?.length > 0 ?
                        <Slider {...settings}>
                            {availableWallets?.map((item, index)=>{
                                return (
                                    <div
                                        className={`${Styles.cardWrapItem}`}
                                        key={'activeWallets' + index}
                                    >
                                        <div
                                            className={`${Styles.cardWrapBgBox}`}
                                        >
                                            <div className={`${Styles.titleRow}`}>
                                                <div className={`${Styles.titleWSF}`}>{item.name}</div>
                                                <div className={`${Styles.settingsBtn}`}>
                                                    <span className={`${Styles.settingsBtnItem}`}/>
                                                    <span className={`${Styles.settingsBtnItem}`}/>
                                                    <span className={`${Styles.settingsBtnItem}`}/>
                                                </div>
                                            </div>
                                            <div className={`${Styles.balanceRow}`}>
                                                <div className={`${Styles.balance}`}>{item.amount ? floorToDecimals(item.amount * 1, 4) : '0'} {item.currency?.short_name}</div>
                                            </div>
                                            <WalletBtns currentWallet={item}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider> :
                        <div className={`${Styles.cardWrapItem}`}>
                            <div  className={`${Styles.cardWrapBgBoxFE}`}>
                                <EmptyValues text={t('У вас пока что нет добавленных кошельков')} withIcon={true} withBtn={false} type='walletAssets'/>
                            </div>
                        </div>}

                </div>
                {/*onClick={()=>{dispatch(setWalletCreateFormStatus(true))}}*/}
                {/*<div className={`${Styles.addBtn}`}>*/}
                {/*    <div className={`${Styles.soon}`}>{t('Скоро')}</div>*/}
                {/*    <div className={`${Styles.addBtnWrap}`}>*/}
                {/*        <div className={`${Styles.addBtnPlus}`}>*/}
                {/*            <img src={importIcon} alt="Import Wallet"/>*/}
                {/*        </div>*/}
                {/*        <div className={`${Styles.addBtnContent}`}>{t('Импортировать кошелек')}</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </section>
    );
};

export default WalletMain;
