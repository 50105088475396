import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import CryptoIcon from "../_UI/cryptoIcon";
import {useNavigate} from "react-router-dom";
import backIcon from "../../theme/assets/svg/omt_btn_back_arrow.svg";
import WalletBtns from "../_UI/walletBtns";




const StandardWalletMain = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const currentWallet = useSelector(store => store.cabinet.currentWallet);
    
    const [data, setData] = useState(currentWallet ? currentWallet : null);
    
    useEffect(()=>{
        if (currentWallet){
            setData(currentWallet);
        }
    },[currentWallet])
    


    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.absBackBtn}`} onClick={()=>{navigate('/wallet')}}>
                    <img src={backIcon} alt="back arrow"/>
                    <span>{t('Назад')}</span>
                </div>
                <div className={`${Styles.iconWrap}`}>
                    <CryptoIcon short_name={data?.currency?.short_name} bigSize={true}/>
                </div>
                <div className={`${Styles.sum}`}>{data?.amount * 1} {data?.currency?.short_name}</div>
                <WalletBtns currentWallet={data}/>
            </div>
        </section>
    );
};

export default StandardWalletMain;
