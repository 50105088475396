import React from "react";
import Styles from './styles.module.scss'
import ReactECharts from 'echarts-for-react';

import * as echarts from 'echarts/core';
import {useTranslation} from "react-i18next";



const StandardWalletGraph = (props) => {
    const {isToken, data, graphData} = props;
    const { t } = useTranslation();

    const option = {
        color: [`${isToken ? '#F7931A' : '#03b759'}`],
        // responsive: true,
        // maintainAspectRatio: false,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: false,
        grid: {
            left: '0',
            right: '0',
            bottom: '0',
            height: '190px',
            containLabel: false
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: graphData ? graphData?.names : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                show: false,
                splitLine: {
                    show: false
                }
            },
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: false
                }
            }
        ],
        series: [
            {
                name: 'Value (USD)',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    color: `${isToken ? '#F7931A' : '#03b759'}`,
                    width: 2
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: `${isToken ? 'rgba(101,78,50,255)' : 'rgba(46,93,68,255)'}`
                        },
                        {
                            offset: 1,
                            color: `${isToken ? 'rgba(36,24,9,255)' : 'rgba(8,35,21,255)'}`
                        }
                    ])
                },
                emphasis: {
                    focus: 'series'
                },
                data: graphData ? graphData?.values : data.name === 'Honee' ? [1, 1, 1, 1, 1, 1, 1] : [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    };


    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`} style={{borderColor: `${isToken ? '#F7931A' : '#8C9497'}`}}>
                <div className={`${Styles.cardHeader}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Токен')}: <span className={`${isToken ? Styles.colorOrange : Styles.colorGreen}`}>{data?.name}</span></div>
                        <div className={`${Styles.title3}`}>{data?.rate} USDT</div>
                    </div>
                </div>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.chartWrap}`}>
                        {data?.name === 'OMT' ? <div className={`${Styles.soonNote}`}>{t('Скоро будет доступно')}</div> : <ReactECharts option={option} style={{ height: '210px', width: '100%'}}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StandardWalletGraph;
