import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from "./rootReducer";
import { compose } from "redux";
import { thunk } from 'redux-thunk'

const middleware = [
    applyMiddleware(thunk),
    ...(window.__REDUX_DEVTOOLS_EXTENSION__ ? [window.__REDUX_DEVTOOLS_EXTENSION__()] : [])
]
export const store = createStore(
    rootReducer,
    {},
    compose(...middleware)
)