import React from "react";
import Styles from './styles.module.scss'
import {
    createCurrentWalletSuccess,
    setWalletSendFormStatus,
    setWalletTakeFormStatus
} from "../../../store/cabinet/action";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

import sendIcon from '../../../theme/assets/svg/h2_send_icon.svg';
import receiveIcon from '../../../theme/assets/svg/h2_receive_icon.svg';
import exchangeIcon from '../../../theme/assets/svg/h2_exchange_icon.svg';



const WalletBtns = (props) => {
    const {currentWallet} = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const btnContentHandler = () =>{
        switch (String(currentWallet?.currency?.id)){
            // case '3':
            //     return <div className={`${Styles.textWrap}`}>
            //         Oracle Meta Technologies
            //     </div>
            case '4':
                return <div className={`${Styles.btnWrap} ${Styles.btnWrapOE}`}>
                    <div
                        className={`${Styles.btnWrapItem}`}
                        onClick={()=>{
                            navigate('/exchange');
                        }}>
                        <div className={`${Styles.btnWrapItemImgWrap}`}>
                            <img src={exchangeIcon} alt="exchange"/>
                        </div>
                        <div className={`${Styles.btnWrapItemContent}`}>{t('Обмен')}</div>
                    </div>
                </div>
            default:
                return <div className={`${Styles.btnWrap}`}>
                    <div
                        className={`${Styles.btnWrapItem}`}
                        onClick={()=>{
                            if (currentWallet?.currency?.refillable){
                                dispatch(createCurrentWalletSuccess(false));
                                dispatch(setWalletTakeFormStatus({status: true, data: currentWallet}));
                            } else {
                                toast(t('На данный момент вы не можете пополнить этот кошелек.'));
                            }
                        }}>
                        <div className={`${Styles.btnWrapItemImgWrap}`}>
                            <img src={receiveIcon} alt="receive transaction"/>
                        </div>
                        <div className={`${Styles.btnWrapItemContent}`}>{t('Получить')}</div>
                    </div>
                    <div
                        className={`${Styles.btnWrapItem}`}
                        onClick={()=>{
                            if (currentWallet?.amount > 0){
                                dispatch(setWalletSendFormStatus({status: true, data: currentWallet}));
                            } else {
                                toast(t('На данный момент у вас нет средств для вывода'));
                            }
                        }}
                    >
                        <div className={`${Styles.btnWrapItemImgWrap}`}>
                            <img src={sendIcon} alt="send transaction"/>
                        </div>
                        <div className={`${Styles.btnWrapItemContent}`}>{t('Отправить')}</div>
                    </div>
                </div>
        }
    }

    return (
        <section className={`${Styles.container}`}>
            {btnContentHandler()}
        </section>
    );
};

export default WalletBtns;
