import React, {useCallback} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {choiceServer, setServerModalStatus} from "../../store/main/action";



const ServerModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const serverModalStatus = useSelector(store => store.main.serverModalStatus);
    const server = useSelector(store => store.main.server);

    const closeModal = useCallback(() =>{
        dispatch(setServerModalStatus(false));
    }, [dispatch])
    

    return (
        <Popup
            open={serverModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title2}`}>{t('Вас пригласили на сервер')}!</div>
                    <div className={`${Styles.note}`}>
                        {'Пользователь с ником'} {server.partner_email ?? ''} {t('приглашает вас на сервер')} <span>"{server.server_name ?? ''}"</span>
                    </div>
                    <div className={`${Styles.btnsWrap}`}>
                        <div className={`${Styles.button}`} onClick={()=>{dispatch(choiceServer(server.partner_server_id))}}>{t('Принять приглашение')}</div>
                        <div className={`${Styles.button} ${Styles.buttonType1}`} onClick={()=>{closeModal()}}>{t('Выбрать другой')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default ServerModal;
