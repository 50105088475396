import React from "react";
import Styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";



const SettingsSupport = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const linkHandler = () =>{
        navigate('/support');
    }
    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Поддержка')}</div>
                    <div className={`${Styles.contentWrapNote}`}>
                        {t('Если у Вас возникли проблемы, вы можете обратиться в службу поддержку, заполнив обращение в разделе')}
                        {' '}<span onClick={()=>{linkHandler()}}>{t('Поддержка')}</span>
                    </div>
                    <div className={`${Styles.button}`} onClick={()=>{linkHandler()}}>{t('Нужна поддержка')}</div>
                </div>
            </div>
        </section>
    );
};

export default SettingsSupport;
