import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import BonusesMain from "../../components/bonusesMain";
import BonusesProfit from "../../components/bonusesProfit";
import BonusesModal from "../../components/bonusesModal";
import {getRefInfo, getRefMainData} from "../../store/cabinet/action";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";


const BonusesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getRefMainData());
        dispatch(getRefInfo());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Программа Лояльности')}/>
            <div className={`${Styles.contentWrap}`}>
                <BonusesMain/>
                <BonusesProfit/>
                <BonusesModal/>
            </div>
        </section>
    );
};

export default BonusesPage;