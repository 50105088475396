import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {floorToDecimals} from "../../helpers/floorHandler";
import {useTranslation} from "react-i18next";


const CabinetBotProfit = () => {
    const { t } = useTranslation();

    const botProfit = useSelector(store => store.cabinet.botProfit);

    const date = new Date();
    const month = date.getMonth();

    const arrowContentHandler = (color) =>{
        return (
            <div className={`${Styles.barAbsValueArrow}`}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.82199 10.8136C7.42431 11.3876 6.57569 11.3876 6.178 10.8136L0.293147 2.31949C-0.166331 1.65629 0.308323 0.75 1.11514 0.75L12.8849 0.750001C13.6917 0.750001 14.1663 1.65629 13.7069 2.3195L7.82199 10.8136Z" fill={color}/>
                </svg>
            </div>
        )
    }

    const barPercentHeightHandler = (maxValue, barValue) =>{
        const totalHeight = maxValue + maxValue / 10;
        return barValue / totalHeight * 100
    }

    const monthTranslateHandler = (month) =>{
        switch (month){
            case '01':
                return t('Янв')
            case '02':
                return t('Фев')
            case '03':
                return t('Мар')
            case '04':
                return t('Апр')
            case '05':
                return t('Май')
            case '06':
                return t('Июн')
            case '07':
                return t('Июл')
            case '08':
                return t('Авг')
            case '09':
                return t('Сен')
            case '10':
                return t('Окт')
            case '11':
                return t('Ноя')
            case '12':
                return t('Дек')
            default:
                return 'Unk'
        }
    }


    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.titleWSF}`}>{t('Доход')} <span className={`${Styles.titleWSF}`}>{t('за месяц')}</span></div>
                <div className={`${botProfit?.data?.length > 0 ? Styles.contentBoxActive : Styles.contentBox}`}>
                    {
                        botProfit?.data?.length > 0 ? botProfit?.data?.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem} ${month + 1 === Number(item.month) ? Styles.contentBoxItemCurrent : item.profit || item.profit === 0 ? Styles.contentBoxItemWithData : null}`} key={'botProfit' + index}>
                                    <div className={`${Styles.barBody}`}>
                                        {item.profit ?
                                            <div className={`${Styles.barAbsValue}`}>
                                                {floorToDecimals(Number(item.profit), 2)}
                                                {arrowContentHandler(month === index ? '#00FF7A' : '#8f5105')}
                                            </div> : null
                                        }
                                        <div className={`${Styles.barAbsPercent}`} style={{height: `${barPercentHeightHandler(Number(botProfit.maxValue), Number(item.profit))}%`}}/>
                                    </div>
                                    <div className={`${Styles.barName}`}>{monthTranslateHandler(item.month)}</div>
                                </div>
                            )
                        }) :
                        <div className={`${Styles.loadBox}`}>{t('Загрузка...')}</div>
                    }
                </div>
            </div>
        </section>
    );
};

export default CabinetBotProfit;