import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAeldersForceIndex = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Elder’s Force Index (EFI)')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Elder’s Force Index (EFI)</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Technical Description of Elder’s Force Index (EFI) Settings</div>
                                <div className={`${Styles.paragraph}`}>
                                    Elder’s Force Index (EFI) is a setting implemented in HoneeAI to measure the
                                    strength of price movements based on volume and price changes. EFI
                                    combines three key parameters: price direction, volume, and the degree of
                                    price change over a period. The setting helps determine the strength of buyers
                                    or sellers at any point in the market.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    EFI can be configured for various strategies: conservative, balanced, and
                                    aggressive, depending on the trader’s preferences and risk tolerance. These
                                    strategies are defined by the length of the period used to calculate the
                                    indicator.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>The formula for calculating the Force Index (EFI) is as follows:</div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>EFI</span> = (Priceclose - Priceprevious close) * Volume<br/>
                                    where: ( * = multiplying )<br/>
                                    <span className={Styles.colorOrange}>Priceclose</span> = the current closing price<br/>
                                    <span className={Styles.colorOrange}>Priceprevious close</span> = the previous period’s closing price<br/>
                                    <span className={Styles.colorOrange}>Volume</span> =  the trading volume for the current period<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The calculated EFI value can be smoothed using an exponential moving average
                                    (EMA) to provide more stable signals.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Applying EFI for Three Strategies</div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Conservative Strategy (Longer Period, Lower Risk)</div>
                                <div className={`${Styles.paragraph}`}>
                                    In the conservative strategy, a longer EMA period is used to smooth EFI
                                    readings. This allows HoneeAI to filter out short-term price and volume
                                    fluctuations, focusing on more stable and sustained movements. The
                                    conservative setting reduces the number of false signals and is suitable for
                                    long-term investors and traders who aim to minimize risk.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Balanced Strategy (Medium Period, Moderate Risk)</div>
                                <div className={`${Styles.paragraph}`}>
                                    The balanced strategy, using a medium EMA period, offers a more flexible
                                    approach. This setting allows HoneeAI to account for both short-term and
                                    medium-term price and volume movements. Traders can identify trend
                                    reversals on shorter intervals while maintaining awareness of broader trends.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Aggressive Strategy (Shorter Period, Higher Risk)</div>
                                <div className={`${Styles.paragraph}`}>
                                    The aggressive strategy uses a very short EMA period, making EFI highly
                                    sensitive to short-term changes. This approach provides HoneeAI with the
                                    fastest entry and exit signals, but it also increases the number of false signals
                                    due to market noise. The aggressive strategy is suitable for traders who
                                    actively trade throughout the day (scalping) and aim to maximize profits
                                    through frequent trades.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.title2}`}>Conclusion</div>
                                <div className={`${Styles.paragraph}`}>
                                    The Elder’s Force Index setting is a powerful tool for analyzing trend strength
                                    based on volume and price changes. Depending on the selected EMA period,
                                    EFI can be applied to different strategies, ranging from conservative with
                                    minimal risk to aggressive with high trade frequency. The conservative strategy
                                    allows for the identification of long-term trends, the balanced strategy enables
                                    responses to medium-term changes, and the aggressive strategy allows
                                    HoneeAI to actively trade based on short-term price and volume fluctuations.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAeldersForceIndex;