import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import honeechizeIcon from '../../theme/assets/svg/omt_honeechize_icon.svg';
import {dotsHandler} from "../../helpers/dotsHandler";
import {floorToDecimals} from "../../helpers/floorHandler";



const HoneechizeMain = () => {
    const honeechizeMainData = useSelector(store => store.cabinet.honeechizeMainData);
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.mainWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.imgWrap}`}>
                            <img src={honeechizeIcon} alt="honeechize"/>
                        </div>
                        <div className={`${Styles.titleWSF} ${Styles.mainT} ${Styles.colorOrange}`}>Honeechize</div>
                    </div>
                    <div className={`${Styles.contentRow}`}>
                        <div className={`${Styles.contentRowNote}`}>
                            {t('Наш искусственный интеллект, осуществляющий торговлю на рынке вместо вас! Вы можете найти подробную информацию.')}
                        </div>
                        <div className={`${Styles.contentRowInfo}`}>
                            <div className={`${Styles.infoBox}`}>
                                <div className={`${Styles.titleWSF} ${Styles.colorGreen}`}>{t('Баланс')}:</div>
                                <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(floorToDecimals(honeechizeMainData?.balance, 2)) : 0}</div>
                            </div>
                            <div className={`${Styles.infoBox}`}>
                                <div className={`${Styles.titleWSF} ${Styles.colorOrange}`}>{t('Структура')}:</div>
                                <div className={`${Styles.infoValue}`}>{honeechizeMainData ? dotsHandler(floorToDecimals(honeechizeMainData?.in_work, 2)) : 0}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.otherWrap}`}>
                    <div className={`${Styles.otherTitleRow}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Информация')}:</div>
                    </div>
                    <div className={`${Styles.otherRowInfo}`}>
                        <div className={`${Styles.infoBox}`}>
                            <div className={`${Styles.titleWSF} ${Styles.colorYellow}`}>{t('Заработок')}:</div>
                            {/*<div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(honeechizeMainData?.money_earned) : 0}</div>*/}
                            <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(floorToDecimals(honeechizeMainData?.money_earned, 2)) : 0}</div>
                        </div>
                        <div className={`${Styles.infoBox}`}>
                            <div className={`${Styles.titleWSF} ${Styles.colorYellow}`}>{t('Ваша структура')}:</div>
                            <div className={`${Styles.infoValue}`}>{honeechizeMainData ? dotsHandler(floorToDecimals(honeechizeMainData?.structure_size, 2)) : 0}</div>
                        </div>
                        <div className={`${Styles.infoBox}`}>
                            <div className={`${Styles.titleWSF} ${Styles.colorYellow}`}>{t('Инвестировано')}:</div>
                            <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(floorToDecimals(honeechizeMainData?.money_invested, 2)) : 0}</div>
                        </div>
                        <div className={`${Styles.infoBox}`}>
                            <div className={`${Styles.titleWSF} ${Styles.colorYellow}`}>{t('Выплачено')}:</div>
                            <div className={`${Styles.infoValue}`}>${honeechizeMainData ? dotsHandler(floorToDecimals(honeechizeMainData?.paid_out, 2)) : 0}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HoneechizeMain;
