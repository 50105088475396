import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import CryptoIcon from "../_UI/cryptoIcon";
import {useDispatch, useSelector} from "react-redux";
import exchangeIcon from '../../theme/assets/svg/omt_exchange_icon.svg';
import {exchange, exchangeSuccess, getExchangeHistory} from "../../store/cabinet/action";
import {toast} from "react-toastify";
import Loader from "../_UI/loader";


const ExchangeForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const exchangeData = useSelector(store => store.cabinet.exchangeData);
    const exchangeStatusStarted = useSelector(store => store.cabinet.exchangeStatusStarted);
    const currentExchangeStatus = useSelector(store => store.cabinet.exchangeStatus);
    

    const [exchangeStatus, setExchangeStatus] = useState('buy');
    const [currentFromCurrency, setCurrentFromCurrency] = useState(exchangeData[exchangeStatus]?.currencies_from  ? exchangeData[exchangeStatus]?.currencies_from[0] : false);
    const [currentToCurrency, setCurrentToCurrency] = useState(exchangeData[exchangeStatus]?.currencies_to  ? exchangeData[exchangeStatus]?.currencies_to[0] : false);
    const [currentFromListStatus, setCurrentFromListStatus] = useState(false);
    const [currentToListStatus, setCurrentToListStatus] = useState(false);
    const [buySum, setBuySum] = useState('');
    const [sellSum, setSellSum] = useState('');

    const dropInputContent = (type) =>{
        return (
            <div className={`${Styles.listPosWrap}`}>
                {type ? currentFromCurrency ?
                    <div
                        className={`${Styles.listBox}`}
                        onClick={()=>{
                            if (type){
                                setCurrentFromListStatus(!currentFromListStatus);
                            } else {
                                setCurrentToListStatus(!currentToListStatus);
                            }
                        }}
                    >
                        <div className={`${Styles.listBtn} ${type ? currentFromListStatus ? Styles.listBtnActive : null : currentToListStatus ? Styles.listBtnActive : null}`}>
                            <img src={listArrowIcon} alt="list arrow"/>
                        </div>
                        <div className={`${Styles.listResult}`}>
                            <div className={`${Styles.listResultImgWrap}`}>
                                <CryptoIcon short_name={type ? currentFromCurrency?.currency?.short_name : currentToCurrency?.currency?.short_name}/>
                            </div>
                            <div className={`${Styles.listResultText}`}>{type ? currentFromCurrency?.currency?.short_name : currentToCurrency?.currency?.short_name}</div>
                        </div>
                    </div>
                    : <div className={`${Styles.listBoxEmpty}`}>{t('Нет доступных кошельков')}</div> : currentToCurrency ?
                    <div
                        className={`${Styles.listBox}`}
                        onClick={()=>{
                            if (type){
                                setCurrentFromListStatus(!currentFromListStatus);
                            } else {
                                setCurrentToListStatus(!currentToListStatus);
                            }
                        }}
                    >
                        <div className={`${Styles.listBtn} ${type ? currentFromListStatus ? Styles.listBtnActive : null : currentToListStatus ? Styles.listBtnActive : null}`}>
                            <img src={listArrowIcon} alt="list arrow"/>
                        </div>
                        <div className={`${Styles.listResult}`}>
                            <div className={`${Styles.listResultImgWrap}`}>
                                <CryptoIcon short_name={type ? currentFromCurrency?.currency?.short_name : currentToCurrency?.currency?.short_name}/>
                            </div>
                            <div className={`${Styles.listResultText}`}>{type ? currentFromCurrency?.currency?.short_name : currentToCurrency?.currency?.short_name}</div>
                        </div>
                    </div> : <div className={`${Styles.listBoxEmpty}`}>{t('Нет доступных кошельков')}</div>
                }
                <div className={`${Styles.inputCurseNote}`}>1 USDT = 1 HONEE</div>
                <div className={`${Styles.abslistWrap} ${type ? currentFromListStatus ? Styles.abslistWrapActive : null : currentToListStatus ? Styles.abslistWrapActive : null}`}>
                    <div className={`${Styles.abslistBorderWrap}`}>
                        <div className={`${Styles.abslistBox}`}>
                            {exchangeData[exchangeStatus]?.[type ? 'currencies_from' : 'currencies_to'] ? exchangeData[exchangeStatus][type ? 'currencies_from' : 'currencies_to'].map((item, index)=>{
                                return (
                                    <div
                                        className={`${Styles.listBoxItem}`}
                                        key={'exchange' + index}
                                        onClick={()=>{
                                            if (type){
                                                setCurrentFromCurrency(item);
                                                setCurrentFromListStatus(false);
                                            } else {
                                                setCurrentToCurrency(item);
                                                setCurrentToListStatus(false);
                                            }
                                        }}
                                    >
                                        <div className={`${Styles.listBoxItemImgWrap}`}>
                                            <CryptoIcon short_name={item?.currency?.short_name}/>
                                        </div>
                                        <div className={`${Styles.listBoxItemText}`}>{item?.currency?.short_name}</div>
                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const amountInputContent = (type, order) =>{
        if (type){
            if (order){
                return (
                    <div className={`${Styles.sumInputBox}`}>
                        <input
                            type="text"
                            placeholder={`${currentFromCurrency ? `${t('От')} 0.1 ${t('до')} ${currentFromCurrency?.amount * 1} ${currentFromCurrency?.currency?.short_name}` : t('Нет доступных кошельков')}`}
                            onChange={(e)=>{setBuySum(e.target.value)}}
                            value={buySum}
                        />
                        <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{currentFromCurrency ? `${currentFromCurrency?.amount * 1} ${currentFromCurrency?.currency?.short_name}` : '--'} </span></div>
                    </div>
                )
            } else {
                return (
                    <div className={`${Styles.fakeInputBox}`}>
                        <div className={`${Styles.fakeInput}`}>{currentFromCurrency ? (buySum * currentFromCurrency?.currency?.rate).toFixed(3) * 1 : '0'}</div>
                        <div className={`${Styles.inputNote}`}>{t('Количество OMT токенов')}: <span>{currentToCurrency?.amount * 1} {currentToCurrency?.currency?.short_name}</span></div>
                        <div className={`${Styles.inputNote}`}>{t('Коммиссия')}: <span>0</span></div>
                    </div>
                )
            }
        } else {
            if (order){
                return (
                    <div className={`${Styles.sumInputBox}`}>
                        <input
                            type="text"
                            placeholder={`${t('От')} 10 ${t('до')} ${currentFromCurrency?.amount * 1} ${currentFromCurrency?.currency?.short_name}`}
                            onChange={(e)=>{setSellSum(e.target.value)}}
                            value={sellSum}
                        />
                        <div className={`${Styles.inputNote}`}>{t('Количество OMT токенов')}: <span>{currentFromCurrency?.amount * 1} {currentFromCurrency?.currency?.short_name}</span></div>
                    </div>
                )
            } else {
                return (
                    <div className={`${Styles.fakeInputBox}`}>
                        <div className={`${Styles.fakeInput}`}>{currentToCurrency ? sellSum * 1 : '0'}</div>
                        <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{currentToCurrency ? `${currentToCurrency?.amount * 1} ${currentToCurrency?.currency?.short_name}` : '--'} </span></div>
                        <div className={`${Styles.inputNote}`}>{t('Коммиссия')}: <span>{currentToCurrency?.currency?.withdraw_commission} {currentToCurrency?.currency?.short_name}</span></div>
                    </div>
                )
            }
        }
    }

    const exchangeStatusHandler = () =>{
        if (exchangeStatus === 'buy'){
            setExchangeStatus('sell');
        } else {
            setExchangeStatus('buy')
        }
    }

    const exchangeHandler = () =>{
        if (exchangeStatus === 'buy'){
            console.log({
                currency_from: currentFromCurrency?.currency?.id,
                currency_to: currentToCurrency?.currency?.id,
                amount: exchangeStatus === 'buy' ? buySum : sellSum,
                direction: exchangeStatus
            })
            if (Number(buySum) >= 0.1 && currentFromCurrency?.amount >= Number(buySum) && !exchangeStatusStarted){
                dispatch(exchange({
                    currency_from: currentFromCurrency?.currency?.id,
                    currency_to: currentToCurrency?.currency?.id,
                    amount: exchangeStatus === 'buy' ? buySum : sellSum,
                    direction: exchangeStatus
                }))
            }
        } else {
            if (Number(sellSum) >= 10 && currentFromCurrency?.amount >= Number(sellSum) && !exchangeStatusStarted){
                dispatch(exchange({
                    currency_from: currentFromCurrency?.currency?.id,
                    currency_to: currentToCurrency?.currency?.id,
                    amount: exchangeStatus === 'buy' ? buySum : sellSum,
                    direction: exchangeStatus,
                }))
            }
        }
    }

    const isExchangeButtonAvailable = () =>{
        return exchangeStatus === 'buy' ? Number(buySum) >= 0.1 && currentFromCurrency?.amount >= Number(buySum) && !exchangeStatusStarted : Number(sellSum) >= 10 && currentFromCurrency?.amount >= Number(sellSum) && !exchangeStatusStarted
    }

    useEffect(()=>{
        setCurrentFromCurrency(exchangeData[exchangeStatus]?.currencies_from  ? exchangeData[exchangeStatus]?.currencies_from[0] : false)
        setCurrentToCurrency(exchangeData[exchangeStatus]?.currencies_to  ? exchangeData[exchangeStatus]?.currencies_to[0] : false)
    },[exchangeData, exchangeStatus])
    
    useEffect(()=>{
        if (currentExchangeStatus){
            toast(t('Вы совершили обмен! Ожидайте завершения транзакции'));
            setBuySum('');
            setSellSum('');
            dispatch(getExchangeHistory());
            dispatch(exchangeSuccess(false));
        }
    },[currentExchangeStatus, dispatch, t])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.titleWSF}`}>{t('Обмен')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxColumn}`}>
                        <div className={`${Styles.contentBoxColumnTitle}`}>{t('Выберете монету, которую хотите поменять')}:</div>
                        <div className={`${Styles.contentBoxColumnItem}`}>
                            {dropInputContent(true)}
                        </div>
                        <div className={`${Styles.contentBoxColumnItem}`}>
                            {amountInputContent(exchangeStatus === 'buy', true)}
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxExchangeBtnWrap}`}>
                        <div className={`${Styles.contentBoxExchangeBtn}`} onClick={()=>{exchangeStatusHandler()}}>
                            <img src={exchangeIcon} alt="exchange"/>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxColumn}`}>
                        <div className={`${Styles.contentBoxColumnTitle}`}>{t('Вы получите')}:</div>
                        <div className={`${Styles.contentBoxColumnItem}`}>
                            {dropInputContent(false)}
                        </div>
                        <div className={`${Styles.contentBoxColumnItem}`}>
                            {amountInputContent(exchangeStatus === 'buy', false)}
                        </div>
                    </div>
                </div>
                <div className={`${Styles.button} ${isExchangeButtonAvailable() ? Styles.buttonActive : null} ${exchangeStatusStarted ? Styles.mainBtnLoading : null}`} onClick={()=>{exchangeHandler()}}>
                    <Loader status={!!exchangeStatusStarted}/>
                    <div className={`${Styles.textWrap}`}>{t('Обменять')}</div>
                </div>
            </div>
        </section>
    );
};

export default ExchangeForm;
