import React from 'react';
import ReactDOM from 'react-dom';
import './helpers/i18n';
import { Provider } from 'react-redux';
import { store } from './store';
import {BrowserRouter as Router} from 'react-router-dom';
import App from "./components/_APP";
import './theme/scss/index.scss'

// import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// // 1. Get projectId
// const projectId = 'dc249d68c78e24a98104a1f9cdf470ef'
//
// // 2. Set chains
// const mainnet = {
//     chainId: 1,
//     name: 'Ethereum',
//     currency: 'ETH',
//     explorerUrl: 'https://etherscan.io',
//     rpcUrl: 'https://cloudflare-eth.com'
// }
//
// // 3. Create a metadata object
// const metadata = {
//     name: 'My Website',
//     description: 'My Website description',
//     url: 'https://mywebsite.com', // origin must match your domain & subdomain
//     icons: ['https://avatars.mywebsite.com/']
// }
//
// // 4. Create Ethers config
// const ethersConfig = defaultConfig({
//     /*Required*/
//     metadata,
//
//     /*Optional*/
//     enableEIP6963: true, // true by default
//     enableInjected: true, // true by default
//     enableCoinbase: true, // true by default
//     rpcUrl: '...', // used for the Coinbase SDK
//     defaultChainId: 1 // used for the Coinbase SDK
// })
//
// // 5. Create a Web3Modal instance
// createWeb3Modal({
//     ethersConfig,
//     chains: [mainnet],
//     projectId,
//     enableAnalytics: true // Optional - defaults to your Cloud configuration
// })

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>,
    document.getElementById('root')
);
