import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAichimokuKinkoHyo = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Ichimoku Kinko Hyo (IKH)')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Ichimoku Kinko Hyo (IKH)</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Technical Description of the Ichimoku Kinko Hyo (IKH) Settings</div>
                                <div className={`${Styles.paragraph}`}>
                                    Ichimoku Kinko Hyo, often referred to as the “Ichimoku Cloud,” is a versatile
                                    setting that provides information on trend direction, momentum, and support
                                    and resistance levels in a single system. Ichimoku combines several
                                    components into one system, allowing HoneeAI to gain a comprehensive view
                                    of the market. This setting is particularly effective in volatile markets, where it
                                    is crucial to timely identify trends and dynamic support and resistance levels.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Mathematical Components of Ichimoku Kinko Hyo</div>
                                <div className={`${Styles.paragraph}`}>
                                    The Ichimoku Kinko Hyo indicator consists of five lines, each calculated based
                                    on different time periods. Below are the key components and their formulas:
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>Tenkan-sen (Conversion Line)</span> = (Highest High + Lowest Low) / 2 | (over the last 9 periods)<br/>
                                    <span className={Styles.colorOrange}>Kijun-sen (Base Line)</span> = (Highest High + Lowest Low) / 2 | (over the last 26 periods)<br/>
                                    <span className={Styles.colorOrange}>Senkou Span A (Leading Span A)</span> = (Tenkan-sen + Kijun-sen) / 2 | (projected 26 periods ahead)<br/>
                                    <span className={Styles.colorOrange}>Senkou Span A (Leading Span A)</span> = (Highest High + Lowest Low) / 2 | (over the last 52 periods, projected 26 periods ahead)<br/>
                                    <span className={Styles.colorOrange}>Chikou Span (Lagging Span)</span> = Current price shifted 26 periods back<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The space between Senkou Span A and Senkou Span B forms the “cloud,” also
                                    known as Kumo, which is one of the most important elements of the Ichimoku
                                    system.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Application of Ichimoku on Different Timeframes (H1, H4, H8)</div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>H1 Timeframe (1 hour)</div>
                                <div className={`${Styles.paragraph}`}>
                                    On the H1 timeframe, the Ichimoku setting works well for short-term trend
                                    identification and dynamic support/resistance levels. The rapid price
                                    movements on this timeframe require attention to the price position relative to
                                    the cloud and the crossovers of the Tenkan-sen and Kijun-sen lines.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>H4 Timeframe (4 hours)</div>
                                <div className={`${Styles.paragraph}`}>
                                    On the H4 timeframe, Ichimoku Kinko Hyo provides a clearer picture of
                                    medium-term trends. This timeframe filters out much of the noise seen on H1
                                    and is ideal for traders holding positions for several days.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>H8 Timeframe (8 hours)</div>
                                <div className={`${Styles.paragraph}`}>
                                    On the H8 timeframe, Ichimoku becomes a powerful tool for long-term or swing
                                    traders looking to capture larger market trends. The longer timeframe allows
                                    the cloud and other components to filter out short-term price fluctuations,
                                    offering a reliable view of the dominant market trend.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.title2}`}>Conclusion</div>
                                <div className={`${Styles.paragraph}`}>
                                    Ichimoku Kinko Hyo is a versatile setting that combines trend identification,
                                    momentum evaluation, and support/resistance analysis in one system. On
                                    shorter timeframes (H1), Ichimoku is useful for identifying quick market
                                    movements and momentum shifts. On longer timeframes, such as H4 and H8,
                                    Ichimoku becomes more reliable for identifying strong trends and major market
                                    reversals. The cloud serves as a dynamic zone of support or resistance, while
                                    the crossovers of the Tenkan-sen and Kijun-sen lines provide insights into
                                    short-term momentum.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAichimokuKinkoHyo;