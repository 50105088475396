import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getExchangeData, getExchangeHistory} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
// import ExchangeMain from "../../components/exchangeMain";
import ExchangeForm from "../../components/exchangeForm";
import ExchangeHistory from "../../components/exchangeHistory";


const ExchangePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getExchangeData());
        dispatch(getExchangeHistory());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Обмен')}/>
            <div className={`${Styles.contentWrap}`}>
                {/*<ExchangeMain/>*/}
                <ExchangeForm/>
                <ExchangeHistory/>
            </div>
        </section>
    );
};

export default ExchangePage;