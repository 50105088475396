import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {getOneCurrentWallet, getStakingCommission, openStaking} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";


const StakingAvailable = () => {
    const dispatch = useDispatch();
    const stakingFormStatus = useSelector(store => store.cabinet.stakingFormOmtStatus);
    const oneCurrentWallet = useSelector(store => store.cabinet.oneCurrentWallet);
    const stakingCommission = useSelector(store => store.cabinet.stakingCommission);

    const { t } = useTranslation();

    const [sum, setSum] = useState('');
    const [currentProfit, setCurrentProfit] = useState(0);

    const isSubmitButtonAvailable = () => {
        return sum >= minStakingAmount() && Number(oneCurrentWallet.amount).toFixed(2) * 1 >= minStakingAmount() && isCommissionSuccess();
    }

    const minStakingAmount = () => {
        return 10;
    }

    const placeholder = () => {
        let message = `${t('Количество от')} ${minStakingAmount()}`;
        const available = Number(oneCurrentWallet.amount).toFixed(2) * 1;
        if (minStakingAmount() < available) {
            message += ` ${t('до')} ${oneCurrentWallet?.amount ? Number(oneCurrentWallet.amount).toFixed(2) * 1 : '0'}`;
        }
        return message;
        // return `${t('Количество от')} ${minStakingAmount()} }`;
    }

    const mainBtnHandler = () =>{
        if (isSubmitButtonAvailable()){
            dispatch(
                openStaking({
                    sum,
                    days: 365,
                    successCallback: function () {
                        toast(t('Ваши активы успешно внесены на стейкинг!'));
                        setSum('');
                        dispatch(getOneCurrentWallet(9));
                    }
                })
            );

        }
    }

    const isCommissionSuccess = () =>{
        return Number(stakingCommission?.wallet?.amount) >= stakingCommission?.commission
    }

    useEffect(()=>{
        if (sum !== '' && sum > 0){
            dispatch(getStakingCommission(sum))
        } else {
            dispatch(getStakingCommission())
        }

    },[dispatch, stakingFormStatus, sum])

    useEffect(()=>{
        if (sum > 0.1){
            setCurrentProfit(Number(sum * ((21 / 365) / 100)).toFixed(4) * 1)
        } else {
            setCurrentProfit(0)
        }
    },[sum])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.formWrap}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Открыть')} {t('Стейкинг')}</div>
                    <div className={`${Styles.settingsWrap}`}>
                        <div className={`${Styles.settingsItem}`}>
                            <div className={`${Styles.settingsItemName} ${Styles.title6}`}>{t('Монета')}</div>
                            <div className={`${Styles.settingsItemCoin}`}>
                                <CryptoIcon short_name={'OMT_BEP20'}/>
                                <div className={`${Styles.settingsItemCoinName}`}>OMT_BEP20</div>
                            </div>
                        </div>
                        <div className={`${Styles.settingsItem}`}>
                            <div className={`${Styles.settingsItemName} ${Styles.title6}`}>{t('Кол-во дней')}</div>
                            <div className={`${Styles.settingsItemValue}`}>365</div>
                        </div>
                        <div className={`${Styles.settingsItem} `}>
                            <div className={`${Styles.settingsItemName} ${Styles.title6}`}>APY %</div>
                            <div className={`${Styles.settingsItemValue}`}>21</div>
                        </div>
                    </div>
                    <div className={`${Styles.inputWrap}`}>
                        <div className={`${Styles.inputBox}`}>
                            <input
                                type="number"
                                placeholder={placeholder()}
                                onChange={(e)=>{
                                    if (e.target.value < 0){
                                        setSum(0)
                                    } else {
                                        setSum(e.target.value)
                                    }
                                }}
                                className={`${Styles.inputSum}`}
                                value={sum}
                            />
                            <div className={`${Styles.inputAbs}`} onClick={()=>{setSum(Number(oneCurrentWallet.amount).toFixed(2) * 1)}}>
                                <span className={`${Styles.inputAbsCur}`}>{stakingFormStatus?.item?.currency?.short_name}</span>
                                <span className={`${Styles.inputAbsBtn}`}>{t('Все')}</span>
                            </div>
                            <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{oneCurrentWallet?.amount ? Number(oneCurrentWallet.amount).toFixed(2) * 1 : '0'} OMT_BEP20</span></div>
                            <div className={`${Styles.inputNote}`}>{t('Комиссия сети:')} <span>{stakingCommission?.commission || stakingCommission?.commission === 0 ? `${Number(stakingCommission?.commission)} ${stakingCommission?.currency?.short_name}` : t('Загрузка...')} {stakingCommission?.commission ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</span></div>
                        </div>
                        <div className={`${Styles.mainBtn} ${isSubmitButtonAvailable() ? Styles.mainBtnActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Депозит')}</div>
                    </div>
                    <div className={`${Styles.noteWrap}`}>
                        <div className={`${Styles.noteWrapRow}`}>
                            <div className={`${Styles.noteWrapRowName}`}>
                                {t('Примерный заработок в день')}:
                            </div>
                            <div className={`${Styles.noteWrapRowValue}`}>
                                {currentProfit} OMT_BEP20
                            </div>
                        </div>
                        <div className={`${Styles.noteWrapRow}`}>
                            <div className={`${Styles.noteWrapRowName}`}>
                                {t('Задержка')}:
                            </div>
                            <div className={`${Styles.noteWrapRowValue}`}>
                                6 {t('дней')}
                            </div>
                        </div>
                        <div className={`${Styles.noteWrapText}`}>
                            {t('В соответствии с правилами сети монеты будут разблокированы в вашем аккаунте в течение определенного периода времени')}.
                        </div>
                    </div>
                    <div className={`${Styles.mainBtnM} ${isSubmitButtonAvailable() ? Styles.mainBtnMActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Депозит')}</div>
                </div>
            </div>
        </section>
    );
};

export default StakingAvailable;
