import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setAmbassadorsModalStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";



const AmbassadorsModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ambassadorsModalStatus = useSelector(store => store.cabinet.ambassadorsModalStatus);
    const ambassadorStatuses = useSelector(store => store.cabinet.ambassadorStatuses);



    const closeModal = () =>{
        dispatch(setAmbassadorsModalStatus(false))
    }


    return (
        <Popup
            open={ambassadorsModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className={`modalWithoutP`}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.titleRowItem}`}>{t('Статус')}</div>
                        <div className={`${Styles.titleRowItem}`}>{t('Структура объема торгов')}</div>
                        <div className={`${Styles.titleRowItem}`}>
                            {t('Привелегии')}:<br/>
                            ({t('Промокоды')})
                        </div>
                        <div className={`${Styles.titleRowItem}`}>% - {t('вознаграждения')}</div>
                        <div className={`${Styles.titleRowItem}`}>{t('Бонус')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {ambassadorStatuses ? ambassadorStatuses.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentRow}`} key={'ambassadorsContentRow' + index}>
                                    <div className={`${Styles.contentRowItem} ${Styles.bolded}`} style={{color: `${item.color}`}}>{item.title}</div>
                                    <div className={`${Styles.contentRowItem} ${Styles.bigest}`}>${(item.trading_volume_structure * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>
                                    <div className={`${Styles.contentRowItem}`}>
                                        {item.promo_codes[0]?.count} {t('кодов на')} 3%<br/>
                                        {item.promo_codes[1]?.count} {t('кодов на')} 5%<br/>
                                    </div>
                                    <div className={`${Styles.contentRowItem}`}>
                                        {(item.levels_string)}
                                    </div>
                                    <div className={`${Styles.contentRowItem}`}>
                                        {(item.bonus_amount * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}<br/>
                                        Honee
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default AmbassadorsModal;
