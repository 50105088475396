import React, {useState, ClipboardEvent, useEffect} from "react";
import Styles from './styles.module.scss'
import {toast} from "react-toastify";
import backIcon from '../../theme/assets/svg/omt_btn_back_arrow.svg';
import {
    setConfirmationStep,
    setPhraseFailure,
    setPhraseToConfirmation
} from "../../store/main/action";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const ConfirmationFinish = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const phraseErr = useSelector(store => store.main.setPhraseErr);
    const phraseStatus = useSelector(store => store.main.setPhrase);

    const [phrase, setPhrase] = useState('');


    const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => { // eslint-disable-line
        e.preventDefault()
    }

    const backBtnHandler = ()=>{
        dispatch(setConfirmationStep('2'));
        localStorage.setItem('honee2-step', '2');
    }

    const sendPhraseHandler = () =>{
        if (phrase.length > 5){
            dispatch(setPhraseToConfirmation({phrase}))
        }
    }


    useEffect(()=>{
        if (phraseStatus){
            toast(t('Поздравляем! Ваша секретная фраза подтверждена! Пожалуйста, сохраните ее!'));
            navigate('/honee')
        }
    },[navigate, phraseStatus, t])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>
                    {t('Подтвердите вашу секретную фразу')}
                    <div className={`${Styles.absBackBtn}`} onClick={()=>{backBtnHandler()}}>
                        <img src={backIcon} alt="back arrow"/>
                        <span>{t('Назад')}</span>
                    </div>
                </div>
                <div className={`${Styles.note}`}>
                    {t('Пожалуйста, введите вашу сеĸретную фразу. Все слова, должны быть в том же порядĸе, в ĸотором они были сгенерированны:')}
                </div>
                <div className={`${Styles.inputWrap} ${phraseErr?.phrase ? Styles.inputWrapWithErr : null}`}>
                    <input
                        type="text"
                        // onCopy={(e) => preventCopyPaste(e)}
                        // onPaste={(e) => preventCopyPaste(e)}
                        // onCut={(e) => preventCopyPaste(e)}
                        value={phrase}
                        onChange={(e)=>{
                            setPhrase(e.target.value);
                            if (phraseErr.phrase){
                                delete phraseErr.phrase;
                                dispatch(setPhraseFailure(phraseErr));
                            }
                        }}
                    />
                    <div className={`${Styles.inputErr}`}>{phraseErr?.phrase ? t('Секретная фраза введена не верно, введите фразу повторно') : null}</div>
                </div>
                <div className={`${Styles.btn} ${phrase.length > 5 ? Styles.btnActive : null}`} onClick={()=>{sendPhraseHandler()}}>{t('Подтвердить')}</div>
            </div>
        </section>
    );
};

export default ConfirmationFinish;
