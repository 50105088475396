import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import CabinetBotMain from "../../components/cabinetBotMain";
import CabinetBotStatistic from "../../components/cabinetBotStatistic";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CabinetBotInformation from "../../components/cabinetBotInformation";
import CabinetBotManagement from "../../components/cabinetBotManagement";
import {getBotHistory, getBotMainData, getBotProfit} from "../../store/cabinet/action";
import BotSubscribeModal from "../../components/botSubscribeModal";
import BotPromoModal from "../../components/botPromoModal";
import CabinetBotProfit from "../../components/cabinetBotProfit";
import CabinetBotHistory from "../../components/cabinetBotHistory";
import BotModal from "../../components/botModal";
import ServerInfo from "../../components/serverInfo";
import BotProPlusModal from "../../components/botProPlusModal";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const CabinetBotPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const botMainData = useSelector(store => store.cabinet.botMainData);
    const botHistory = useSelector(store => store.cabinet.botHistory);

    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getBotMainData());
        dispatch(getBotProfit());
    },[dispatch])

    useEffect(()=>{
        if (!botHistory){
            dispatch(getBotHistory());
        } else if (botHistory.current_page + 1 <= botHistory.last_page){
            dispatch(getBotHistory(botHistory.current_page + 1));
        }

    },[dispatch, isScrolledToEnd]) // eslint-disable-line

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('HoneeAI')}/>
            <ServerInfo/>
            <CabinetBotMain/>
            <CabinetBotStatistic/>
            <CabinetBotInformation/>
            <CabinetBotManagement/>
            <BotSubscribeModal/>
            <BotPromoModal/>
            <CabinetBotProfit/>
            <CabinetBotHistory scrollStatus={isScrolledToEnd} scrollAction={setIsScrolledToEnd}/>
            {botMainData?.subscription?.subscription_type === 'ProPlus' ? <BotProPlusModal/> : null}
            {botMainData?.subscription?.subscription_type === 'Pro' ? <BotModal/> : null}
        </section>
    );
};

export default CabinetBotPage;