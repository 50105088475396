import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getOneCurrentWallet, getStakingCommission, getStakingCommissionSuccess,
    openStaking,
    setStakingFormOmtStatus,
} from "../../store/cabinet/action";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const StakingFormOmt = () => {
    const dispatch = useDispatch();
    const stakingFormStatus = useSelector(store => store.cabinet.stakingFormOmtStatus);
    const oneCurrentWallet = useSelector(store => store.cabinet.oneCurrentWallet);
    const stakingCommission = useSelector(store => store.cabinet.stakingCommission);
    const { t } = useTranslation();

    const [sum, setSum] = useState('');
    const [days, setDays] = useState(stakingFormStatus?.item?.days?.[0]);
    const [daysIndex, setDaysIndex] = useState(0);
    const [reInvest, setReInvest] = useState(false);
    const [currentProfit, setCurrentProfit] = useState(0);

    const closeModal = () =>{
        dispatch(setStakingFormOmtStatus({status: false, item: false, balance: false}));
        dispatch(getStakingCommissionSuccess(false))
        setSum('');
    }

    const mainBtnHandler = () =>{
        if (isSubmitButtonAvailable()){
            dispatch(
                openStaking({
                    sum,
                    days: stakingFormStatus?.item?.days?.[daysIndex],
                    reInvest,
                    id: stakingFormStatus.item.id,
                    successCallback: function () {
                        closeModal();
                        toast(t('Ваши активы успешно внесены на стейкинг!'))
                    }
                })
            );

        }
    }

    const isSubmitButtonAvailable = () => {
        return sum >= minStakingAmount() && Number(oneCurrentWallet.amount).toFixed(2) * 1 >= minStakingAmount();
    }

    const minStakingAmount = () => {
        return stakingFormStatus?.item?.min_invest_amount * 1;
    }

    const placeholder = () => {
        let message = `${t('Количество от')} ${minStakingAmount()}`;
        const available = Number(oneCurrentWallet.amount).toFixed(2) * 1;
        if (minStakingAmount() < available) {
            message += ` ${t('до')} ${oneCurrentWallet?.amount ? Number(oneCurrentWallet.amount).toFixed(2) * 1 : '0'}`;
        }
        return message;
        // return `${t('Количество от')} ${minStakingAmount()} }`;
    }

    const isCommissionSuccess = () =>{
        return Number(stakingCommission?.wallet?.amount) >= stakingCommission?.commission
    }
    
    useEffect(()=>{
        if (stakingFormStatus){

            setDays(stakingFormStatus?.item?.days?.[0]);
            // getOneCurrentWallet(stakingFormStatus.item.currency.id);
        }
        if (stakingFormStatus.status){
            dispatch(getOneCurrentWallet(stakingFormStatus.item.currency.id));
        }
    },[dispatch, stakingFormStatus])

    useEffect(()=>{
        if (sum > 0.1){
            if (reInvest){
                let difSum = sum;
                let i = 0;
                while (i < stakingFormStatus?.item?.days?.[daysIndex]) {
                    difSum = Number(difSum * ((stakingFormStatus?.item?.apy?.[daysIndex] / stakingFormStatus?.item?.days?.[daysIndex]) / 100)) + Number(difSum)
                    i++
                }
                setCurrentProfit(((difSum - sum) / stakingFormStatus?.item?.days?.[daysIndex]).toFixed(4) * 1)
            } else {
                setCurrentProfit(Number(sum * ((stakingFormStatus?.item?.apy?.[daysIndex] / stakingFormStatus?.item?.days?.[daysIndex]) / 100)).toFixed(4) * 1)
            }
        }
    },[sum, daysIndex, reInvest]) // eslint-disable-line

    useEffect(()=>{
        if (sum !== '' && sum > 0){
            dispatch(getStakingCommission(sum))
        } else {
            dispatch(getStakingCommission())
        }

    },[dispatch, stakingFormStatus, sum])


    return (
        <Popup
            open={stakingFormStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.formWrap}`}>
                        <div className={`${Styles.formTitle}`}>{t('Стейкинг')} {stakingFormStatus?.item?.short_name}</div>
                        <div className={`${Styles.inputWrap}`}>
                            <div className={`${Styles.inputBox}`}>
                                <input
                                    type="number"
                                    placeholder={placeholder()}
                                    onChange={(e)=>{
                                        if (e.target.value < 0){
                                            setSum(0)
                                        } else {
                                            setSum(e.target.value)
                                        }
                                    }}
                                    className={`${Styles.inputSum}`}
                                    value={sum}
                                />
                                <div className={`${Styles.inputAbs}`} onClick={()=>{setSum(Number(oneCurrentWallet.amount).toFixed(2) * 1)}}>
                                    <span className={`${Styles.inputAbsCur}`}>{stakingFormStatus?.item?.currency?.short_name}</span>
                                    <span className={`${Styles.inputAbsBtn}`}>{t('Все')}</span>
                                </div>
                                <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{oneCurrentWallet?.amount ? Number(oneCurrentWallet.amount).toFixed(2) * 1 : '0'} {stakingFormStatus?.item?.currency?.short_name}</span></div>
                                <div className={`${Styles.inputNote}`}>{t('Комиссия сети:')} <span>{stakingCommission?.commission || stakingCommission?.commission === 0 ? `${Number(stakingCommission?.commission)} ${stakingCommission?.currency?.short_name}` : t('Загрузка...')} {stakingCommission?.commission ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</span></div>
                            </div>
                            <div className={`${Styles.mainBtn} ${isSubmitButtonAvailable() ? Styles.mainBtnActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Депозит')}</div>
                        </div>
                        <div className={`${Styles.daysWrap}`}>
                            <div className={`${Styles.daysTitle}`}>{t('Кол-во дней')}</div>
                            <div className={`${Styles.daysBox} ${days ? null : Styles.daysBoxInitial}`}>
                                {stakingFormStatus?.item?.days?.map((item, index)=>{
                                    return (
                                        <div
                                            className={`${Styles.daysBoxItem} ${index === daysIndex ? Styles.daysBoxItemActive : null}`}
                                            key={'stakingDays' + index}
                                            onClick={()=>{setDaysIndex(index)}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={`${Styles.profitInvestWrap}`}>
                            <div className={`${Styles.profitInvestWrapItem}`}>
                                <div className={`${Styles.profitInvestItemTitle}`}>APY</div>
                                <div className={`${Styles.profitBox}`}>
                                    {stakingFormStatus?.item?.apy?.[daysIndex]}
                                </div>
                            </div>
                            {stakingFormStatus?.item?.auto_reinvest ?
                                <div className={`${Styles.profitInvestWrapItem}`}>
                                    <div className={`${Styles.profitInvestItemTitle}`}>{t('Авто-реинвест')}</div>
                                    <div className={`${Styles.investBox}`}>
                                        <input type="checkbox" onChange={(e)=>{setReInvest(e.target.checked)}}/>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className={`${Styles.noteWrap}`}>
                            <div className={`${Styles.noteWrapRow}`}>{t('Примерный заработок в день')}: <span>
                                {currentProfit} {stakingFormStatus?.item?.currency?.short_name}
                            </span></div>
                            <div className={`${Styles.noteWrapRow}`}>{t('Задержка')}: <span>6 {t('дней')}</span></div>
                            <div className={`${Styles.noteWrapText}`}>
                                -<br/>
                                {t('В соответствии с правилами сети монеты будут разблокированы в вашем аккаунте в течение определенного периода времени')}.
                            </div>
                        </div>
                        <div className={`${Styles.mainBtnM} ${sum >= 0.1 ? Styles.mainBtnMActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Депозит')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default StakingFormOmt;
