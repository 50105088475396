import React, {useEffect} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {getRefInfo, setHoneechizeAffiliateTableModalStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";


const HoneechizeAffiliateSettingsModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const honeechizeModalStatus = useSelector(store => store.cabinet.honeechizeAffiliateTableModalStatus);
    const refInfo = useSelector(store => store.cabinet.refInfo);


    const closeModal = () =>{
        dispatch(setHoneechizeAffiliateTableModalStatus(false))
    }
    
    useEffect(()=>{
        if (honeechizeModalStatus){
            dispatch(getRefInfo());
        }
    },[dispatch, honeechizeModalStatus])


    return (
        <Popup
            open={honeechizeModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className='fixH'
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                {refInfo ?
                    <div className={`${Styles.modalContentWrap}`}>
                        <div className={`${Styles.title}`}>{t('Приглашайте друзей, чтобы зарабатывать вместе!')}</div>
                        <div className={`${Styles.subTitle}`}>{t('Реферальная программа OMT предлагает множество способов заработка для Вас и Вашей команды:')}</div>
                        <div className={`${Styles.tableWrap}`}>
                            <div className={`${Styles.titleRow}`}>
                                <div className={`${Styles.titleRowItem}`}>{t('Ранг')}</div>
                                <div className={`${Styles.titleRowItem}`}>{t('Объем торгов структуры')}</div>
                                <div className={`${Styles.titleRowItem}`}>{t('Личный объем торгов')}</div>
                                <div className={`${Styles.titleRowItem}`}>% {t('вознаграждения')}</div>
                            </div>
                            <div className={`${Styles.tableBox}`}>
                                {refInfo?.levels?.map((item, index)=>{
                                    return (
                                        <div className={`${Styles.tableBoxRow}`} key={'refInfo' + index}>
                                            {/*<div className={`${Styles.tableBoxRowItem} ${Styles.tableBoxRowItemColored}`}><span className={`${Styles.desk}`}>Ранг</span> <span className={`${Styles.pos}`}> 0</span></div>*/}
                                            <div className={`${Styles.tableBoxRowItem} ${Styles.tableBoxRowItemColored}`}>{item?.name?.[item?.name?.length - 1]}</div>
                                            <div className={`${Styles.tableBoxRowItem}`}>{item.referral_turnover}$</div>
                                            <div className={`${Styles.tableBoxRowItem}`}>{item.personal_turnover}$</div>
                                            <div className={`${Styles.tableBoxRowItem}`}>
                                                {item.reward_percentage?.map((itemX, indexX)=>{
                                                    if (indexX === item.reward_percentage.length - 1){
                                                        return (`${((itemX * 10) + (refInfo?.percent * 10)) / 10}%`)
                                                    } else {
                                                        return (`${((itemX * 10) + (refInfo?.percent * 10)) / 10} - `)
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div> :
                    <div className={`${Styles.loadingWrap}`}>Loading...</div>
                }
            </div>
        </Popup>
    );
};

export default HoneechizeAffiliateSettingsModal;
