import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes, Outlet} from "react-router-dom";
import MainPage from "../../pages/mainPage";
import TopHeader from "../topHeader";
import Header from "../header";
import Footer from "../footer";
import RegPage from "../../pages/regPage";
import LogPage from "../../pages/logPage";
import AuthHeader from "../authHeader";
import ResetPasswordPage from "../../pages/resetPasswordPage";
import CabinetBotPage from "../../pages/cabinetBotPage";
import CabinetHeader from "../cabinetHeader";
import CabinetNavigation from "../cabinetNavigation";
import TransactionsPage from "../../pages/transactionsPage";
import SettingsPage from "../../pages/settingsPage";
import {getUserData} from "../../store/main/action";
import SupportPage from "../../pages/supportPage";
import BonusesPage from "../../pages/bonusesPage";
import AmbassadorsPage from "../../pages/ambassadorsPage";
import HoneechizeMetricsPage from "../../pages/honeechizeMetricsPage";
import HoneechizeBotPage from "../../pages/honeechizeBotPage";
import HoneechizeAffiliatePage from "../../pages/honeechizeAffiliatePage";
import IndexPage from "../../pages/indexPage";
import ConfirmationPage from "../../pages/confirmationPage";
import NewDevicePage from "../../pages/newDevicePage";
import StandardWalletPage from "../../pages/standardWalletPage";
import StandardCurrentWalletPage from "../../pages/standardCurrentWalletPage";
import WhitePaperExecutiveSummary from "../../pages/whitepaperPages/whitePaperExecutiveSummary";
import WhitePaperMission from "../../pages/whitepaperPages/whitePaperMission";
import WhitePaperHistory from "../../pages/whitepaperPages/whitePaperHistory";
import WhitePaperGrowthStrategy from "../../pages/whitepaperPages/whitePaperGrowthStrategy";
import WhitePaperCommunity from "../../pages/whitepaperPages/whitePaperCommunity";
import WhitePaperMarketChallenges from "../../pages/whitepaperPages/whitePaperMarketChallenges";
import WhitePaperRoadmap from "../../pages/whitepaperPages/whitePaperRoadmap";
import WhitePaperMarketAnalysis from "../../pages/whitepaperPages/whitePaperMarketAnalysis";
import WhitePaperMAmarketStatus from "../../pages/whitepaperPages/whitePaperMAmarketStatus";
import WhitePaperMAadvantages from "../../pages/whitepaperPages/whitePaperMAadvantages";
import WhitePaperMAdevelopmentTrends from "../../pages/whitepaperPages/whitePaperMAdevelopmentTrends";
import WhitePaperMAforecast from "../../pages/whitepaperPages/whitePaperMAforecast";
import WhitePaperOmtEcosystem from "../../pages/whitepaperPages/whitePaperOmtEcosystem";
import WhitePaperOEoverview from "../../pages/whitepaperPages/whitePaperOEoverview";
import WhitePaperOEomtcStaking from "../../pages/whitepaperPages/whitePaperOEomtcStaking";
import WhitePaperOEstakingTPcrypto from "../../pages/whitepaperPages/whitePaperOEstakingTPcrypto";
import WhitePaperOEhoneeAi from "../../pages/whitepaperPages/whitePaperOEhoneeAi";
import WhitePaperOEindex from "../../pages/whitepaperPages/whitePaperOEindex";
import WhitePaperTokenomics from "../../pages/whitepaperPages/whitePaperTokenomics";
import WhitePaperTMdeflationaryModel from "../../pages/whitepaperPages/whitePaperTMdeflationaryModel";
import WhitePaperHoneyAi from "../../pages/whitepaperPages/whitePaperHoneyAi";
import WhitePaperHAtechnicalPart from "../../pages/whitepaperPages/whitePaperHAtechnicalPart";
import WhitePaperHAanalysing from "../../pages/whitepaperPages/whitePaperHAanalysing";
import WhitePaperHAmoneyFlowIndex from "../../pages/whitepaperPages/whitePaperHAmoneyFlowIndex";
import WhitePaperHAparabolicSar from "../../pages/whitepaperPages/whitePaperHAparabolicSar";
import WhitePaperHAmethodOfTrading from "../../pages/whitepaperPages/whitePaperHAmethodOfTrading";
import WhitePaperHArelativeAverageConditions from "../../pages/whitepaperPages/whitePaperHArelativeAverageConditions";
import WhitePaperNavigation from "../whitePaperNavigation";
import WalletHeader from "../walletHeader";
import StakingPage from "../../pages/stakingPage";
import ExchangePage from "../../pages/exchangePage";
import ServerPage from "../../pages/serverPage";
import NewsPage from "../../pages/newsPage";
import PostPage from "../../pages/postPage";
import WhitePaperHAonBalanceVolume from "../../pages/whitepaperPages/whitePaperHAonBalanceVolume";
import WhitePaperHAichimokuKinkoHyo from "../../pages/whitepaperPages/whitePaperHAichimokuKinkoHyo";
import WhitePaperHAeldersForceIndex from "../../pages/whitepaperPages/whitePaperHAeldersForceIndex";
import GreetingModal from "../greetingModal";
import ExternalChat from "../externalChat";


const MainLayout = () => (
    <div className={`${Styles.main}`}>
        <TopHeader/>
        <Header/>
        <Outlet/>
        <Footer/>
        <ToastContainer/>
        <ExternalChat/>
    </div>
);

const AuthenticationLayout = () => (
    <div className={`${Styles.authentication}`}>
        <AuthHeader/>
        <Outlet />
        <Footer withoutBg={true}/>
    </div>
);

const CabinetLayout = () => (
    <div className={`${Styles.cabinet}`}>
        <div className={`${Styles.posWrap}`}>
            <CabinetHeader/>
            <div className={`${Styles.cabinetWrap} ${Styles.pageContainer}`}>
                <CabinetNavigation/>
                <Outlet/>
                <GreetingModal/>
                <ExternalChat/>
            </div>
        </div>
    </div>
);

const WhitePaperLayout = () =>(
    <div className={`${Styles.whitepaper}`}>
        <WalletHeader isMain={false}/>
        <WhitePaperNavigation/>
        <Outlet />
    </div>
);


const App = () => {
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if(authStatus?.authorization){
            dispatch(getUserData())
        }
    },[authStatus, dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Routes>
                <Route element={<MainLayout/>}>
                    <Route exact path='/' element={<MainPage/>}/>
                    <Route exact path='/news/page/:page' element={<NewsPage/>}/>
                    <Route exact path='/news/:title' element={<PostPage/>}/>
                </Route>
                <Route element={<AuthenticationLayout/>}>
                    {/*<Route exact path='/' element={<MainPage/>}/>*/}
                    <Route exact path='/sign-up' element={<RegPage/>}/>
                    <Route exact path='/sign-up/ref/:code' element={<RegPage/>}/>
                    <Route exact path='/sign-in' element={<LogPage/>}/>
                    <Route exact path='/confirmation' element={<ConfirmationPage/>}/>
                    <Route exact path='/reset-password' element={<ResetPasswordPage/>}/>
                    <Route exact path='/device-confirmation/:hash' element={<NewDevicePage/>}/>
                    <Route exact path='/server' element={<ServerPage/>}/>
                </Route>
                <Route element={<CabinetLayout/>}>
                    <Route exact path='/honee' element={<CabinetBotPage/>}/>
                    <Route exact path='/transactions' element={<TransactionsPage/>}/>
                    <Route exact path='/settings' element={<SettingsPage/>}/>
                    <Route exact path='/support' element={<SupportPage/>}/>
                    <Route exact path='/bonuses' element={<BonusesPage/>}/>
                    <Route exact path='/ambassadors' element={<AmbassadorsPage/>}/>
                    <Route exact path='/honeechize/metrics' element={<HoneechizeMetricsPage/>}/>
                    <Route exact path='/honeechize/bot' element={<HoneechizeBotPage/>}/>
                    <Route exact path='/honeechize/affiliate' element={<HoneechizeAffiliatePage/>}/>
                    <Route exact path='/index' element={<IndexPage/>}/>
                    <Route exact path='/wallet' element={<StandardWalletPage/>}/>
                    <Route exact path='/wallet/:id' element={<StandardCurrentWalletPage/>}/>
                    <Route exact path='/staking' element={<StakingPage/>}/>
                    <Route exact path='/exchange' element={<ExchangePage/>}/>
                </Route>
                <Route element={<WhitePaperLayout/>}>
                    <Route exact path='/white-paper/executive-summary' element={<WhitePaperExecutiveSummary/>}/>
                    <Route exact path='/white-paper/mission' element={<WhitePaperMission/>}/>
                    <Route exact path='/white-paper/history' element={<WhitePaperHistory/>}/>
                    <Route exact path='/white-paper/growth-strategy' element={<WhitePaperGrowthStrategy/>}/>
                    <Route exact path='/white-paper/community' element={<WhitePaperCommunity/>}/>
                    <Route exact path='/white-paper/market-challenges' element={<WhitePaperMarketChallenges/>}/>
                    <Route exact path='/white-paper/roadmap' element={<WhitePaperRoadmap/>}/>
                    <Route exact path='/white-paper/market-analysis' element={<WhitePaperMarketAnalysis/>}/>
                    <Route exact path='/white-paper/market-analysis/market-status' element={<WhitePaperMAmarketStatus/>}/>
                    <Route exact path='/white-paper/market-analysis/advantages' element={<WhitePaperMAadvantages/>}/>
                    <Route exact path='/white-paper/market-analysis/development-trends' element={<WhitePaperMAdevelopmentTrends/>}/>
                    <Route exact path='/white-paper/market-analysis/forecast' element={<WhitePaperMAforecast/>}/>
                    <Route exact path='/white-paper/omt-ecosystem' element={<WhitePaperOmtEcosystem/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/overview' element={<WhitePaperOEoverview/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/omt-staking' element={<WhitePaperOEomtcStaking/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/staking-of-third-party-crypto' element={<WhitePaperOEstakingTPcrypto/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/honee-ai' element={<WhitePaperOEhoneeAi/>}/>
                    <Route exact path='/white-paper/omt-ecosystem/index-omt20' element={<WhitePaperOEindex/>}/>
                    <Route exact path='/white-paper/tokenomics' element={<WhitePaperTokenomics/>}/>
                    <Route exact path='/white-paper/tokenomics/deflationary-model' element={<WhitePaperTMdeflationaryModel/>}/>
                    <Route exact path='/white-paper/honee-ai' element={<WhitePaperHoneyAi/>}/>
                    <Route exact path='/white-paper/honee-ai/technical-part' element={<WhitePaperHAtechnicalPart/>}/>
                    <Route exact path='/white-paper/honee-ai/analysing' element={<WhitePaperHAanalysing/>}/>
                    <Route exact path='/white-paper/honee-ai/money-flow-index' element={<WhitePaperHAmoneyFlowIndex/>}/>
                    <Route exact path='/white-paper/honee-ai/parabolic-sar' element={<WhitePaperHAparabolicSar/>}/>
                    <Route exact path='/white-paper/honee-ai/method-of-trading' element={<WhitePaperHAmethodOfTrading/>}/>
                    <Route exact path='/white-paper/honee-ai/relative-average-conditions' element={<WhitePaperHArelativeAverageConditions/>}/>
                    <Route exact path='/white-paper/honee-ai/on-balance-volume' element={<WhitePaperHAonBalanceVolume/>}/>
                    <Route exact path='/white-paper/honee-ai/ichimoku-kinko-hyo' element={<WhitePaperHAichimokuKinkoHyo/>}/>
                    <Route exact path='/white-paper/honee-ai/elders-force-index' element={<WhitePaperHAeldersForceIndex/>}/>
                </Route>
            </Routes>
            <ToastContainer/>
            {/*<ExternalChat/>*/}
        </section>
    );
};

export default App;