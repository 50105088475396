import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import linkIcon from '../../theme/assets/svg/h2_server_link_icon.svg';


const ServerInfo = () => {
    const { t } = useTranslation();

    const botMainData = useSelector(store => store.cabinet.botMainData);

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.infoWrap}`}>
                        <div className={`${Styles.infoWrapItem}`}>
                            <div className={`${Styles.titleWSF}`}>{t('Сервер')}:</div>
                            <div className={`${Styles.infoWrapItemValue}`}>{botMainData?.server ? botMainData?.server : ''}</div>
                        </div>
                        <div
                            className={`${Styles.infoWrapItem} ${Styles.point}`}
                            onClick={()=>{
                                if (botMainData?.server_leader){
                                    window.open(`https://t.me/${botMainData?.server_leader}`, "_blank")
                                }
                            }}
                        >
                            <div className={`${Styles.titleWSF}`}>{t('Лидер Сервера')}:</div>
                            <div className={`${Styles.infoWrapItemValue}`}>@{botMainData?.server_leader ? botMainData?.server_leader : ''}</div>
                        </div>
                    </div>
                    <div className={`${Styles.noteWrap}`}>
                        <div className={`${Styles.note}`}>{t('Если вы хотите связаться с лидером сервера, нажмите на его ник')}</div>
                        <div className={`${Styles.noteImgWrap}`}>
                            <img src={linkIcon} alt="cursor"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServerInfo;