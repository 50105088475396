import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import circle from '../../theme/assets/other/honee_main_about_circle.png';
import numberIcon1 from '../../theme/assets/svg/honee_main_register_icon1.svg';
import numberIcon2 from '../../theme/assets/svg/honee_main_register_icon2.svg';
import numberIcon3 from '../../theme/assets/svg/honee_main_register_icon3.svg';
import numberIcon4 from '../../theme/assets/svg/honee_main_register_icon4.svg';




const MainAbout = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title2}`}><span>{t('Внутрисистемный токен')}</span> {t('HoneeAI')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.imgWrap}`}>
                        <img src={circle} alt="About HoneeAI"/>
                    </div>
                    <div className={`${Styles.aboutWrap}`}>
                        <div className={`${Styles.aboutItem}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={numberIcon1} alt="01"/>
                            </div>
                            <div className={`${Styles.aboutItemContent}`}>
                                {t('Обеспечивает защиту активов от волатильности и стабильную работу системы')}.
                            </div>
                        </div>
                        <div className={`${Styles.aboutItem}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={numberIcon2} alt="02"/>
                            </div>
                            <div className={`${Styles.aboutItemContent}`}>
                                {t('Полная конфиденциальность исключает возможность отслеживания и вмешательства третьих лиц')}.
                            </div>
                        </div>
                        <div className={`${Styles.aboutItem}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={numberIcon3} alt="03"/>
                            </div>
                            <div className={`${Styles.aboutItemContent}`}>
                                {t('Упрощает взаимодействие с ИИ, делая процесс быстрым и понятным')}.
                            </div>
                        </div>
                        <div className={`${Styles.aboutItem}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={numberIcon4} alt="04"/>
                            </div>
                            <div className={`${Styles.aboutItemContent}`}>
                                {t('Используется для бесперебойной работы и развития платформы')}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainAbout;