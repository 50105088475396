import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
// import PostSubscribe from "../../components/postSubscribe";
import PostMostRead from "../../components/postMostRead";
import PostMain from "../../components/postMain";
import {getOmtPrice, getPost, getTopNews} from "../../store/main/action";


const PostPage = () => {
    const dispatch = useDispatch();
    let {title} = useParams();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    useEffect(()=>{
        dispatch(getOmtPrice());
        dispatch(getPost(title.slice(title.lastIndexOf('n') + 1, title.length)));
        dispatch(getTopNews())
    },[dispatch, title])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'News'}/>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <PostMain/>
                <PostMostRead/>
                {/*<PostSubscribe/>*/}
            </div>
        </section>
    );
};

export default PostPage;