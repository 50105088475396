import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import table1 from '../../../theme/assets/svg/omt_whitepaper_table1.svg';
import table2 from '../../../theme/assets/svg/omt_whitepaper_table2.svg';
import Fade from 'react-reveal/Fade';


const WhitePaperOEhoneeAi = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('HoneeAi')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>HoneeAi</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Automated cryptocurrency arbitrage is an innovation in the world of Web3. It is an official
                                    development by the OMT team, utilizing artificial intelligence to enable cryptocurrency exchanges
                                    across multiple platforms. Our bot now operates seamlessly between both decentralized (DEX) and
                                    centralized (CEX) exchanges, allowing trades between DEX and DEX, CEX and CEX, as well as cross-platform
                                    transactions between DEX and CEX, and CEX and DEX.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The system primarily focuses on arbitrage opportunities, detecting price differences between exchanges and
                                    executing profitable trades. However, the bot is also capable of executing trades within a single exchange
                                    if there is a sudden price spike that presents a more favorable opportunity.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    One of the key innovations is that the system works directly with exchanges through exclusive API connections,
                                    ensuring high-speed trading and the ability to capitalize on arbitrage spreads quickly and efficiently.
                                    This exclusive access provides our algorithm with a unique edge, allowing for faster trade execution and
                                    better results.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Additionally, when you invest in the bot, your deposit is intelligently distributed across multiple trades
                                    and platforms. This prevents the entire sum from being locked into a single trade due to blockchain delays
                                    or exchange operator issues, allowing the bot to make more trades and increase profitability. This method
                                    ensures continuous capital flow and enhances the overall returns for the user.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The program has access to more than 80 major global cryptocurrency exchanges, constantly updating information
                                    on current exchange rates. Once the AI detects a price difference (spread), it automatically initiates an exchange,
                                    typically completing within minutes to maximize profits. Thanks to the new algorithm and our system of connections
                                    with exchanges, the bot can now execute trades with virtually any spread. This offers even greater flexibility
                                    in arbitrage opportunities and increases the frequency of trades while maintaining efficiency.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Now, 8 types of settings are available for the bot, allowing users to fully customize the system to their specific
                                    needs, unlocking limitless earning potential. These settings not only allow users to manage trading risks but also
                                    to choose optimal strategies. We strongly recommend using these settings carefully to avoid potential asset loss
                                    and not increasing risks unnecessarily.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={table1} alt="Automated cryptocurrency arbitrage is an innovation in the world of Web3."/>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    OMT's cryptocurrency arbitrage system is built on the model of a bee colony. There is a queen,
                                    which is the main server and the shared fund of all clients, and there are worker bees, which are
                                    the users' bots. After the user activates their bot and replenishes the deposit, their money is
                                    sent to the common pool, frozen for 90 days, and all bots of the OMT platform have access to it..
                                    The worker bee bots utilize the shared funds but do not exceed the deposit amount of their respective owners.
                                    Once the user's deposit enters the shared fund, you can activate your worker bee (bot) and start generating profits.
                                    You can customize your bot according to your preferences, with several main settings available, one of which is the
                                    risk level. There are three types: low, medium, and high. The higher the risk level, the more trades your worker bee
                                    will make and the higher the percentage it will bring in. However, it also carries the potential for loss-making trades.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={table2} alt="OMT's cryptocurrency arbitrage system is built on the model of a bee colony."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperOEhoneeAi;