import React, {useCallback, useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setGreetingModalStatus} from "../../store/main/action";

import checkA from '../../theme/assets/svg/h2_greeting_check_icon_active.svg';
import checkP from '../../theme/assets/svg/h2_greeting_check_icon_pasive.svg';
import {setGreeting, setGreetingSuccess} from "../../store/cabinet/action";


const GreetingModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const greetingModalStatus = useSelector(store => store.main.greetingModalStatus);
    const setGreetingStatus = useSelector(store => store.cabinet.setGreetingStatus);
    const setGreetingStatusStarted = useSelector(store => store.cabinet.setGreetingStatusStarted);
    
    const [checkStatus, setCheckStatus] = useState(false);


    const closeModal = useCallback(() =>{
        dispatch(setGreetingModalStatus(false));
    }, [dispatch])
    
    
    const btnHandler = (status) =>{
        if (!setGreetingStatusStarted && checkStatus){
            dispatch(setGreeting(status))
        }
    }
    
    useEffect(()=>{
        if (setGreetingStatus){
            dispatch(setGreetingModalStatus(false));
            dispatch(setGreetingSuccess(false));
        }
    },[dispatch, setGreetingStatus])


    return (
        <Popup
            open={greetingModalStatus}
            modal
            nested
            closeOnDocumentClick={false}
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.note}`}>
                        {t('Мы дали вам наш продукт, спасибо, что Вы нам доверились. Благодаря вам мы все достигли потрясающих результатов. Без вашего участия у нас бы ничего не получилось. Теперь наша очередь отблагодарить вас!')} <span>{t('Встречайте HoneeAI 2.0!')}</span>
                    </div>
                    <div className={`${Styles.checkRow}`} onClick={()=>{setCheckStatus(!checkStatus)}}>
                        <div className={`${Styles.check}`}>
                            <img src={checkStatus ? checkA : checkP} alt="check"/>
                        </div>
                        <div className={`${Styles.checkNote}`}>{t('Спасибо, я согласен принять подарок')}</div>
                    </div>
                    <div className={`${Styles.btnWrap}`}>
                        <div className={`${Styles.button} ${checkStatus ? null : Styles.buttonDisabled}`} onClick={()=>{btnHandler(true)}}>{t('принять подарок')}</div>
                        <div className={`${Styles.cancelBtn} ${checkStatus ? Styles.cancelBtnA : null}`} onClick={()=>{btnHandler(false)}}>{t('Нет, спасибо!')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default GreetingModal;
