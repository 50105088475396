import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Styles from './styles.module.scss';
import {
    botInvest,
    changeBotRisk, controlBot, getCustomSettings, setBotModalStatus,
    setBotPromoModalStatus, setBotProPlusModalStatus, setBotReinvest,
    setBotSubscribeModalStatus
} from "../../store/cabinet/action";

import startBtnIcon from '../../theme/assets/svg/h2_bot_start.svg';
import stopBtnIcon from '../../theme/assets/svg/h2_bot_stop.svg';
import passiveBtnIcon from '../../theme/assets/svg/h2_bot_passive.svg';
import questionIcon from '../../theme/assets/svg/h2_bot_question_icon.svg';
import {toast} from "react-toastify";
import {floorToDecimals} from "../../helpers/floorHandler";


const CabinetBotInformation = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const botMainData = useSelector(store => store.cabinet.botMainData);
    const botInvestStatusStarted = useSelector(store => store.cabinet.botInvestStatusStarted);
    const botInvestStatus = useSelector(store => store.cabinet.botInvestStatus);
    const botControlStarted = useSelector(store => store.cabinet.botControlStarted);
    const botReinvestStarted = useSelector(store => store.cabinet.botReinvestStarted);
    const customSettings = useSelector(store => store.cabinet.customSettings);

    const [currentRiskType, setCurrentRiskType] = useState(botMainData?.bot?.next_risk_type ? botMainData?.bot?.next_risk_type : botMainData?.bot?.risk_type);
    const [sum, setSum] = useState('');

    const getAvailableAmount = () => {
        const tempAmount = Math.floor(botMainData?.max_available_amount * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    const getAvailableBalance = () => {
        const tempAmount = Math.floor(botMainData?.wallet?.amount * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    const percentBarHandler = () =>{
        let currentPercent;
        currentPercent = (botMainData.bot?.turnover / botMainData?.next_level?.amount) * 100;

        if (currentPercent > 100){
            return 101
        } else if (currentPercent > 5){
            return currentPercent
        } else if (currentPercent < 5 && currentPercent > 0){
            return 5
        } else {
            return 0
        }
    }

    const riskTextHandler = (text) =>{
        switch (text){
            case 'low':
                return t('Низкий');
            case 'middle':
                return t('Умеренный');
            case 'high':
                return t('Высокий');
            case 'custom':
                return t('Пользовательский');
            case 'default':
                return t('По умолчанию')
            default:
                return ''
        }
    }

    const changeRiskHandler = (item) =>{
        setCurrentRiskType(item);
        dispatch(changeBotRisk(botMainData?.bot?.id, {risk_type: item}));
    }

    const  mainBtnHandler = () =>{
        if (sum >= 250 && sum <= getAvailableAmount() && !botInvestStatusStarted){
            dispatch(setBotPromoModalStatus({status: true, balance: Number(sum), sendAction: botInvest, botId: botMainData.bot.id, isOpen: false, currentRisk: currentRiskType}))
            // dispatch(openBot({amount: Number(sum), risk_type: currentRisk}));
        }
    }

    const controlBotHandler = () =>{
        if (!botControlStarted && botMainData.bot.id){
            dispatch(controlBot(botMainData.bot.id));
        }
    }

    const mainBtnContentHandler = (status)=>{
        let btnIcon = false;
        let btnContent = false;
        let color = false;

        switch (status){
            case 'active':
                btnIcon = stopBtnIcon;
                btnContent = t('СТОП');
                color = '#FF060A'
                break
            case 'stopped':
                btnIcon = startBtnIcon;
                btnContent = t('СТАРТ');
                color = '#00FF7A'
                break
            case 'inactive':
                btnIcon = passiveBtnIcon;
                btnContent = t('СТАРТ');
                color = '#C4C6C7'
                break
            default:
                btnIcon = passiveBtnIcon;
                btnContent = t('СТАРТ');
                color = '#C4C6C7'
        }

        return (
            <div className={`${Styles.mainBtn}`} onClick={()=>{controlBotHandler()}}>
                <div className={`${Styles.mainBtnBg}`}><img src={btnIcon} alt={btnContent}/></div>
                <div className={`${Styles.mainBtnContent}`} style={{color: `${color}`}}>{btnContent}</div>
            </div>
        )
    }

    const reinvestHandler = () =>{
        if (!botReinvestStarted && botMainData.bot.id){
            dispatch(setBotReinvest(botMainData.bot.id))
        }
    }
    
    useEffect(()=>{
        if (botMainData){
            setCurrentRiskType(botMainData?.bot?.next_risk_type ? botMainData?.bot?.next_risk_type : botMainData?.bot?.risk_type);
        }
    }, [botMainData])

    useEffect(()=>{
        if (botInvestStatus){
            setSum('')
        }
    },[botInvestStatus])
    
    useEffect(()=>{
        if (!customSettings && currentRiskType === 'custom'){
            dispatch(getCustomSettings());
        }
    },[currentRiskType, customSettings, dispatch])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            {botMainData?.subscription?.status ?
                <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                    <div className={`${Styles.infoWrap}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Информация')}</div>
                        {/*<div className={`${Styles.infoLevelWrap}`}>*/}
                        {/*    <div className={`${Styles.infoLevelWrapName}`}>Bot level:</div>*/}
                        {/*    <div className={`${Styles.infoLevelWrapValue}`}>{botMainData?.bot?.level ? botMainData?.bot?.level : '0'}</div>*/}
                        {/*</div>*/}
                        <div className={`${Styles.infoInputWrap}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Введите сумму')}:</div>
                            <input
                                type="number"
                                placeholder={`${t('От')} 250 ${(botMainData?.wallet?.amount ? getAvailableAmount() : '0') >= 250 ? `${t('до')} ${botMainData?.wallet?.amount ? getAvailableAmount() : '0'}` : ''}`}
                                className={`${Styles.cabinetInput}`}
                                onChange={(e)=>{
                                    if (e.target.value < 0){
                                        setSum(0)
                                    } else if (e.target.value > getAvailableBalance() || e.target.value > getAvailableAmount()){
                                        if (Number(getAvailableBalance()) > Number(getAvailableAmount())){
                                            setSum(getAvailableAmount());
                                        } else {
                                            setSum(getAvailableBalance());
                                        }
                                    } else {
                                        setSum(e.target.value)
                                    }
                                }}
                                value={sum}
                            />
                            <div className={`${Styles.inputAvailable}`}>{t('Доступный баланс')}: <span>{botMainData?.wallet?.amount ? getAvailableBalance() : '0'} Honee</span></div>
                        </div>
                        {botMainData?.subscription?.subscription_type === 'ProPlus' ?
                            <div className={`${Styles.reinvestWrap}`}>
                                <div className={`${Styles.reinvestBox}`}>
                                    <div className={`${Styles.profitInvestItemTitle}`}>{t('Авто-реинвест')}:</div>
                                    <div className={`${Styles.investBox}`}>
                                        <input type="checkbox" onChange={(e)=>{reinvestHandler()}} checked={botMainData?.bot?.auto_reinvest ?? false}/>
                                    </div>
                                    <div className={`${Styles.questionWrap}`}>
                                        <div className={`${Styles.questionImgWrap}`}>
                                            <img src={questionIcon} alt="question"/>
                                        </div>
                                    </div>
                                    <div className={`${Styles.questionHoverNote}`}>
                                        {t('Авто-реинвест включается и действует рабочий квартал')}
                                    </div>
                                </div>
                            </div> : null
                        }
                        <div className={`${Styles.button} ${sum >= 250 && sum <= getAvailableAmount() ? Styles.buttonGreen : Styles.buttonDisabled}`} onClick={()=>{mainBtnHandler()}}>{t('Инвестировать')}</div>
                    </div>
                    <div className={`${Styles.mainBtnWrap}`}>
                        <div className={`${Styles.mainBtnBox}`}>
                            {mainBtnContentHandler(botMainData?.bot?.status ?? '')}
                        </div>
                        <div className={`${Styles.barWrap}`}>
                            <div className={`${Styles.botLevel}`}>
                                <div className={`${Styles.botLevelBar}`} style={{width: `${percentBarHandler()}%`}}/>
                                <div className={`${Styles.botLevelText}`}>
                                    ${botMainData?.bot?.level === 7 ? botMainData?.bot?.turnover ? floorToDecimals(botMainData?.bot?.turnover, 2) * 1 : '0' : <>{botMainData?.bot?.turnover ? floorToDecimals(botMainData?.bot?.turnover, 2) * 1 : '0'} / ${botMainData?.next_level?.amount ? floorToDecimals(botMainData?.next_level?.amount, 2)  * 1 : '0'}</>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.settingsWrap}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Настройки')}</div>
                        <div className={`${Styles.btnsWrap} ${botMainData?.risk_settings?.length === 2 ? Styles.btnsWrapFhight : null} ${currentRiskType === 'custom' ? Styles.btnsWrapCustom : null}`}>
                            {botMainData?.risk_settings ? botMainData?.risk_settings.map((item, index)=>{
                                return (
                                    <div className={`${Styles.button} ${currentRiskType === item ? Styles.buttonGreen : Styles.buttonGray}`} key={'riskSettings' + index} onClick={()=>{changeRiskHandler(item)}}>{riskTextHandler(item)}</div>
                                )
                            }) : null}
                            {currentRiskType === 'custom' ?
                                <div
                                    className={`${Styles.button}`}
                                    onClick={()=>{
                                        if (currentRiskType === 'custom' && customSettings){
                                            if (botMainData?.subscription?.subscription_type === 'ProPlus'){
                                                dispatch(setBotProPlusModalStatus(true));
                                            } else {
                                                dispatch(setBotModalStatus(true));
                                            }
                                        }
                                    }}
                                >
                                    {t('Изменить настройки')}
                                </div>
                                : null}
                            {/*<div className={`${Styles.button} ${Styles.buttonGray}`}>Default</div>*/}
                            {/*<div className={`${Styles.button} ${Styles.buttonGreen}`}>Custom</div>*/}
                            {/*<div className={`${Styles.button} ${Styles.buttonOrange}`}>Change settings</div>*/}
                        </div>
                    </div>
                </div> :
                <div className={`${Styles.contentWrap} ${Styles.contentWrapSubscribe} ${Styles.honeeCardBody}`}>
                    <div className={`${Styles.subscribeWrap}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Добро пожаловать в HoneeAI')} 2.0!</div>
                        <div className={`${Styles.subscribeNoteWrap}`}>
                            <div className={`${Styles.subscribeNoteRow}`}>{t('Мы рады видеть ваш интерес ĸ HoneeAI!')}</div>
                            <div className={`${Styles.subscribeNoteRow}`}>{t('Чтобы начать пользоваться всеми возможностями нашего бота, пожалуйста, оформите подписĸу.')}</div>
                        </div>
                        <div
                            className={`${Styles.btn}`}
                            onClick={()=>{
                                if (botMainData?.pending_subscription){
                                    toast('Покупка подписки на Honee 2.0 брабатывается. Ожидайте завершения транзакции!')
                                } else {
                                    dispatch(setBotSubscribeModalStatus(true))
                                }
                            }}
                        >
                            {t('Подписаться')}
                        </div>
                    </div>
                </div>
            }
        </section>
    );
};

export default CabinetBotInformation;