import React from "react";
import Styles from './styles.module.scss';
import botLogo from "../../theme/assets/svg/honee_cabinet_bot_logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {setBotSubscribeModalStatus} from "../../store/cabinet/action";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const CabinetBotManagement = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const botMainData = useSelector(store => store.cabinet.botMainData);

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                {/*<div className={`${Styles.investWrap}`}>*/}
                {/*    <div className={`${Styles.titleWSF}`}>BOT MANAGMENT</div>*/}
                {/*    <div className={`${Styles.inputWrap}`}>*/}
                {/*        <div className={`${Styles.inputTitle}`}>Re-invest:</div>*/}
                {/*        <input type='text' className={`${Styles.cabinetInput}`} disabled={!botMainData?.subscription?.subscription_type}/>*/}
                {/*        <div className={`${Styles.inputAvailable}`}>Available balance: <span>0 USDT</span></div>*/}
                {/*    </div>*/}
                {/*    <div className={`${Styles.reinvestWrap}`}>*/}
                {/*        <div className={`${Styles.reinvestBox}`}>*/}
                {/*            <div className={`${Styles.profitInvestItemTitle}`}>Auto Re-invest:</div>*/}
                {/*            <div className={`${Styles.investBox}`}>*/}
                {/*                <input type="checkbox" onChange={(e)=>{setReInvest(e.target.checked)}} disabled={!botMainData?.subscription?.subscription_type}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={`${Styles.reinvestNote}`}>*/}
                {/*            The auto reinvestment feature is only*/}
                {/*            available with <span>PRO+</span> subscription.*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={`${Styles.detailsWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.imgWrap}`}>
                            <img src={botLogo} alt="honee 2.0"/>
                        </div>
                        <div
                            className={`${Styles.button}`}
                            onClick={()=>{
                                if (botMainData?.pending_subscription){
                                    toast(t('Покупка подписки на Honee 2.0 обрабатывается. Ожидайте завершения транзакции!'))
                                } else {
                                    dispatch(setBotSubscribeModalStatus(true))
                                }
                            }}
                        >
                            {botMainData?.subscription?.status === 'pending' || botMainData?.subscription?.subscription_type === false ? t('Подписаться') : t('Сменить подписку')}
                        </div>
                    </div>
                    <div className={`${Styles.dataWrap}`}>
                        <div className={`${Styles.dataItem}`}>
                            <div className={`${Styles.dataItemName}`}>{t('Спред')}:</div>
                            <div className={`${Styles.dataItemValue}`}>{botMainData?.spread ? botMainData?.spread : '0'}%</div>
                        </div>
                        <div className={`${Styles.dataItem}`}>
                            <div className={`${Styles.dataItemName}`}>{t('Кол-во сделок в день')}:</div>
                            <div className={`${Styles.dataItemValue}`}>{botMainData?.number_of_transactions ? botMainData?.number_of_transactions : '0'}</div>
                        </div>
                        <div className={`${Styles.dataItem}`}>
                            <div className={`${Styles.dataItemName}`}>{t('Средний доход сделки')}:</div>
                            <div className={`${Styles.dataItemValue}`}>${botMainData?.profit_avg ? botMainData?.profit_avg : '0'}</div>
                        </div>
                        <div className={`${Styles.dataItem}`}>
                            <div className={`${Styles.dataItemName}`}>{t('Кол-во бирж')}:</div>
                            <div className={`${Styles.dataItemValue}`}>{t('Динамические изменения от 40 до 80')}</div>
                            {/*<div className={`${Styles.dataItemValue}`}>{t('Динамические изменения от 40 до 80')} {botMainData?.exchanges_from ? botMainData?.exchanges_from : '0'} to {botMainData?.exchanges_to ? botMainData?.exchanges_to : '0'}</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetBotManagement;