import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {botInvestFailure, openBotFailure, setBotPromoModalStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";


const BotPromoModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const botPromoModalStatus = useSelector(store => store.cabinet.botPromoModalStatus);
    const botInvestStatusErr = useSelector(store => store.cabinet.botInvestStatusErr);
    const botInvestStatus = useSelector(store => store.cabinet.botInvestStatus);
    const newOpenBotErr = useSelector(store => store.cabinet.newOpenBotErr);
    const newOpenBot = useSelector(store => store.cabinet.newOpenBot);




    const [promo, setPromo] = useState('');


    const closeModal = () =>{
        dispatch(setBotPromoModalStatus({status: false, balance: false, sendAction: false, botId: false, isOpen: false, currentRisk: false}))
    }

    const sendHandler = () =>{
        if (botPromoModalStatus?.isOpen){
            if (promo.length > 0){
                dispatch(botPromoModalStatus?.sendAction({amount: botPromoModalStatus?.balance, risk_type: botPromoModalStatus?.currentRisk, promo_code: promo}))
            } else {
                dispatch(botPromoModalStatus?.sendAction({amount: botPromoModalStatus?.balance, risk_type: botPromoModalStatus?.currentRisk}))
            }
        } else {
            if (promo.length > 0){
                dispatch(botPromoModalStatus?.sendAction(botPromoModalStatus?.botId, {amount: botPromoModalStatus?.balance, promo_code: promo}))
            } else {
                dispatch(botPromoModalStatus?.sendAction(botPromoModalStatus?.botId, {amount: botPromoModalStatus?.balance}))
            }
        }
    }

    useEffect(()=>{
        if (botPromoModalStatus?.isOpen){
            if (newOpenBot){
                closeModal();
            }
        } else {
            if (botInvestStatus){
                closeModal();
            }
        }
    },[botInvestStatus, newOpenBot]) // eslint-disable-line


    return (
        <Popup
            open={botPromoModalStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Добавить средства')} ({botPromoModalStatus?.balance} Honee)</div>
                    <div className={`${Styles.modalContentBox}`}>
                        <div className={`${Styles.inputWrap} ${botPromoModalStatus?.isOpen ? newOpenBotErr?.promo_code ? Styles.inputWrapWithErr : null : botInvestStatusErr?.promo_code ? Styles.inputWrapWithErr : null}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Промокод (Необязательно)')}</div>
                            <input
                                type="text"
                                onChange={(e)=>{setPromo(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (botPromoModalStatus?.isOpen){
                                        if (newOpenBotErr?.promo_code){
                                            delete newOpenBotErr.promo_code;
                                            dispatch(openBotFailure(newOpenBotErr));
                                        }
                                    } else {
                                        if (botInvestStatusErr?.promo_code){
                                            delete botInvestStatusErr.promo_code;
                                            dispatch(botInvestFailure(botInvestStatusErr));
                                        }
                                    }
                                }}
                                value={promo}
                            />
                            <div className={`${Styles.inputErr}`}>{botPromoModalStatus?.isOpen ? newOpenBotErr?.promo_code : botInvestStatusErr?.promo_code}</div>
                        </div>
                    </div>
                    <div  className={`${Styles.saveBtn}`} onClick={()=>{sendHandler()}}>{t('Добавить средства')}</div>
                </div>
            </div>
        </Popup>
    );
};

export default BotPromoModal;
