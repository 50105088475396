import React from "react";
import Styles from './styles.module.scss';
import Logo from "../_UI/logo";
import {useDispatch, useSelector} from "react-redux";
import {setMobileNavStatus} from "../../store/cabinet/action";


const CabinetHeader = () => {
    const dispatch = useDispatch();

    const mobileNavStatus = useSelector(store => store.cabinet.mobileNavStatus);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${mobileNavStatus ? Styles.contentWrapWAbs : null}`}>
                <div className={`${Styles.burgerBtn}`} onClick={()=>{dispatch(setMobileNavStatus(!mobileNavStatus))}}>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <Logo/>
            </div>
        </section>
    );
};

export default CabinetHeader;