import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {spaceHandler} from "../../helpers/dotsHandler";



const IndexTotal = () => {
    const { t } = useTranslation();

    const indexMainData = useSelector(store => store.cabinet.indexMainData);


    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrap}`}>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemName} ${Styles.titleWSF}`}>{t('Всего инвесторов')}:</div>
                        <div className={`${Styles.contentWrapItemValue}`}>{indexMainData?.stats?.total_investors ? spaceHandler(indexMainData?.stats?.total_investors, true) : '0'}</div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemName} ${Styles.titleWSF}`}>{t('Ценность')}:</div>
                        <div className={`${Styles.contentWrapItemValue}`}>{indexMainData?.stats?.value ? spaceHandler(indexMainData?.stats?.value, true) : '0'}</div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.contentWrapItemName} ${Styles.titleWSF}`}>{t('Общая капитализация')}:</div>
                        <div className={`${Styles.contentWrapItemValue}`}>{indexMainData?.stats?.total_cup ? spaceHandler(indexMainData?.stats?.total_cup, true) : '0'}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndexTotal;
