import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";




const MainWork = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title2}`}><span>{t('Как работает HoneeAI 2.0')}</span> {t('и почему он приносит вам пользу')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxRow}`}>
                        <div className={`${Styles.contentBoxRowItem}`}>
                            <div className={`${Styles.contentBoxRowItemTitle}`}>{t('Технология ИИ')}</div>
                            <div className={`${Styles.contentBoxRowItemNote}`}>
                                {t('Наш алгоритм анализирует тысячи транзакций в секунду, находя оптимальные варианты арбитража')}.
                            </div>
                        </div>
                        <div className={`${Styles.contentBoxRowItem}`}>
                            <div className={`${Styles.contentBoxRowItemTitle}`}>{t('Низкие комиссии')}</div>
                            <div className={`${Styles.contentBoxRowItemNote}`}>
                                {t('Мы учитываем все комиссии и сокращаем затраты для максимизации вашей прибыли')}.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxRow}`}>
                        <div className={`${Styles.contentBoxRowItem}`}>
                            <div className={`${Styles.contentBoxRowItemTitle}`}>{t('Мгновенные сделки')}</div>
                            <div className={`${Styles.contentBoxRowItemNote}`}>
                                {t('Арбитражные сделки совершаются мгновенно, позволяя вам не упускать ни одну возможность')}.
                            </div>
                        </div>
                        <div className={`${Styles.contentBoxRowItem}`}>
                            <div className={`${Styles.contentBoxRowItemTitle}`}>{t('Удобный интерфейс')}</div>
                            <div className={`${Styles.contentBoxRowItemNote}`}>
                                {t('Простая настройка и мониторинг сделок, с возможностью персонализировать стратегию под ваши цели')}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainWork;