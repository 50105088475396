import React, {useEffect} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {getAmbassadorsSalaryData, setAmbassadorsModalSalaryStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";



const AmbassadorsModalSalary = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ambassadorsModalStatus = useSelector(store => store.cabinet.ambassadorsModalSalaryStatus);
    const ambassadorsSalaryData = useSelector(store => store.cabinet.ambassadorsSalaryData);

    const closeModal = () =>{
        dispatch(setAmbassadorsModalSalaryStatus(false))
    }

    useEffect(()=>{
        if (ambassadorsModalStatus){
            dispatch(getAmbassadorsSalaryData())
        }
    },[dispatch, ambassadorsModalStatus])


    return (
        <Popup
            open={ambassadorsModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.noteWrap}`}>
                        <div className={`${Styles.title3}`}>{t('О заработной плате')}</div>
                        <div className={`${Styles.note}`}>
                            {t('Вы получаете зарплату, ĸоторая зависит от того, сĸольĸо денег ежемесячно вносят на свой депозит ваши партнеры. Ваше вознаграждение будет выглядеть следующим образом')}:
                        </div>
                    </div>
                    <div className={`${Styles.tableContent}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.titleRowItem}`}>{t('Депозит за месяц')}</div>
                            <div className={`${Styles.titleRowItem}`}>{t('Награда')}</div>
                        </div>
                        <div className={`${Styles.tableWrap}`}>
                            {ambassadorsSalaryData ? ambassadorsSalaryData.map((item, index)=>{
                                return (
                                    <div className={`${Styles.tableRow}`} key={'ambassadorsSalaryData' + index}>
                                        <div className={`${Styles.tableRowItem}`}>${(item.monthly_referral_turnover * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>
                                        <div className={`${Styles.tableRowItem}`}>${(item.reward * 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</div>
                                    </div>
                                )
                            }) : <div className={`${Styles.loader}`}>Loading...</div>}
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default AmbassadorsModalSalary;
