import React from "react";
import {useNavigate} from "react-router-dom";
import Styles from './styles.module.scss';
import honeeLogo from '../../../theme/assets/svg/honee_logo.svg';



const Logo = () => {
    const navigate = useNavigate();

    return (
        <div
            className={`${Styles.logoWrap}`}
            onClick={()=>{navigate('/')}}
        >
            <img src={honeeLogo} alt="Honee"/>
        </div>
    );
};

export default Logo;