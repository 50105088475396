import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setBotProPlusModalStatus, setCustomSettings} from "../../store/cabinet/action";
import newBackIcon from "../../theme/assets/svg/h2_bot_back_icon.svg";

import legs1_1 from '../../theme/assets/bee/h2leg1_icon1.svg';
import legs1_2 from '../../theme/assets/bee/h2leg1_icon2.svg';
import legs1_3 from '../../theme/assets/bee/h2leg1_icon3.svg';

import legs2_1 from '../../theme/assets/bee/h2leg2_icon1.svg';
import legs2_2 from '../../theme/assets/bee/h2leg2_icon2.svg';
import legs2_3 from '../../theme/assets/bee/h2leg2_icon3.svg';

import legs3_1 from '../../theme/assets/bee/h2leg3_icon1.svg';
import legs3_2 from '../../theme/assets/bee/h2leg3_icon2.svg';
import legs3_3 from '../../theme/assets/bee/h2leg3_icon3.svg';

import head1 from '../../theme/assets/bee/h2head_icon1.svg';
import head2 from '../../theme/assets/bee/h2head_icon2.svg';
import head3 from '../../theme/assets/bee/h2head_icon3.svg';

import body1 from '../../theme/assets/bee/h2body_icon1.svg';
import body2 from '../../theme/assets/bee/h2body_icon2.svg';
import body3 from '../../theme/assets/bee/h2body_icon3.svg';

import wings1 from '../../theme/assets/bee/h2wing_icon1.svg';
import wings2 from '../../theme/assets/bee/h2wing_icon2.svg';
import wings3 from '../../theme/assets/bee/h2wing_icon3.svg';

import eye1 from '../../theme/assets/bee/h2eye_icon1.svg';
import eye2 from '../../theme/assets/bee/h2eye_icon2.svg';
import eye3 from '../../theme/assets/bee/h2eye_icon3.svg';

import sting1 from '../../theme/assets/bee/h2sting_icon1.svg';
import sting2 from '../../theme/assets/bee/h2sting_icon2.svg';
import sting3 from '../../theme/assets/bee/h2sting_icon3.svg';

import {useTranslation} from "react-i18next";



const BotProPlusModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const botMainData = useSelector(store => store.cabinet.botMainData);
    const botProPlusModalStatus = useSelector(store => store.cabinet.botProPlusModalStatus);
    const customSettings = useSelector(store => store.cabinet.customSettings);
    const userData = useSelector(store => store.main.userData);

    const indexDetected = (obj) =>{
        let defaultValue = 0;
        obj.options.forEach((item, index)=>{
            if (item.id === obj.current_option){
                defaultValue = index
            }
        })

        return defaultValue
    }

    const settingsIndexDetected = (setId) =>{
        let defaultValue = 0;
        customSettings.forEach((item, index)=>{
            if (item.id === setId){
                defaultValue = index
            }
        })

        return String(defaultValue)
    }

    const [setting1, setSetting1] = useState(customSettings ? indexDetected(customSettings[0]) : 0);
    const [setting2, setSetting2] = useState(customSettings ? indexDetected(customSettings[1]) : 0);
    const [setting3, setSetting3] = useState(customSettings ? indexDetected(customSettings[2]) : 0);
    const [setting4, setSetting4] = useState(customSettings ? indexDetected(customSettings[3]) : 0);
    const [setting5, setSetting5] = useState(customSettings ? indexDetected(customSettings[4]) : 0);
    const [setting6, setSetting6] = useState(customSettings ? indexDetected(customSettings[5]) : 0);
    const [setting7, setSetting7] = useState(customSettings ? indexDetected(customSettings[6]) : 0);
    const [setting8, setSetting8] = useState(customSettings ? indexDetected(customSettings[7]) : 0);

    const [dropStatus, setDropStatus] = useState(false);


    const imgData = [
        [head1, head2, head3],
        [body1, body2, body3],
        [wings1, wings2, wings3],
        [sting1, sting2, sting3],
        [eye1, eye2, eye3],
        [legs1_1, legs1_2, legs1_3],
        [legs2_1, legs2_2, legs2_3],
        [legs3_1, legs3_2, legs3_3],
    ]

    const closeModal = () =>{
        dispatch(setBotProPlusModalStatus(false))
    }

    const showCurrentChoice = (index) =>{
        switch (index){
            case '0':
                return setting1
            case '1':
                return setting2
            case '2':
                return setting3
            case '3':
                return setting4
            case '4':
                return setting5
            case '5':
                return setting6
            case '6':
                return setting7
            case '7':
                return setting8
            default:
                return setting8
        }
    }

    const riskShowHandler = (action, index) =>{

        const settingChoice = () =>{
                switch (dropStatus){
                    case '0':
                        return setSetting1
                    case '1':
                        return setSetting2
                    case '2':
                        return setSetting3
                    case '3':
                        return setSetting4
                    case '4':
                        return setSetting5
                    case '5':
                        return setSetting6
                    case '6':
                        return setSetting7
                    case '7':
                        return setSetting8
                    default:
                        return setSetting8
                }
        }
        if (dropStatus){
            if (action === 'enter'){
                settingChoice()(index);
            }

            if (action === 'leave'){
                settingChoice()(indexDetected(customSettings[dropStatus]))
            }

            if (action === 'click'){
                dispatch(setCustomSettings(botMainData?.bot?.id, {bot_custom_setting_id: customSettings[dropStatus]?.id, bot_custom_setting_option_id: customSettings[dropStatus]?.options[index]?.id}))
                setDropStatus(false);
            }
        }
    }

    const settingsClassHandler = (setting) =>{
        let currentIndex = null;

        setting.options.forEach((item, index)=>{
            if (setting.current_option === item.id){
                currentIndex = index
            }
        })

        if (currentIndex === 0){
            return Styles.settingsItemGreen
        }
        if (currentIndex === 1){
            return Styles.settingsItemRed
        }
        if (currentIndex === 2){
            return Styles.settingsItemOrange
        }
    }

    useEffect(()=>{
        if (customSettings){
            setSetting1(indexDetected(customSettings[0]));
            setSetting2(indexDetected(customSettings[1]));
            setSetting3(indexDetected(customSettings[2]));
            setSetting4(indexDetected(customSettings[3]));
            setSetting5(indexDetected(customSettings[4]));
            setSetting6(indexDetected(customSettings[5]));
            setSetting7(indexDetected(customSettings[6]));
            setSetting8(indexDetected(customSettings[7]));
        }
    },[customSettings])

    return (
        <Popup
            open={botProPlusModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Пользовательские настройки')}</div>
                    <div className={`${Styles.modalContentBox}`}>
                        <div className={`${Styles.settingsWrap}`}>
                            {dropStatus ?
                                <div className={`${Styles.dropBox}`}>
                                    <div className={`${Styles.dropName} ${Styles.titleWSF}`}>
                                        {customSettings?.[dropStatus]?.name}
                                    </div>
                                    <div
                                        className={`${Styles.dropItem} ${Styles.green}`}
                                        onClick={()=>{riskShowHandler('click', 0)}}
                                        onMouseEnter={()=>{riskShowHandler('enter', 0)}}
                                        onMouseLeave={()=>{riskShowHandler('leave', 0)}}
                                    >
                                        {customSettings?.[dropStatus]?.options?.[0].name}
                                    </div>
                                    <div
                                        className={`${Styles.dropItem} ${Styles.red}`}
                                        onClick={()=>{riskShowHandler('click', 1)}}
                                        onMouseEnter={()=>{riskShowHandler('enter', 1)}}
                                        onMouseLeave={()=>{riskShowHandler('leave', 1)}}
                                    >
                                        {customSettings?.[dropStatus]?.options?.[1].name}
                                    </div>
                                    <div
                                        className={`${Styles.dropItem} ${Styles.orange} `}
                                        onClick={()=>{riskShowHandler('click', 2)}}
                                        onMouseEnter={()=>{riskShowHandler('enter', 2)}}
                                        onMouseLeave={()=>{riskShowHandler('leave', 2)}}
                                    >
                                        {customSettings?.[dropStatus]?.options?.[2].name}
                                    </div>
                                    <div className={`${Styles.dropTitle}`} onClick={()=>{setDropStatus(false)}}>
                                        <div className={`${Styles.dropTitleBack}`}>
                                            <div className={`${Styles.dropTitleBackImgWrap}`}>
                                                <img src={newBackIcon} alt="back arrow"/>
                                            </div>
                                            <div className={`${Styles.dropTitleBackNote} ${Styles.titleWSF}`}>{t('Назад')}</div>
                                        </div>
                                    </div>
                                </div> :
                                <div className={`${Styles.settingsBox}`}>
                                    {customSettings ? customSettings.map((item, index)=>{
                                        return (
                                            <div
                                                className={`${Styles.settingsItem} ${settingsClassHandler(item)}`}
                                                key={'bee_settings_item' + index}
                                                onClick={()=>{
                                                    if (userData){
                                                        setDropStatus(settingsIndexDetected(item.id));
                                                    }
                                                }}
                                            >
                                                <div className={`${Styles.settingsItemName}`}>{item.name}</div>
                                                <div className={`${Styles.settingsItemValue}`}>({item.options[showCurrentChoice(String(index))].name})</div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            }
                        </div>
                        <div className={`${Styles.beeWrap}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={imgData[0][setting1]} alt="bee head"/>
                                <img src={imgData[1][setting2]} alt="bee body"/>
                                <img src={imgData[2][setting3]} alt="bee wings"/>
                                <img src={imgData[3][setting4]} alt="bee sting"/>
                                <img src={imgData[4][setting5]} alt="bee eye"/>
                                <img src={imgData[5][setting6]} alt="bee leg1"/>
                                <img src={imgData[6][setting7]} alt="bee leg2"/>
                                <img src={imgData[7][setting8]} alt="bee leg3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BotProPlusModal;
