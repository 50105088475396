import React from "react";
import Styles from './styles.module.scss';

import emptyIcon from '../../../theme/assets/svg/omt_epty_icon.svg';
import emptyBotIcon from '../../../theme/assets/svg/omt_empty_bot_icon.svg';
// import {setWalletCreateFormStatus} from "../../../store/wallet/action";
// import {useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";




const EmptyValues = (props) => {
    // const {text, withIcon, withBtn, type} = props;
    // const dispatch = useDispatch();
    const {text, withIcon, withBtn} = props;
    const { t } = useTranslation();

    // const btnHandler = () =>{
    //     if (type === 'walletAssets'){
    //         dispatch(setWalletCreateFormStatus(true))
    //     }
    // }

    const iconHandler = (iconType) =>{
        switch (iconType){
            case 'bot':
                return emptyBotIcon;
            default: return emptyIcon
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                {withIcon ? <div className={`${Styles.contentItemIconWrap}`}>
                    <img src={iconHandler(withIcon)} alt="warning"/>
                </div> : null}
                <div  className={`${Styles.contentItemText}`}>{text}</div>
                {withBtn ? <div className={`${Styles.contentItemBtn}`} onClick={()=>{}}>{t('Добавить')}</div> : null}
            </div>
        </section>
    );
};

export default EmptyValues;
