import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";

import considerationIcon from '../../theme/assets/svg/omt_ambassadors_btn_consideration.svg';
import {ambassadorLinkErrorsClear, sendAmbassadorLink} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";



const AmbassadorsTasks = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ambassadorCorrectLinks = useSelector(store => store.cabinet.ambassadorCorrectLinks);
    const ambassadorLinksErrors = useSelector(store => store.cabinet.ambassadorLinksErrors);


    const [socialTaskStatus1, setSocialTaskStatus1] = useState(ambassadorCorrectLinks?.social_post ? ambassadorCorrectLinks?.social_post[1]?.status !== 'rejected' : false);
    const [socialTaskStatus2, setSocialTaskStatus2] = useState(ambassadorCorrectLinks?.social_post ? ambassadorCorrectLinks?.social_post[2]?.status !== 'rejected' : false);

    const [amaLink, setAmaLink] = useState('');
    const [spaLink, setSpaLink] = useState('');
    const [videoLink, setVideoLink] = useState('');

    const [socLink1, setSocLink1] = useState('');
    const [socLink2, setSocLink2] = useState('');
    const [socLink3, setSocLink3] = useState('');


    const sendLinkHandler = (type, link, socIndex) =>{
        dispatch(sendAmbassadorLink(type, link, socIndex));
    }

    useEffect(()=>{
        if (ambassadorCorrectLinks?.social_post){
            setSocialTaskStatus1(ambassadorCorrectLinks?.social_post[1]?.status !== 'rejected');
            setSocialTaskStatus2(ambassadorCorrectLinks?.social_post[2]?.status !== 'rejected');
        }
    },[ambassadorCorrectLinks])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Прикрепить ссылки')}</div>
                    </div>
                    <div className={`${Styles.mainTaskWrap}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Ссылĸа на вашу АМА сессию')}</div>
                        {ambassadorCorrectLinks?.ama?.status !== 'pending' ?
                            <div className={`${Styles.inputWrap} ${ambassadorLinksErrors?.ama ? Styles.inputWrapWithErr : null}`}>
                                <input
                                    type="text"
                                    onChange={(e)=>{setAmaLink(e.target.value)}}
                                    value={amaLink}
                                    onKeyDown={(event)=>{
                                        if (ambassadorLinksErrors?.ama){
                                            dispatch(ambassadorLinkErrorsClear('ama'));
                                        }
                                    }}
                                />
                                <div className={`${Styles.sendBtn}`} onClick={()=>{sendLinkHandler('ama', amaLink)}}>{t('Отправить')}</div>
                                <div className={`${Styles.inputErr}`}>{ambassadorLinksErrors?.ama?.url}</div>
                            </div> :
                            <div className={`${Styles.fakeInputWrap}`}>
                                <div className={`${Styles.fakeInput}`}>{ambassadorCorrectLinks?.ama?.url}</div>
                                <div className={`${Styles.statusBtn} ${Styles.statusBtnConsideration}`}>
                                    <img src={considerationIcon} alt={ambassadorCorrectLinks?.ama?.status}/>
                                </div>
                            </div>
                        }
                        <div className={`${Styles.noteWrap}`}>
                            <div className={`${Styles.noteWrapItem}`}>
                                <div className={`${Styles.noteWrapItemTitle}`}>{t('Условия')}:</div>
                                <div className={`${Styles.noteWrapItemContent}`}>
                                    <div className={`${Styles.noteWrapItemContentRow}`}>
                                        <span className={`${Styles.colored}`}>1 lvl</span> - 20 - 50 {t('зрителей присутствующих на АМА сессии')}
                                    </div>
                                    <div className={`${Styles.noteWrapItemContentRow}`}>
                                        <span className={`${Styles.colored}`}>2 lvl</span> - 50+ {t('зрителей присутствующих на АМА сессии')}
                                    </div>
                                </div>
                            </div>
                            <div className={`${Styles.noteWrapItem}`}>
                                <div className={`${Styles.noteWrapItemTitle}`}>{t('Награды')}:</div>
                                <div className={`${Styles.noteWrapItemContent}`}>
                                    <div className={`${Styles.noteWrapItemContentRow}`}>
                                        <span className={`${Styles.colored}`}>1 lvl</span> - 2 {t('кодов')} 5% + 10 OMT
                                    </div>
                                    <div className={`${Styles.noteWrapItemContentRow}`}>
                                        <span className={`${Styles.colored}`}>2 lvl</span> - 5 {t('кодов')} 5% + 20 OMT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.otherTaskWrap}`}>
                        <div className={`${Styles.otherTaskBox}`}>
                            {ambassadorCorrectLinks?.social_post_ambassador?.status !== 'pending' ?
                                <div className={`${Styles.inputWrap}`}>
                                    <div className={`${Styles.inputRow} ${ambassadorLinksErrors?.social_post_ambassador ? Styles.inputRowWithErr : null}`}>
                                        <div className={`${Styles.inputTitle}`}>{t('Ссылĸа на социальную сеть, в ĸоторой уĸазано, что вы являетесь Амбассадором ОМТ')}</div>
                                        <input
                                            type="text"
                                            onChange={(e)=>{setSpaLink(e.target.value)}}
                                            value={spaLink}
                                            onKeyDown={(event)=>{
                                                if (ambassadorLinksErrors?.social_post_ambassador){
                                                    dispatch(ambassadorLinkErrorsClear('social_post_ambassador'));
                                                }
                                            }}
                                        />
                                        <div className={`${Styles.inputErr}`}>{ambassadorLinksErrors?.social_post_ambassador?.url}</div>
                                    </div>
                                    <div className={`${Styles.reward}`}>
                                        <div className={`${Styles.rewardTitle}`}>{t('Награда')}</div>
                                        <div className={`${Styles.rewardValue}`}>3 OMT</div>
                                    </div>
                                    <div className={`${Styles.sendBtn}`} onClick={()=>{sendLinkHandler('social_post_ambassador', spaLink)}}>{t('Отправить')}</div>
                                </div> :
                                <div className={`${Styles.fakeInputWrap}`}>
                                    <div className={`${Styles.inputRow}`}>
                                        <div className={`${Styles.inputTitle}`}>{t('Ссылĸа на социальную сеть, в ĸоторой уĸазано, что вы являетесь Амбассадором ОМТ')}</div>
                                        <div className={`${Styles.fakeInput}`}>{ambassadorCorrectLinks?.social_post_ambassador?.url}</div>
                                    </div>
                                    <div className={`${Styles.reward}`}>
                                        <div className={`${Styles.rewardTitle}`}>{t('Награда')}</div>
                                        <div className={`${Styles.rewardValue}`}>3 OMT</div>
                                    </div>
                                    <div className={`${Styles.statusBtn} ${Styles.statusBtnConsideration}`}>
                                        <img src={considerationIcon} alt={ambassadorCorrectLinks?.social_post_ambassador?.status}/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`${Styles.otherTaskBox}`}>
                            {ambassadorCorrectLinks?.video?.status !== 'pending' ?
                                <div className={`${Styles.inputWrap}`}>
                                    <div className={`${Styles.inputRow} ${ambassadorLinksErrors?.video ? Styles.inputRowWithErr : null}`}>
                                        <div className={`${Styles.inputTitle}`}>{t('Ссылĸа на видео')}</div>
                                        <input
                                            type="text"
                                            onChange={(e)=>{setVideoLink(e.target.value)}}
                                            value={videoLink}
                                            onKeyDown={(event)=>{
                                                if (ambassadorLinksErrors?.video){
                                                    dispatch(ambassadorLinkErrorsClear('video'));
                                                }
                                            }}
                                        />
                                        <div className={`${Styles.inputErr}`}>{ambassadorLinksErrors?.video?.url}</div>
                                    </div>
                                    <div className={`${Styles.reward}`}>
                                        <div className={`${Styles.rewardTitle}`}>{t('Награда')}</div>
                                        <div className={`${Styles.rewardValue}`}>15 OMT</div>
                                    </div>
                                    <div className={`${Styles.sendBtn}`} onClick={()=>{sendLinkHandler('video', videoLink)}}>{t('Отправить')}</div>
                                </div> :
                                <div className={`${Styles.fakeInputWrap}`}>
                                    <div className={`${Styles.inputRow}`}>
                                        <div className={`${Styles.inputTitle}`}>{t('Ссылĸа на видео')}</div>
                                        <div className={`${Styles.fakeInput}`}>{ambassadorCorrectLinks?.video?.url}</div>
                                    </div>
                                    <div className={`${Styles.reward}`}>
                                        <div className={`${Styles.rewardTitle}`}>{t('Награда')}</div>
                                        <div className={`${Styles.rewardValue}`}>15 OMT</div>
                                    </div>
                                    <div className={`${Styles.statusBtn} ${Styles.statusBtnConsideration}`}>
                                        <img src={considerationIcon} alt={ambassadorCorrectLinks?.video?.status}/>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`${Styles.otherTaskBox} ${Styles.otherTaskBoxSocialContent}`}>
                            <div className={`${Styles.inputTitleRow}`}>
                                <div className={`${Styles.inputTitle}`}>{t('Ссылĸа на пост в социальных сетях')}</div>
                                <div className={`${Styles.inputTitle}`}>{t('Награда')}</div>
                            </div>
                            <div className={`${Styles.socialContent}`}>
                                {ambassadorCorrectLinks?.social_post?.length > 0 ?
                                    <div className={`${Styles.socialRow}`}>
                                        {ambassadorCorrectLinks?.social_post[0]?.status === 'rejected' ?
                                            <div className={`${Styles.inputWrap} ${ambassadorLinksErrors?.social_post?.index === '1' ? Styles.inputWrapWithErr : null}`}>
                                                <div className={`${Styles.inputRow}`}>
                                                    <input
                                                        type="text"
                                                        onChange={(e)=>{setSocLink1(e.target.value)}}
                                                        value={socLink1}
                                                    />
                                                </div>
                                                <div className={`${Styles.reward}`}>
                                                    <div className={`${Styles.rewardValue}`}>1 OMT</div>
                                                </div>
                                                <div className={`${Styles.sendBtn}`} onClick={()=>{sendLinkHandler('social_post', socLink1, '1')}}>{t('Отправить')}</div>
                                            </div> :
                                            <div className={`${Styles.fakeInputWrap}`}>
                                                <div className={`${Styles.inputRow}`}>
                                                    <div className={`${Styles.fakeInput}`}>{ambassadorCorrectLinks.social_post[0].url}</div>
                                                </div>
                                                <div className={`${Styles.reward}`}>
                                                    <div className={`${Styles.rewardValue}`}>1 OMT</div>
                                                </div>
                                                <div className={`${Styles.statusBtn} ${Styles.statusBtnConsideration}`}>
                                                    <img src={considerationIcon} alt={ambassadorCorrectLinks.social_post[0].status}/>
                                                </div>
                                            </div>
                                        }
                                        {socialTaskStatus1 ? ambassadorCorrectLinks.social_post[1].status === 'rejected' ?
                                                <div className={`${Styles.inputWrap} ${ambassadorLinksErrors?.social_post?.index === '2' ? Styles.inputWrapWithErr : null}`}>
                                                    <div className={`${Styles.inputRow}`}>
                                                        <input
                                                            type="text"
                                                            onChange={(e)=>{setSocLink2(e.target.value)}}
                                                            value={socLink2}
                                                        />
                                                    </div>
                                                    <div className={`${Styles.reward}`}>
                                                        <div className={`${Styles.rewardValue}`}>1 OMT</div>
                                                    </div>
                                                    <div className={`${Styles.sendBtn}`} onClick={()=>{sendLinkHandler('social_post', socLink2, '2')}}>{t('Отправить')}</div>
                                                </div> :
                                                <div className={`${Styles.fakeInputWrap}`}>
                                                    <div className={`${Styles.inputRow}`}>
                                                        <div className={`${Styles.fakeInput}`}>{ambassadorCorrectLinks.social_post[1].url}</div>
                                                    </div>
                                                    <div className={`${Styles.reward}`}>
                                                        <div className={`${Styles.rewardValue}`}>1 OMT</div>
                                                    </div>
                                                    <div className={`${Styles.statusBtn} ${Styles.statusBtnConsideration}`}>
                                                        <img src={considerationIcon} alt={ambassadorCorrectLinks.social_post[1].status}/>
                                                    </div>
                                                </div> :
                                            <div className={`${Styles.btnInputWrap}`}>
                                                <div
                                                    className={`${Styles.btnInputBox}`}
                                                    onClick={()=>{
                                                        setSocialTaskStatus1(true);
                                                    }}
                                                >
                                                    <div className={`${Styles.btnInput}`}>+ {t('добавить ссылку')}</div>
                                                </div>
                                            </div>
                                        }
                                        {socialTaskStatus2 ? ambassadorCorrectLinks.social_post[2].status === 'rejected' ?
                                                <div className={`${Styles.inputWrap} ${ambassadorLinksErrors?.social_post?.index === '3' ? Styles.inputWrapWithErr : null}`}>
                                                    <div className={`${Styles.inputRow}`}>
                                                        <input
                                                            type="text"
                                                            onChange={(e)=>{setSocLink3(e.target.value)}}
                                                            value={socLink3}
                                                        />
                                                    </div>
                                                    <div className={`${Styles.reward}`}>
                                                        <div className={`${Styles.rewardValue}`}>1 OMT</div>
                                                    </div>
                                                    <div className={`${Styles.sendBtn}`} onClick={()=>{sendLinkHandler('social_post', socLink3, '3')}}>{t('Отправить')}</div>
                                                </div> :
                                                <div className={`${Styles.fakeInputWrap}`}>
                                                    <div className={`${Styles.inputRow}`}>
                                                        <div className={`${Styles.fakeInput}`}>{ambassadorCorrectLinks.social_post[2].url}</div>
                                                    </div>
                                                    <div className={`${Styles.reward}`}>
                                                        <div className={`${Styles.rewardValue}`}>1 OMT</div>
                                                    </div>
                                                    <div className={`${Styles.statusBtn} ${Styles.statusBtnConsideration}`}>
                                                        <img src={considerationIcon} alt={ambassadorCorrectLinks.social_post[2].status}/>
                                                    </div>
                                                </div> :
                                            <div className={`${Styles.btnInputWrap}`}>
                                                <div
                                                    className={`${Styles.btnInputBox}`}
                                                    onClick={()=>{
                                                        if (socialTaskStatus1){
                                                            setSocialTaskStatus2(true);
                                                        } else {
                                                            setSocialTaskStatus1(true);
                                                        }
                                                    }}
                                                >
                                                    <div className={`${Styles.btnInput}`}>+ {t('добавить ссылку')}</div>
                                                </div>
                                            </div>
                                        }
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbassadorsTasks;
