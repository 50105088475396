import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import TransactionsMain from "../../components/transactionsMain";
import {getWalletTransactions} from "../../store/cabinet/action";


const TransactionsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const walletTransactions = useSelector(store => store.cabinet.walletTransactions);

    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    // useEffect(()=>{
    //     dispatch(getWalletTransactions());
    // },[dispatch])

    useEffect(()=>{
        if (!walletTransactions){
            dispatch(getWalletTransactions());
        } else if (walletTransactions.current_page + 1 <= walletTransactions.last_page && isScrolledToEnd){
            dispatch(getWalletTransactions(walletTransactions.current_page + 1));
            setIsScrolledToEnd(false);
        }

    },[dispatch, isScrolledToEnd]) // eslint-disable-line

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Транзакции')}/>
            <div className={`${Styles.contentWrap}`}>
                <TransactionsMain scrollAction={setIsScrolledToEnd}/>
            </div>
        </section>
    );
};

export default TransactionsPage;