import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

import registerIcon1 from '../../theme/assets/svg/honee_main_register_icon1.svg';
import registerIcon2 from '../../theme/assets/svg/honee_main_register_icon2.svg';
import registerIcon3 from '../../theme/assets/svg/honee_main_register_icon3.svg';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";



const MainRegister = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    const btnHandler = () => {
        if (authStatus.phrase){
            if (authStatus.server){
                navigate('/honee');
            } else {
                navigate('/server');
            }
        } else {
            if (authStatus.authorization){
                navigate('/confirmation');
            } else {
                navigate('/sign-up');
            }
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.textWrap}`}>
                    <div className={`${Styles.title2}`}><span>{t('3 Простых шага')}</span> {t('Для регистрации')}</div>
                    <div className={`${Styles.note}`}>
                        {t('Регистрация займет всего несколько минут! Не забывайте, что регистрация абсолютно бесплатна и вы всегда можете обратиться в нашу службу поддержки в случае возникновения вопросов или трудностей. Добро пожаловать в мир криптовалют с нашими технологиями')}!
                    </div>
                </div>
                <div className={`${Styles.stepWrap}`}>
                    <div className={`${Styles.stepWrapItem}`}>
                        <div className={`${Styles.imgWrap}`}>
                            <img src={registerIcon1} alt="01"/>
                        </div>
                        <div className={`${Styles.stepWrapItemContent}`}>
                            {t('Зарегистрируйтесь за 1 минуту и получите доступ к платформе, которая помогает зарабатывать на криптовалютных сделках')}.
                        </div>
                    </div>
                    <div className={`${Styles.stepWrapItem}`}>
                        <div className={`${Styles.imgWrap}`}>
                            <img src={registerIcon2} alt="02"/>
                        </div>
                        <div className={`${Styles.stepWrapItemContent}`}>
                            {t('Настройте параметры стратегии, активируйте автоматизированный процесс и начните получать прибыль с минимальными усилиями')}.
                        </div>
                    </div>
                    <div className={`${Styles.stepWrapItem}`}>
                        <div className={`${Styles.imgWrap}`}>
                            <img src={registerIcon3} alt="03"/>
                        </div>
                        <div className={`${Styles.stepWrapItemContent}`}>
                            {t('Наблюдайте, как система автоматически анализирует рынок и совершает сделки для стабильного заработка')}.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <div className={`${Styles.button}`} onClick={()=>{btnHandler()}}>{t('Зарегестрируйтесь')}</div>
                </div>
            </div>
        </section>
    );
};

export default MainRegister;