import React, {useCallback, useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {registration, registrationFailure} from "../../store/main/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";


const RegPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formErr = useSelector(store => store.main.registrationErr);
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const {code} = useParams()
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [referral_code, setReferral_code] = useState(code ? code : '');

    const sendFormHandler = useCallback(()=>{
        dispatch(registration({email, password, password_confirmation, referral_code: code ? code : referral_code}));
    }, [dispatch, email, password, password_confirmation, code, referral_code])

    useEffect(()=>{
        if (authStatus.authorization){
            if (authStatus.phrase){
                if (authStatus.server){
                    navigate('/honee');
                } else {
                    navigate('/server');
                }
            } else {
                navigate('/confirmation');
            }
        }
    },[authStatus, navigate])

    useEffect(() => {
        const onKeyDown = e => {
            if(e.keyCode === 13) {
                sendFormHandler();
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [sendFormHandler]);


    return (
        <section className={`${Styles.container} ${Styles.pageContainer}`}>
            <Seo title={t('Регистрация')}/>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Зерегистрируйте новый аккаунт')}</div>
                {/*<div className={`${Styles.typeWrap}`}>*/}
                {/*    <div*/}
                {/*        className={`${Styles.typeItem} ${regType === 'email' ? Styles.typeItemActive : null}`}*/}
                {/*        onClick={()=>{setRegType('email')}}*/}
                {/*    >*/}
                {/*        {t('Почта')}*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={`${Styles.typeItem} ${regType === 'wallet' ? Styles.typeItemActive : null}`}*/}
                {/*        onClick={()=>{setRegType('wallet')}}*/}
                {/*    >*/}
                {/*        {t('Кошелек')}*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={`${Styles.inputsBox}`}>
                    <div className={`${Styles.inputWrap} ${formErr?.email ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Логин (ваш email)')}</div>
                        <input
                            type="email"
                            placeholder='example@gmail.com'
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.email){
                                    delete formErr.email;
                                    dispatch(registrationFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.email ? formErr.email : null}</div>
                    </div>
                    <div className={`${Styles.inputWrap} ${formErr?.password ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Пароль')}</div>
                        <input
                            type="password"
                            placeholder='********'
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.password){
                                    delete formErr.password;
                                    dispatch(registrationFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.password?.[0] ? formErr.password[0] : null}</div>
                    </div>
                    <div className={`${Styles.inputWrap} ${formErr?.password_confirmation ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Подтвердите пароль')}</div>
                        <input
                            type="password"
                            placeholder='********'
                            value={password_confirmation}
                            onChange={(e)=>{setPassword_confirmation(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.password_confirmation){
                                    delete formErr.password_confirmation;
                                    dispatch(registrationFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}/>
                    </div>
                    <div className={`${Styles.inputWrap}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Реферальный код (Необязательно)')}</div>
                        {code ?
                            <div className={`${Styles.fakeInput}`}>{code}</div> :
                            <input
                                type="text"
                                placeholder='referral code'
                                value={referral_code}
                                onChange={(e)=>{setReferral_code(e.target.value)}}
                            />
                        }

                    </div>
                </div>
                <div className={`${Styles.links}`}>{t('У вас уже есть аккаунт')}? <span onClick={()=>{navigate('/sign-in')}}>{t('Войти')}</span></div>
                <div className={`${Styles.mainBtn}`} onClick={()=>{sendFormHandler()}}>{t('Регистрация')}</div>
            </div>
        </section>
    );
};

export default RegPage;