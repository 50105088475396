import React, {useEffect, useState} from "react";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getCryptoNews, getCryptoNewsSuccess,
    getIndicators,
    getIndicatorsSuccess,
    setStandardWalletCryptoModalStatus
} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";

import signalArrow from '../../theme/assets/svg/omt_signal_arrow.svg';
import Moment from "react-moment";
import NextIcon from "../_UI/nextIcon";

const StandardWalletCryptoModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isNewsContent, setIsNewsContent] = useState(false);
    const [signalStatus, setSignalStatus] = useState(false);
    const [signalData, setSignalData] = useState([]);
    const [total, setTotal] = useState({signals: 0, signalsMax: 0, prediction: ''})
    const [time, setTime] = useState(0);
    const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
    const [nextBtnHoverStatus, setNextBtnHoverStatus] = useState(false);
    const [prevBtnHoverStatus, setPrevBtnHoverStatus] = useState(false);
    const [contentWaitStatus, setContentWaitStatus] = useState(false);

    const timeContent = [
        {name: '1 min', value: '1m', graphValue: '1'},
        {name: '5 min', value: '5m', graphValue: '5'},
        {name: '15 min', value: '15m', graphValue: '15'},
        {name: '1h', value: '1h', graphValue: '60'},
        {name: '1d', value: '1d', graphValue: 'D'},
        {name: '1 week', value: '1w', graphValue: 'W'},
    ]

    const standardWalletCryptoModalStatus = useSelector(store => store.cabinet.standardWalletCryptoModalStatus);
    const walletIndicators = useSelector(store => store.cabinet.walletIndicators);
    const cryptoNewsData = useSelector(store => store.cabinet.cryptoNewsData);


    // console.log(walletIndicators);
    // console.log(standardWalletCryptoModalStatus);
    // console.log(`${standardWalletCryptoModalStatus?.item?.currency?.source}:${standardWalletCryptoModalStatus?.item?.currency?.pair}`);


    const nextNewsBtnHandler = (isNext) =>{
        setContentWaitStatus(true);
        if (isNext){
            if (cryptoNewsData?.[currentNewsIndex + 1]){
                setCurrentNewsIndex(currentNewsIndex + 1)
            } else {
                setCurrentNewsIndex(0)
            }
        } else {
            if (cryptoNewsData?.[currentNewsIndex - 1]){
                setCurrentNewsIndex(currentNewsIndex - 1)
            } else {
                if (cryptoNewsData?.[Object.keys(cryptoNewsData).length]){
                    setCurrentNewsIndex(Object.keys(cryptoNewsData).length)
                }
            }
        }
    }

    const closeModal = () =>{
        dispatch(setStandardWalletCryptoModalStatus({status: false, item: false}));
        dispatch(getIndicatorsSuccess(false));
        dispatch(getCryptoNewsSuccess(false));
        setCurrentNewsIndex(0);
        setTime(0);
        setTotal({signals: 0, signalsMax: 0, prediction: ''});
        setSignalStatus(false);
        setIsNewsContent(false);
    }

    const signalClosePredictionContentHandler = (prediction) =>{
        switch (prediction){
            case '0':
                return <div className={`${Styles.cb} ${Styles.cbh}`}>{t('hold')}</div>
            case '-1':
                return <div className={`${Styles.cb} ${Styles.cbs}`}>{t('sell')}</div>
            case '-2':
                return <div className={`${Styles.cb} ${Styles.cbs}`}>{t('sell+')}</div>
            case '1':
                return <div className={`${Styles.cb} ${Styles.cbb}`}>{t('buy')}</div>
            case '2':
                return <div className={`${Styles.cb} ${Styles.cbb}`}>{t('buy+')}</div>
            default :
                return <div className={`${Styles.cb}`}>{t('load')}</div>
        }
    }

    const signalOpenPredictionContentHandler = (prediction) =>{
        switch (prediction){
            case '0':
                return <div className={`${Styles.ct} ${Styles.cth}`}>{t('hold')}</div>
            case '-1':
                return <div className={`${Styles.ct} ${Styles.cts}`}>{t('sell')}</div>
            case '-2':
                return <div className={`${Styles.ct} ${Styles.cts}`}>{t('sell+')}</div>
            case '1':
                return <div className={`${Styles.ct} ${Styles.ctb}`}>{t('buy')}</div>
            case '2':
                return <div className={`${Styles.ct} ${Styles.ctb}`}>{t('buy+')}</div>
            default :
                return <div className={`${Styles.ct}`}>{t('load')}</div>
        }
    }

    const imgLoadHandler = () =>{
        setContentWaitStatus(false);
    }

    const renamedSignals = {
        relative_strength_index: 'RSI',
        stochastic_k: 'SK',
        commodity_chanel_index: 'CCI',
        average_directional_index: 'ADI',
        awesome_oscillator: 'AO',
        momentum: 'MM',
        MACD: 'MACD',
        stochastic_rsi_fast: 'SRF',
        williams_percent_range: 'WPR',
        bear_bull_power: 'BBP',
        ultimate_oscillator: 'UO',
        ichimoku_base_line: 'IBL',
        volume_weighted_moving_average: 'VWMA',
        hull_moving_average: 'HMA',
        exponential_moving_average_10: 'EMA-10',
        exponential_moving_average_20: 'EMA-20',
        exponential_moving_average_30: 'EMA-30',
        exponential_moving_average_50: 'EMA-50',
        exponential_moving_average_100: 'EMA-100',
        exponential_moving_average_200: 'EMA-200',
        simple_moving_average_10: 'SMA-10',
        simple_moving_average_20: 'SMA-20',
        simple_moving_average_30: 'SMA-30',
        simple_moving_average_50: 'SMA-50',
        simple_moving_average_100: 'SMA-100',
        simple_moving_average_200: 'SMA-200'
    }

    // useEffect(()=>{
    //     if (contentWaitStatus){
    //         const timer = setTimeout(() => {
    //
    //         }, 1000);
    //         return () => clearTimeout(timer);
    //     }
    // },[contentWaitStatus])

    useEffect(()=>{
        if (isNewsContent){
            if (standardWalletCryptoModalStatus?.item.currency?.id){
                dispatch(getCryptoNews(standardWalletCryptoModalStatus?.item.currency?.id))
            }
        } else {
            if (standardWalletCryptoModalStatus?.item.currency?.id){
                dispatch(getIndicators(standardWalletCryptoModalStatus?.item.currency?.id, timeContent[time].value));
                const interval = setInterval(()=>{
                    dispatch(getIndicators(standardWalletCryptoModalStatus?.item.currency?.id, timeContent[time].value));
                }, 60000)
                return ()=>{
                    clearInterval(interval)
                }
            }
        }

    },[dispatch, standardWalletCryptoModalStatus, time, isNewsContent]) // eslint-disable-line

    useEffect(()=>{
        if (walletIndicators){
            let signalsCounter = 0;
            let signalsMaxCounter = 0;
            let signalArrData = [];

            for (let key in walletIndicators) {
                if (walletIndicators[key]?.signal === walletIndicators?.overall_signal){
                    signalsCounter++
                }
                if (renamedSignals[key]){
                    signalArrData.push({name: renamedSignals[key], prediction: walletIndicators[key]?.signal})
                }
                signalsMaxCounter++
            }

            setTotal({signals: signalsCounter, signalsMax: signalsMaxCounter - 2, prediction: String(walletIndicators?.overall_signal)});
            setSignalData(signalArrData);
        }
    },[walletIndicators]) // eslint-disable-line

    return (
        <Popup
            open={standardWalletCryptoModalStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.tabRow}`}>
                        <div
                            className={`${Styles.tabRowItem} ${!isNewsContent ? Styles.tabRowItemActive : null}`}
                            onClick={()=>{setIsNewsContent(false)}}
                        >
                            {t('Информация')}
                        </div>
                        <div
                            className={`${Styles.tabRowItem} ${isNewsContent ? Styles.tabRowItemActive : null}`}
                            onClick={()=>{setIsNewsContent(true)}}
                        >
                            {t('Новости')}
                        </div>
                    </div>
                    <div className={`${Styles.modalContentBox}`}>
                        {walletIndicators ?
                            isNewsContent ?
                                <div className={`${Styles.newsPosWrap}`}>
                                    <div className={`${Styles.newsWrap}`}>
                                        {cryptoNewsData?.[currentNewsIndex]  ?
                                            <>
                                                <div className={`${Styles.newsLoading}`} style={{display: `${contentWaitStatus ? 'grid' : 'none'}`}}>Loading...</div>
                                                <div className={`${Styles.newsBox}`} style={{display: `${!contentWaitStatus ? 'grid' : 'none'}`}}>
                                                    <div className={`${Styles.newsTitle}`}>
                                                        {cryptoNewsData?.[currentNewsIndex]?.title}
                                                    </div>
                                                    <div className={`${Styles.newsDate}`}>
                                                        {t('Дата')}: <Moment format="DD/MM/YYYY">{cryptoNewsData?.[currentNewsIndex]?.publishDate}</Moment>
                                                    </div>
                                                    <div className={`${Styles.newsImgWrap}`}>
                                                        <img
                                                            onLoad={imgLoadHandler}
                                                            src={cryptoNewsData?.[currentNewsIndex]?.image} alt={cryptoNewsData?.[currentNewsIndex]?.title}/>
                                                    </div>
                                                    <div className={`${Styles.newsDescription}`}>
                                                        {cryptoNewsData?.[currentNewsIndex]?.summary}
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </div>
                                    <div className={`${Styles.navRow}`}>
                                        <div
                                            className={`${Styles.navBtn}`}
                                            onMouseEnter={()=>{setPrevBtnHoverStatus(true)}}
                                            onMouseLeave={()=>{setPrevBtnHoverStatus(false)}}
                                            onClick={()=>{nextNewsBtnHandler(false)}}
                                        >
                                            <div className={`${Styles.navBtnImgWrap}`}>
                                                <NextIcon isHover={prevBtnHoverStatus}/>
                                            </div>
                                            <div className={`${Styles.navBtnText}`}>{t('Назад')}</div>
                                        </div>
                                        <div
                                            className={`${Styles.navBtn}`}
                                            onMouseEnter={()=>{setNextBtnHoverStatus(true)}}
                                            onMouseLeave={()=>{setNextBtnHoverStatus(false)}}
                                            onClick={()=>{nextNewsBtnHandler(true)}}
                                        >
                                            <div className={`${Styles.navBtnText}`}>{t('Вперед')}</div>
                                            <div className={`${Styles.navBtnImgWrap}`}>
                                                <NextIcon isHover={nextBtnHoverStatus}/>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className={`${Styles.infoWrap}`}>
                                    <div className={`${Styles.timeRow}`}>
                                        <div className={`${Styles.pairName}`}>{standardWalletCryptoModalStatus?.item?.currency?.ticker} / USDT</div>
                                        <div className={`${Styles.timeBox}`}>
                                            {timeContent.map((item, index)=>{
                                                return (
                                                    <div className={`${Styles.timeBoxItem}`} key={'timeContentItem' + index}>
                                                        <div
                                                            className={`${Styles.textContent} ${timeContent[time].value === item.value ? Styles.textContentActive : null}`}
                                                            onClick={()=>{setTime(index)}}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={`${Styles.graphWrap}`}>
                                        <TradingViewWidget
                                            symbol={`${standardWalletCryptoModalStatus?.item?.currency?.source}:${standardWalletCryptoModalStatus?.item?.currency?.pair}`}
                                            interval={timeContent[time].graphValue}
                                            theme={Themes.DARK}
                                            locale="en"
                                            autosize={true}
                                            height={360}
                                            resolveSymbol="NASDAQ:AAPL"
                                            allow_symbol_change={false}
                                            // disabled_features={['use_localstorage_for_settings', 'header_symbol_search']}
                                            hide_top_toolbar={true}
                                            hide_legend={true}
                                            save_image={false}
                                        />
                                    </div>
                                    <div className={`${Styles.predictionWrap}`}>
                                        <div className={`${Styles.signalBox}`}>
                                            <div className={`${Styles.signalController}`} onClick={()=>{setSignalStatus(!signalStatus)}}>
                                                <div className={`${Styles.signalControllerText}`}>{t('Торговые сигналы')}</div>
                                                <div className={`${Styles.signalControllerArrow} ${signalStatus ? Styles.signalControllerArrowOpen : null}`}><img src={signalArrow} alt="dropdown arrow"/></div>
                                            </div>
                                            <div className={`${Styles.signalContent}`}>
                                                {signalStatus ?
                                                    <div className={`${Styles.signalOpen}`}>
                                                        <div className={`${Styles.signalOpenBox}`}>
                                                            {signalData.map((item, index)=>{
                                                                return (
                                                                    <div className={`${Styles.signalOpenBoxItem}`} key={'signalOpenBoxItem' + index}>
                                                                        <div className={`${Styles.signalOpenBoxItemName}`}>{item.name}</div>
                                                                        <div className={`${Styles.signalOpenBoxItemSeparator}`}>-</div>
                                                                        <div className={`${Styles.signalOpenBoxItemPrediction}`}>{walletIndicators ? signalOpenPredictionContentHandler(String(item.prediction)) : <div className={`${Styles.ct}`}>{t('nope')}</div>}</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div> :
                                                    <div className={`${Styles.signalClose}`}>
                                                        <div className={`${Styles.totalRow}`}>
                                                            <div className={`${Styles.totalRowValues}`}>{t('Всего')} {walletIndicators?.total}/{total?.signalsMax}:</div>
                                                            <div className={`${Styles.totalRowPredictions}`}>
                                                                {signalClosePredictionContentHandler(total?.prediction)}
                                                            </div>
                                                        </div>
                                                        <div className={`${Styles.mainSignalWrap}`}>
                                                            <div className={`${Styles.mainSignalItem}`}>
                                                                <div className={`${Styles.mainSignalItemName}`}>RSI:</div>
                                                                <div className={`${Styles.mainSignalItemPrediction}`}>{walletIndicators ? signalOpenPredictionContentHandler(String(walletIndicators?.relative_strength_index?.signal)) : <div className={`${Styles.ct}`}>{t('nope')}</div>}</div>
                                                            </div>
                                                            <div className={`${Styles.mainSignalItem}`}>
                                                                <div className={`${Styles.mainSignalItemName}`}>MACD:</div>
                                                                <div className={`${Styles.mainSignalItemPrediction}`}>{walletIndicators ? signalOpenPredictionContentHandler(String(walletIndicators?.MACD?.signal)) : <div className={`${Styles.ct}`}>{t('nope')}</div>}</div>
                                                            </div>
                                                            <div className={`${Styles.mainSignalItem}`}>
                                                                <div className={`${Styles.mainSignalItemName}`}>SK:</div>
                                                                <div className={`${Styles.mainSignalItemPrediction}`}>{walletIndicators ? signalOpenPredictionContentHandler(String(walletIndicators?.stochastic_k?.signal)) : <div className={`${Styles.ct}`}>{t('nope')}</div>}</div>
                                                            </div>
                                                            <div className={`${Styles.mainSignalItem}`}>
                                                                <div className={`${Styles.mainSignalItemName}`}>IBL:</div>
                                                                <div className={`${Styles.mainSignalItemPrediction}`}>{walletIndicators ? signalOpenPredictionContentHandler(String(walletIndicators?.ichimoku_base_line?.signal)) : <div className={`${Styles.ct}`}>{t('nope')}</div>}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={`${Styles.predictionBox}`}>
                                            <div className={`${Styles.predictionRow}`}>
                                                <div className={`${Styles.predictionTitle}`}>{t('Наш прогноз')}:</div>
                                                <div className={`${Styles.predictionValue}`}>{walletIndicators ? signalOpenPredictionContentHandler(String(walletIndicators?.overall_signal)) : <div className={`${Styles.ct}`}>Loading</div>}</div>
                                            </div>
                                            <div className={`${Styles.predictionText}`}>
                                                {t('Торговля на финансовых рынках несет в себе свои риски, и важно осознавать возможные трудности. Предоставляемые нами сигналы предназначены исключительно в информационных целях, и их использование не гарантирует прибыль. Условия на рынке могут быстро меняться, и прошлые результаты не обязательно предсказывают будущие.')}
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                            <div className={`${Styles.loadingWrap}`}>Loading...</div>
                        }
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default StandardWalletCryptoModal;
