import React from "react";
import {useSelector} from "react-redux";
import Styles from './styles.module.scss';
import Moment from "react-moment";
import DOMPurify from "dompurify";
import {linkTranslate} from "../../helpers/linkTranslite";
import {useNavigate} from "react-router-dom";
import newsReadIcon from "../../theme/assets/svg/omt_news_read_icon.svg";
import {useTranslation} from "react-i18next";




const MainNews = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const news = useSelector(store => store.main.latestNews);

    const postLinkHandler = (title, id) => {
        const correctUrl = linkTranslate(title, id);
        navigate(`/news/${correctUrl}`);
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title2}`} onClick={()=>{navigate(`/news/page/1`)}}>{t('Новости & События')}</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.newsBox}`}>
                        {news?.length > 0 ?
                            news.map((item, index)=>{
                                if (index < 3){
                                    return (
                                        <div className={`${Styles.newsBoxItem}`} key={'mainNews' + index} onClick={()=>{postLinkHandler(item.title, item.id)}}>
                                            <div className={`${Styles.newsBoxItemImgWrap}`}>
                                                <img src={item.picture} alt={item.title}/>
                                            </div>
                                            <div className={`${Styles.dateWrap}`}>
                                                <div className={`${Styles.date}`}><Moment format="DD/MM/YYYY">{item.date}</Moment></div>
                                                <div className={`${Styles.read}`}>
                                                    <div className={`${Styles.readImgWrap}`}>
                                                        <img src={newsReadIcon} alt={item.title}/>
                                                    </div>
                                                    {item.read_time ? item.read_time : '2'} minutes
                                                </div>
                                            </div>
                                            <div className={`${Styles.title2} ${Styles.titleWSF}`}>{item.title}</div>
                                            <div
                                                className={`${Styles.newsBoxItemDescription}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(item.content),
                                                }}
                                            />
                                            <div className={`${Styles.newsBoxItemCategory}`}>{item.category?.name}</div>
                                        </div>
                                    )
                                } else return null
                            }) : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainNews;