import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import HoneechizeNavigation from "../../components/honeechizeNavigation";
import HoneechizeMain from "../../components/honeechizeMain";
import {getHoneechizeMainData} from "../../store/cabinet/action";
import HoneechizeMetricsEarningsGraph from "../../components/honeechizeMetricsEarningsGraph";
import HoneechizeMetricsProfitGraph from "../../components/honeechizeMetricsProfitGraph";



const HoneechizeMetricsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);


    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getHoneechizeMainData());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Metrics'}/>
            <div className={`${Styles.contentWrap}`}>
                <HoneechizeNavigation/>
                <HoneechizeMain/>
                <HoneechizeMetricsEarningsGraph/>
                <HoneechizeMetricsProfitGraph/>
            </div>
        </section>
    );
};

export default HoneechizeMetricsPage;