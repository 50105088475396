import axios from '../../helpers/interceptors';

import {
    TEST,
    SET_MOBILE_NAV_STATUS,
    GET_BOT_MAIN_DATA_STARTED,
    GET_BOT_MAIN_DATA_SUCCESS,
    GET_BOT_MAIN_DATA_FAILURE,
    SEND_SUPPORT_FORM_STARTED,
    SEND_SUPPORT_FORM_SUCCESS,
    SEND_SUPPORT_FORM_FAILURE,
    GET_BOT_SUBSCRIBE_INFO_STARTED,
    GET_BOT_SUBSCRIBE_INFO_SUCCESS,
    GET_BOT_SUBSCRIBE_INFO_FAILURE,
    SET_BOT_SUBSCRIBE_MODAL_STATUS,
    SET_BONUSES_MODAL_STATUS,
    GET_REF_MAIN_DATA_STARTED,
    GET_REF_MAIN_DATA_SUCCESS,
    GET_REF_MAIN_DATA_FAILURE,
    GET_REF_INFO_STARTED,
    GET_REF_INFO_SUCCESS,
    GET_REF_INFO_FAILURE,
    GET_REF_LOGS_STARTED,
    GET_REF_LOGS_SUCCESS,
    GET_REF_LOGS_FAILURE,
    SET_REF_LOGS_CORRECT_DATA,
    SET_REF_LOGS_PAGE_DATA,
    SET_AMBASSADORS_MODAL_STATUS,
    GET_AMBASSADOR_MAIN_DATA_STARTED,
    GET_AMBASSADOR_MAIN_DATA_SUCCESS,
    GET_AMBASSADOR_MAIN_DATA_FAILURE,
    GET_AMBASSADOR_STATUSES_STARTED,
    GET_AMBASSADOR_STATUSES_SUCCESS,
    GET_AMBASSADOR_STATUSES_FAILURE,
    GET_AMBASSADOR_GRAPH_STARTED,
    GET_AMBASSADOR_GRAPH_SUCCESS,
    GET_AMBASSADOR_GRAPH_FAILURE,
    GET_AMBASSADOR_GRAPH_CORRECT_DATA,
    GET_AMBASSADOR_LINKS_STARTED,
    GET_AMBASSADOR_LINKS_SUCCESS,
    GET_AMBASSADOR_LINKS_FAILURE,
    SEND_AMBASSADOR_LINK_STARTED,
    SEND_AMBASSADOR_LINK_SUCCESS,
    SEND_AMBASSADOR_LINK_FAILURE,
    GET_AMBASSADOR_TURNOVER_STARTED,
    GET_AMBASSADOR_TURNOVER_SUCCESS,
    GET_AMBASSADOR_TURNOVER_FAILURE,
    AMBASSADOR_LINK_ERRORS,
    AMBASSADOR_LINK_ERRORS_CLEAR,
    AMBASSADOR_CORRECT_LINKS,
    SET_AMBASSADORS_MODAL_SALARY_STATUS,
    GET_AMBASSADOR_SALARY_DATA_STARTED,
    GET_AMBASSADOR_SALARY_DATA_SUCCESS,
    GET_AMBASSADOR_SALARY_DATA_FAILURE,
    SET_INFLUENCER_MODAL_STATUS,
    CHANGE_PASSWORD_STARTED,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_HONEECHIZE_MAIN_DATA_STARTED,
    GET_HONEECHIZE_MAIN_DATA_SUCCESS,
    GET_HONEECHIZE_MAIN_DATA_FAILURE,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE,
    SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS,
    SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE,
    GET_HONEECHIZE_BOT_EARNINGS_STARTED,
    GET_HONEECHIZE_BOT_EARNINGS_SUCCESS,
    GET_HONEECHIZE_BOT_EARNINGS_FAILURE,
    GET_HONEECHIZE_BOT_SETTINGS_STARTED,
    GET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    GET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    SET_HONEECHIZE_BOT_SETTINGS_STARTED,
    SET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    SET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE,
    SET_HONEECHIZE_ALERT_MODAL_STATUS,
    SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA,
    SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA,
    GET_CUSTOM_SETTINGS_STARTED,
    GET_CUSTOM_SETTINGS_SUCCESS,
    GET_CUSTOM_SETTINGS_FAILURE,
    GET_INDEX_MAIN_DATA_STARTED,
    GET_INDEX_MAIN_DATA_SUCCESS,
    GET_INDEX_MAIN_DATA_FAILURE,
    INDEX_SWAP_STARTED,
    INDEX_SWAP_SUCCESS,
    INDEX_SWAP_FAILURE,
    GET_INDEX_SWAP_HISTORY_STARTED,
    GET_INDEX_SWAP_HISTORY_SUCCESS,
    GET_INDEX_SWAP_HISTORY_FAILURE,
    GET_INDEX_SWAP_PREDICTION_STARTED,
    GET_INDEX_SWAP_PREDICTION_SUCCESS,
    GET_INDEX_SWAP_PREDICTION_FAILURE,
    CHANGE_BOT_RISK_STARTED,
    CHANGE_BOT_RISK_SUCCESS,
    CHANGE_BOT_RISK_FAILURE,
    SET_BOT_PROMO_MODAL_STATUS,
    BOT_INVEST_STARTED,
    BOT_INVEST_SUCCESS,
    BOT_INVEST_FAILURE,
    OPEN_BOT_STARTED,
    OPEN_BOT_SUCCESS,
    OPEN_BOT_FAILURE,
    CONTROL_BOT_STARTED,
    CONTROL_BOT_SUCCESS,
    CONTROL_BOT_FAILURE,
    SET_BOT_REINVEST_STARTED,
    SET_BOT_REINVEST_SUCCESS,
    SET_BOT_REINVEST_FAILURE,
    GET_BOT_HISTORY_STARTED,
    GET_BOT_HISTORY_SUCCESS,
    GET_BOT_HISTORY_FAILURE,
    SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS,
    GET_CRYPTO_NEWS_STARTED,
    GET_CRYPTO_NEWS_SUCCESS,
    GET_CRYPTO_NEWS_FAILURE,
    GET_INDICATORS_STARTED,
    GET_INDICATORS_SUCCESS,
    GET_INDICATORS_FAILURE,
    GET_AVAILABLE_CURRENCIES_STARTED,
    GET_AVAILABLE_CURRENCIES_SUCCESS,
    GET_AVAILABLE_CURRENCIES_FAILURE,
    SET_CREATE_WALLET_CURRENCIES,
    SET_WALLET_CREATE_FORM_STATUS,
    CREATE_WALLET_STARTED,
    CREATE_WALLET_SUCCESS,
    CREATE_WALLET_FAILURE,
    GET_WALLET_ASSETS_SUCCESS,
    GET_WALLET_ASSETS_STARTED,
    GET_WALLET_ASSETS_FAILURE,
    GET_WALLET_ASSETS_WITH_BALANCE,
    GET_REFILLABLE_WALLETS,
    SET_CURRENT_WALLET,
    CREATE_CURRENT_WALLET_STARTED,
    CREATE_CURRENT_WALLET_SUCCESS,
    CREATE_CURRENT_WALLET_FAILURE,
    SET_WALLET_TAKE_FORM_STATUS,
    SET_WALLET_SEND_FORM_STATUS,
    GET_TRANSFER_COMMISSION_STARTED,
    GET_TRANSFER_COMMISSION_SUCCESS,
    GET_TRANSFER_COMMISSION_FAILURE,
    SEND_TRANSACTIONS_STARTED,
    SEND_TRANSACTIONS_SUCCESS,
    SEND_TRANSACTIONS_FAILURE,
    GET_WALLET_GRAPH_HISTORY_STARTED,
    GET_WALLET_GRAPH_HISTORY_SUCCESS,
    GET_WALLET_GRAPH_HISTORY_FAILURE,
    GET_OMT_PERIODS_STARTED,
    GET_OMT_PERIODS_SUCCESS,
    GET_OMT_PERIODS_FAILURE,
    GET_AVAILABLE_WALLETS_STARTED,
    GET_AVAILABLE_WALLETS_SUCCESS,
    GET_AVAILABLE_WALLETS_FAILURE,
    GET_CURRENT_WALLET_STARTED,
    GET_CURRENT_WALLET_SUCCESS,
    GET_CURRENT_WALLET_FAILURE,
    GET_CURRENT_WALLET_TRANSACTIONS_STARTED,
    GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS,
    GET_CURRENT_WALLET_TRANSACTIONS_FAILURE,
    SET_STAKING_FORM_STATUS,
    BALANCE_TRANSACTIONS_STARTED,
    BALANCE_TRANSACTIONS_SUCCESS,
    BALANCE_TRANSACTIONS_FAILURE,
    SET_STAKING_BALANCE_STATUS,
    GET_ONE_CURRENT_WALLET_STARTED,
    GET_ONE_CURRENT_WALLET_SUCCESS,
    GET_ONE_CURRENT_WALLET_FAILURE,
    OPEN_STAKING_STARTED,
    OPEN_STAKING_SUCCESS,
    OPEN_STAKING_FAILURE,
    GET_AVAILABLE_STAKING_STARTED,
    GET_AVAILABLE_STAKING_SUCCESS,
    GET_AVAILABLE_STAKING_FAILURE,
    SET_STAKING_FORM_OMT_STATUS,
    GET_STAKING_COMMISSION_STARTED,
    GET_STAKING_COMMISSION_SUCCESS,
    GET_STAKING_COMMISSION_FAILURE,
    SET_STAKING_WITHDRAW_FORM_STATUS,
    GET_STAKING_AWARD_STARTED,
    GET_STAKING_AWARD_SUCCESS,
    GET_STAKING_AWARD_FAILURE,
    WITHDRAW_OMT_STAKING_STARTED,
    WITHDRAW_OMT_STAKING_SUCCESS,
    WITHDRAW_OMT_STAKING_FAILURE,
    WITHDRAW_STAKING_STARTED,
    WITHDRAW_STAKING_SUCCESS,
    WITHDRAW_STAKING_FAILURE,
    EXCHANGE_STARTED,
    EXCHANGE_SUCCESS,
    EXCHANGE_FAILURE,
    GET_EXCHANGE_HISTORY_STARTED,
    GET_EXCHANGE_HISTORY_SUCCESS,
    GET_EXCHANGE_HISTORY_FAILURE,
    GET_EXCHANGE_DATA_STARTED,
    GET_EXCHANGE_DATA_SUCCESS,
    GET_EXCHANGE_DATA_FAILURE,
    GET_WALLET_TRANSACTIONS_STARTED,
    GET_WALLET_TRANSACTIONS_SUCCESS,
    GET_WALLET_TRANSACTIONS_FAILURE,
    SET_BOT_MODAL_STATUS,
    SET_CUSTOM_SETTINGS_STARTED,
    SET_CUSTOM_SETTINGS_SUCCESS,
    SET_CUSTOM_SETTINGS_FAILURE,
    BUY_SUBSCRIBE_STARTED,
    BUY_SUBSCRIBE_SUCCESS,
    BUY_SUBSCRIBE_FAILURE,
    GET_BOT_PROFIT_STARTED,
    GET_BOT_PROFIT_SUCCESS,
    GET_BOT_PROFIT_FAILURE,
    SET_BOT_PRO_PLUS_MODAL_STATUS,
    CLEAR_CABINET_STATE,
    GET_SUBSCRIBE_COMMISSION_STARTED,
    GET_SUBSCRIBE_COMMISSION_SUCCESS,
    GET_SUBSCRIBE_COMMISSION_FAILURE,
    SET_GREETING_STARTED,
    SET_GREETING_SUCCESS,
    SET_GREETING_FAILURE
} from "./types";

import {api} from "../../api";
// import {setAuthorizationStatus} from "../main/action";
import {toast} from "react-toastify";
const host = api.host;

const headWT = ()=>{
    const token = localStorage.getItem('honee2-token');
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
}

export const clearCabinetState = () => ({
    type: CLEAR_CABINET_STATE
});

export const test = data => ({
    type: TEST,
    payload: data
});

export const setMobileNavStatus = status => ({
    type: SET_MOBILE_NAV_STATUS,
    payload: status
});

export const setBotSubscribeModalStatus = status => ({
    type: SET_BOT_SUBSCRIBE_MODAL_STATUS,
    payload: status
});

export const setBotProPlusModalStatus = status => ({
    type: SET_BOT_PRO_PLUS_MODAL_STATUS,
    payload: status
});

export const setBonusesModalStatus = status => ({
    type: SET_BONUSES_MODAL_STATUS,
    payload: status
});

export const setInfluencerModalStatus = status => ({
    type: SET_INFLUENCER_MODAL_STATUS,
    payload: status
});

export const setBotPromoModalStatus = status => ({
    type: SET_BOT_PROMO_MODAL_STATUS,
    payload: status
});

export const setAmbassadorsModalStatus = status => ({
    type: SET_AMBASSADORS_MODAL_STATUS,
    payload: status
});

export const setAmbassadorsModalSalaryStatus = status => ({
    type: SET_AMBASSADORS_MODAL_SALARY_STATUS,
    payload: status
});

export const setStakingFormStatus = status => ({
    type: SET_STAKING_FORM_STATUS,
    payload: status
});

export const setHoneechizeAffiliateSettingsModalStatus = status => ({
    type: SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS,
    payload: status
});

export const setHoneechizeAffiliateTableModalStatus = status => ({
    type: SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS,
    payload: status
});

export const setHoneechizeAlertModalStatus = status => ({
    type: SET_HONEECHIZE_ALERT_MODAL_STATUS,
    payload: status
});

export const getBotMainData = () => {
    let url = host + api.bot.main;

    return dispatch => {
        dispatch(getBotMainDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getBotMainDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getBotMainDataFailure(err));
            });
    };
};

const getBotMainDataStarted = () => ({
    type: GET_BOT_MAIN_DATA_STARTED
});

export const getBotMainDataSuccess = serverReport => ({
    type: GET_BOT_MAIN_DATA_SUCCESS,
    payload: serverReport
});

export const getBotMainDataFailure = error => ({
    type: GET_BOT_MAIN_DATA_FAILURE,
    payload: error
});


export const sendSupportForm = (data) => {
    let url = host + api.support.main;

    return dispatch => {
        dispatch(sendSupportFormStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(sendSupportFormSuccess(res.data));
            })
            .catch(err => {
                dispatch(sendSupportFormFailure(err.response.data.errors));
            });
    };
};

const sendSupportFormStarted = () => ({
    type: SEND_SUPPORT_FORM_STARTED
});

export const sendSupportFormSuccess = serverReport => ({
    type: SEND_SUPPORT_FORM_SUCCESS,
    payload: serverReport
});

export const sendSupportFormFailure = error => ({
    type: SEND_SUPPORT_FORM_FAILURE,
    payload: error
});

export const getBotSubscribeInfo = () => {
    let url = host + api.bot.subscribe;


    return dispatch => {
        dispatch(getBotSubscribeInfoStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getBotSubscribeInfoSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getBotSubscribeInfoFailure(err));
            });
    };
};

const getBotSubscribeInfoStarted = () => ({
    type: GET_BOT_SUBSCRIBE_INFO_STARTED
});

export const getBotSubscribeInfoSuccess = serverReport => ({
    type: GET_BOT_SUBSCRIBE_INFO_SUCCESS,
    payload: serverReport
});

export const getBotSubscribeInfoFailure = error => ({
    type: GET_BOT_SUBSCRIBE_INFO_FAILURE,
    payload: error
});


export const getRefMainData = () => {
    let url = host + api.bonuses.main;

    return dispatch => {
        dispatch(getRefMainDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getRefMainDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getRefMainDataFailure(err));
            });
    };
};

const getRefMainDataStarted = () => ({
    type: GET_REF_MAIN_DATA_STARTED
});

export const getRefMainDataSuccess = serverReport => ({
    type: GET_REF_MAIN_DATA_SUCCESS,
    payload: serverReport
});

export const getRefMainDataFailure = error => ({
    type: GET_REF_MAIN_DATA_FAILURE,
    payload: error
});

export const getRefInfo = () => {
    let url = host + api.bonuses.info;

    return dispatch => {
        dispatch(getRefInfoStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getRefInfoSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getRefInfoFailure(err));
            });
    };
};

const getRefInfoStarted = () => ({
    type: GET_REF_INFO_STARTED
});

export const getRefInfoSuccess = serverReport => ({
    type: GET_REF_INFO_SUCCESS,
    payload: serverReport
});

export const getRefInfoFailure = error => ({
    type: GET_REF_INFO_FAILURE,
    payload: error
});


export const getRefLogs = (type, page) => {
    let url;
    if (page){
        url = host + api.bonuses.logs  + `?type=${type}&page=${page}`;
    } else {
        url = host + api.bonuses.logs  + `?type=${type}`;
    }


    return dispatch => {
        dispatch(getRefLogsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getRefLogsSuccess(res.data.response));
                dispatch(refLogsPageData({current_page: res.data.response.current_page, last_page: res.data.response.last_page}));
                let correctData = {
                    all: [],
                    staking: [],
                    bot: [],
                    pageStatus: page
                }
                res.data.response.data.forEach((item)=>{
                    correctData[type].push(item)
                })
                dispatch(refLogsCorrectData(correctData));
            })
            .catch(err => {
                dispatch(getRefLogsFailure(err));
            });
    };
};

const getRefLogsStarted = () => ({
    type: GET_REF_LOGS_STARTED
});

export const getRefLogsSuccess = serverReport => ({
    type: GET_REF_LOGS_SUCCESS,
    payload: serverReport
});

export const refLogsCorrectData = data => ({
    type: SET_REF_LOGS_CORRECT_DATA,
    payload: data
});

export const refLogsPageData = data => ({
    type: SET_REF_LOGS_PAGE_DATA,
    payload: data
});

export const getRefLogsFailure = error => ({
    type: GET_REF_LOGS_FAILURE,
    payload: error
});


export const getAmbassadorMainData = () => {
    let url = host + api.ambassador.main;

    return dispatch => {
        dispatch(getAmbassadorMainDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAmbassadorMainDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAmbassadorMainDataFailure(err));
            });
    };
};

const getAmbassadorMainDataStarted = () => ({
    type: GET_AMBASSADOR_MAIN_DATA_STARTED
});

export const getAmbassadorMainDataSuccess = serverReport => ({
    type: GET_AMBASSADOR_MAIN_DATA_SUCCESS,
    payload: serverReport
});

export const getAmbassadorMainDataFailure = error => ({
    type: GET_AMBASSADOR_MAIN_DATA_FAILURE,
    payload: error
});

export const getAmbassadorsSalaryData = () => {
    let url = host + api.ambassador.salary;

    return dispatch => {
        dispatch(getAmbassadorsSalaryDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAmbassadorsSalaryDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAmbassadorsSalaryDataFailure(err));
            });
    };
};

const getAmbassadorsSalaryDataStarted = () => ({
    type: GET_AMBASSADOR_SALARY_DATA_STARTED
});

export const getAmbassadorsSalaryDataSuccess = serverReport => ({
    type: GET_AMBASSADOR_SALARY_DATA_SUCCESS,
    payload: serverReport
});

export const getAmbassadorsSalaryDataFailure = error => ({
    type: GET_AMBASSADOR_SALARY_DATA_FAILURE,
    payload: error
});


export const getAmbassadorStatuses = () => {
    let url = host + api.ambassador.statuses;

    return dispatch => {
        dispatch(getAmbassadorStatusesStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAmbassadorStatusesSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAmbassadorStatusesFailure(err));
            });
    };
};

const getAmbassadorStatusesStarted = () => ({
    type: GET_AMBASSADOR_STATUSES_STARTED
});

export const getAmbassadorStatusesSuccess = serverReport => ({
    type: GET_AMBASSADOR_STATUSES_SUCCESS,
    payload: serverReport
});

export const getAmbassadorStatusesFailure = error => ({
    type: GET_AMBASSADOR_STATUSES_FAILURE,
    payload: error
});


export const getAmbassadorGraphData = () => {
    let url = host + api.ambassador.graph;

    return dispatch => {
        dispatch(getAmbassadorGraphDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAmbassadorGraphDataSuccess(res.data.response));
                let correctData = {
                    min: 100000,
                    max: -100000,
                    data: res.data.response
                }
                res.data.response.forEach((item)=>{
                    if (correctData.min > item?.value){
                        correctData.min = item.value;
                    }
                    if (correctData.max < item?.value){
                        correctData.max = item.value;
                    }
                })
                dispatch(getAmbassadorGraphCorrectData(correctData));
            })
            .catch(err => {
                dispatch(getAmbassadorGraphDataFailure(err));
            });
    };
};

const getAmbassadorGraphDataStarted = () => ({
    type: GET_AMBASSADOR_GRAPH_STARTED
});

export const getAmbassadorGraphDataSuccess = serverReport => ({
    type: GET_AMBASSADOR_GRAPH_SUCCESS,
    payload: serverReport
});

export const getAmbassadorGraphDataFailure = error => ({
    type: GET_AMBASSADOR_GRAPH_FAILURE,
    payload: error
});

export const getAmbassadorGraphCorrectData = data => ({
    type: GET_AMBASSADOR_GRAPH_CORRECT_DATA,
    payload: data
});

export const getAmbassadorLinks = () => {
    let url = host + api.ambassador.links;

    let correctLinks = {
        ama: {status: 'rejected', link: false},
        social_post_ambassador: {status: 'rejected', link: false},
        video: {status: 'rejected', link: false},
        social_post: []
    }

    return dispatch => {
        dispatch(getAmbassadorLinksStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAmbassadorLinksSuccess(res.data.response));
                res.data.response.forEach((item)=>{
                    if (item.type === 'ama'){
                        correctLinks.ama = item
                    }
                    if (item.type === 'social_post_ambassador'){
                        correctLinks.social_post_ambassador = item
                    }
                    if (item.type === 'video'){
                        correctLinks.video = item
                    }
                    if (item.type === 'social_post'){
                        correctLinks.social_post.push(item);
                    }
                })
                if (!correctLinks.social_post[0]){
                    correctLinks.social_post[0] = {status: 'rejected', url: false}
                }
                if (!correctLinks.social_post[1]){
                    correctLinks.social_post[1] = {status: 'rejected', url: false}
                }
                if (!correctLinks.social_post[2]){
                    correctLinks.social_post[2] = {status: 'rejected', url: false}
                }
                dispatch(ambassadorCorrectLinks(correctLinks));
            })
            .catch(err => {
                dispatch(getAmbassadorLinksFailure(err));
            });
    };
};

const getAmbassadorLinksStarted = () => ({
    type: GET_AMBASSADOR_LINKS_STARTED
});

export const getAmbassadorLinksSuccess = serverReport => ({
    type: GET_AMBASSADOR_LINKS_SUCCESS,
    payload: serverReport
});

export const getAmbassadorLinksFailure = error => ({
    type: GET_AMBASSADOR_LINKS_FAILURE,
    payload: error
});

export const ambassadorCorrectLinks = links => ({
    type: AMBASSADOR_CORRECT_LINKS,
    payload: links
});

export const sendAmbassadorLink = (type, link, socIndex) => {
    let url = host + api.ambassador.links + `/${type}`;

    return dispatch => {
        dispatch(sendAmbassadorLinkStarted());
        axios.post(url, {url: link}, headWT())
            .then(res => {
                dispatch(sendAmbassadorLinkSuccess(res.data.response));
                dispatch(getAmbassadorLinks());
            })
            .catch(err => {
                dispatch(sendAmbassadorLinkFailure(err));
                dispatch(ambassadorLinkErrors({type: type, err: err.response.data.errors, index: socIndex ? socIndex : false}))
            });
    };
};

const sendAmbassadorLinkStarted = () => ({
    type: SEND_AMBASSADOR_LINK_STARTED
});

export const sendAmbassadorLinkSuccess = serverReport => ({
    type: SEND_AMBASSADOR_LINK_SUCCESS,
    payload: serverReport
});

export const sendAmbassadorLinkFailure = error => ({
    type: SEND_AMBASSADOR_LINK_FAILURE,
    payload: error
});

export const ambassadorLinkErrors = error => ({
    type: AMBASSADOR_LINK_ERRORS,
    payload: error
});

export const ambassadorLinkErrorsClear = type => ({
    type: AMBASSADOR_LINK_ERRORS_CLEAR,
    payload: type
});

export const getAmbassadorTurnover = () => {
    let url = host + api.ambassador.turnover;

    return dispatch => {
        dispatch(getAmbassadorTurnoverStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAmbassadorTurnoverSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAmbassadorTurnoverFailure(err));
            });
    };
};

const getAmbassadorTurnoverStarted = () => ({
    type: GET_AMBASSADOR_TURNOVER_STARTED
});

export const getAmbassadorTurnoverSuccess = serverReport => ({
    type: GET_AMBASSADOR_TURNOVER_SUCCESS,
    payload: serverReport
});

export const getAmbassadorTurnoverFailure = error => ({
    type: GET_AMBASSADOR_TURNOVER_FAILURE,
    payload: error
});


export const changePassword = (data) => {
    let url = host + api.settings.password;

    return dispatch => {
        dispatch(changePasswordStarted());
        axios.put(url, data, headWT())
            .then(res => {
                dispatch(changePasswordSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(changePasswordFailure(err.response.data.errors));
            });
    };
};

const changePasswordStarted = () => ({
    type: CHANGE_PASSWORD_STARTED
});

export const changePasswordSuccess = serverReport => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: serverReport
});

export const changePasswordFailure = error => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: error
});

export const getHoneechizeMainData = () => {
    let url = host + api.honeechize.honeechizeMain;


    return dispatch => {
        dispatch(getHoneechizeMainDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeMainDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getHoneechizeMainDataFailure(err));
            });
    };
};

const getHoneechizeMainDataStarted = () => ({
    type: GET_HONEECHIZE_MAIN_DATA_STARTED
});

export const getHoneechizeMainDataSuccess = serverReport => ({
    type: GET_HONEECHIZE_MAIN_DATA_SUCCESS,
    payload: serverReport
});

export const getHoneechizeMainDataFailure = error => ({
    type: GET_HONEECHIZE_MAIN_DATA_FAILURE,
    payload: error
});


export const getHoneechizeEarningsGraphData = (period) => {
    let url = host + api.honeechize.honeechizeEarningsGraph  + `?period=${period}`;


    return dispatch => {
        dispatch(getHoneechizeEarningsGraphDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeEarningsGraphDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getHoneechizeEarningsGraphDataFailure(err));
            });
    };
};

const getHoneechizeEarningsGraphDataStarted = () => ({
    type: GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED
});

export const getHoneechizeEarningsGraphDataSuccess = serverReport => ({
    type: GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS,
    payload: serverReport
});

export const getHoneechizeEarningsGraphDataFailure = error => ({
    type: GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE,
    payload: error
});


export const getHoneechizeProfitGraphData = (period) => {
    let url = host + api.honeechize.honeechizeProfitGraph + `?period=${period}`;


    return dispatch => {
        dispatch(getHoneechizeProfitGraphDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeProfitGraphDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getHoneechizeProfitGraphDataFailure(err));
            });
    };
};

const getHoneechizeProfitGraphDataStarted = () => ({
    type: GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED
});

export const getHoneechizeProfitGraphDataSuccess = serverReport => ({
    type: GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS,
    payload: serverReport
});

export const getHoneechizeProfitGraphDataFailure = error => ({
    type: GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE,
    payload: error
});


export const getHoneechizeAffiliateSettings = () => {
    let url = host + api.honeechize.honeechizeAffiliateSettings;


    return dispatch => {
        dispatch(getHoneechizeAffiliateSettingsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeAffiliateSettingsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getHoneechizeAffiliateSettingsFailure(err));
            });
    };
};

const getHoneechizeAffiliateSettingsStarted = () => ({
    type: GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED
});

export const getHoneechizeAffiliateSettingsSuccess = serverReport => ({
    type: GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS,
    payload: serverReport
});

export const getHoneechizeAffiliateSettingsFailure = error => ({
    type: GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE,
    payload: error
});


export const changeHoneechizeAffiliatePercent = (type, value) => {
    let url = host + api.honeechize.honeechizeAffiliateSettings + `/${type}`;


    return dispatch => {
        dispatch(changeHoneechizeAffiliatePercentStarted());
        axios.put(url, {value: Number(value)}, headWT())
            .then(res => {
                dispatch(getHoneechizeAffiliateSettings());
                dispatch(changeHoneechizeAffiliatePercentSuccess(true));
            })
            .catch(err => {
                dispatch(changeHoneechizeAffiliatePercentFailure(err));
            });
    };
};

const changeHoneechizeAffiliatePercentStarted = () => ({
    type: CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED
});

export const changeHoneechizeAffiliatePercentSuccess = serverReport => ({
    type: CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS,
    payload: serverReport
});

export const changeHoneechizeAffiliatePercentFailure = error => ({
    type: CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE,
    payload: error
});


export const getHoneechizeBotEarnings = () => {
    let url = host + api.honeechize.honeechizeBotEarnings;


    return dispatch => {
        dispatch(getHoneechizeBotEarningsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeBotEarningsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getHoneechizeBotEarningsFailure(err));
            });
    };
};

const getHoneechizeBotEarningsStarted = () => ({
    type: GET_HONEECHIZE_BOT_EARNINGS_STARTED
});

export const getHoneechizeBotEarningsSuccess = serverReport => ({
    type: GET_HONEECHIZE_BOT_EARNINGS_SUCCESS,
    payload: serverReport
});

export const getHoneechizeBotEarningsFailure = error => ({
    type: GET_HONEECHIZE_BOT_EARNINGS_FAILURE,
    payload: error
});

export const getHoneechizeBotSettings = () => {
    let url = host + api.honeechize.honeechizeBotSettings;


    return dispatch => {
        dispatch(getHoneechizeBotSettingsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeBotSettingsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getHoneechizeBotSettingsFailure(err));
            });
    };
};

const getHoneechizeBotSettingsStarted = () => ({
    type: GET_HONEECHIZE_BOT_SETTINGS_STARTED
});

export const getHoneechizeBotSettingsSuccess = serverReport => ({
    type: GET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    payload: serverReport
});

export const getHoneechizeBotSettingsFailure = error => ({
    type: GET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    payload: error
});

export const setHoneechizeBotSettings = (data) => {
    let url = host + api.honeechize.honeechizeBotSettings;


    return dispatch => {
        dispatch(setHoneechizeBotSettingsStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(setHoneechizeBotSettingsSuccess(res.data.response));
                dispatch(getHoneechizeBotSettings())
            })
            .catch(err => {
                dispatch(setHoneechizeBotSettingsFailure(err));
            });
    };
};

const setHoneechizeBotSettingsStarted = () => ({
    type: SET_HONEECHIZE_BOT_SETTINGS_STARTED
});

export const setHoneechizeBotSettingsSuccess = serverReport => ({
    type: SET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    payload: serverReport
});

export const setHoneechizeBotSettingsFailure = error => ({
    type: SET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    payload: error
});


export const getHoneechizeAffiliateStatistic = (period, page) => {
    let url;

    if (page){
        url = host + api.honeechize.honeechizeAffiliateStatistic  + `?period=${period}&page=${page}`;
    } else {
        url = host + api.honeechize.honeechizeAffiliateStatistic + `?period=${period}`;
    }


    return dispatch => {
        dispatch(getHoneechizeAffiliateStatisticStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getHoneechizeAffiliateStatisticSuccess(res.data.response));
                dispatch(setHoneechizeAffiliateStatisticPageData({current_page: res.data.response.current_page, last_page: res.data.response.last_page}));
                let correctData = {
                    day: [],
                    week: [],
                    month: [],
                    all: [],
                    pageStatus: page
                }
                res.data.response.data.forEach((item)=>{
                    correctData[period].push(item)
                })
                dispatch(setHoneechizeAffiliateStatisticCorrectData(correctData));
            })
            .catch(err => {
                dispatch(getHoneechizeAffiliateStatisticFailure(err));
            });
    };
};

const getHoneechizeAffiliateStatisticStarted = () => ({
    type: GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED
});

export const getHoneechizeAffiliateStatisticSuccess = serverReport => ({
    type: GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS,
    payload: serverReport
});

export const getHoneechizeAffiliateStatisticFailure = error => ({
    type: GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE,
    payload: error
});

export const setHoneechizeAffiliateStatisticPageData = data => ({
    type: SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA,
    payload: data
});

export const setHoneechizeAffiliateStatisticCorrectData = data => ({
    type: SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA,
    payload: data
});

export const getCustomSettings = () => {
    let url = host + api.bot.custom;


    return dispatch => {
        dispatch(getCustomSettingsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getCustomSettingsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getCustomSettingsFailure(err));
            });
    };
};

const getCustomSettingsStarted = () => ({
    type: GET_CUSTOM_SETTINGS_STARTED
});

export const getCustomSettingsSuccess = serverReport => ({
    type: GET_CUSTOM_SETTINGS_SUCCESS,
    payload: serverReport
});

export const getCustomSettingsFailure = error => ({
    type: GET_CUSTOM_SETTINGS_FAILURE,
    payload: error
});

export const getIndexMainData = () => {
    let url = host + api.index.indexMain;


    return dispatch => {
        dispatch(getIndexMainDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getIndexMainDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getIndexMainDataFailure(err));
            });
    };
};

const getIndexMainDataStarted = () => ({
    type: GET_INDEX_MAIN_DATA_STARTED
});

export const getIndexMainDataSuccess = serverReport => ({
    type: GET_INDEX_MAIN_DATA_SUCCESS,
    payload: serverReport
});

export const getIndexMainDataFailure = error => ({
    type: GET_INDEX_MAIN_DATA_FAILURE,
    payload: error
});

export const indexSwap = (data) => {
    let url = host + api.index.indexSwap;


    return dispatch => {
        dispatch(indexSwapStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(indexSwapSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(indexSwapFailure(err));
            });
    };
};

const indexSwapStarted = () => ({
    type: INDEX_SWAP_STARTED
});

export const indexSwapSuccess = serverReport => ({
    type: INDEX_SWAP_SUCCESS,
    payload: serverReport
});

export const indexSwapFailure = error => ({
    type: INDEX_SWAP_FAILURE,
    payload: error
});


export const getIndexSwapHistory = (period) => {
    let url = host + api.index.indexHistory  + `?period=${period}`;


    return dispatch => {
        dispatch(getIndexSwapHistoryStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getIndexSwapHistorySuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getIndexSwapHistoryFailure(err));
            });
    };
};

const getIndexSwapHistoryStarted = () => ({
    type: GET_INDEX_SWAP_HISTORY_STARTED
});

export const getIndexSwapHistorySuccess = serverReport => ({
    type: GET_INDEX_SWAP_HISTORY_SUCCESS,
    payload: serverReport
});

export const getIndexSwapHistoryFailure = error => ({
    type: GET_INDEX_SWAP_HISTORY_FAILURE,
    payload: error
});


export const getIndexSwapPrediction = (days) => {
    let url = host + api.index.indexPrediction + `?d=${days}`;


    return dispatch => {
        dispatch(getIndexSwapPredictionStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getIndexSwapPredictionSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getIndexSwapPredictionFailure(err));
            });
    };
};

const getIndexSwapPredictionStarted = () => ({
    type: GET_INDEX_SWAP_PREDICTION_STARTED
});

export const getIndexSwapPredictionSuccess = serverReport => ({
    type: GET_INDEX_SWAP_PREDICTION_SUCCESS,
    payload: serverReport
});

export const getIndexSwapPredictionFailure = error => ({
    type: GET_INDEX_SWAP_PREDICTION_FAILURE,
    payload: error
});

export const changeBotRisk = (id, data) => {
    let url = host + api.bot.open + `/${id}/risk-type`;

    return dispatch => {
        dispatch(changeBotRiskStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(changeBotRiskSuccess(res.data.response));
                if (data.risk_type === 'custom'){
                    dispatch(getCustomSettings());
                }
            })
            .catch(err => {
                dispatch(changeBotRiskFailure(err));
            });
    };
};

const changeBotRiskStarted = () => ({
    type: CHANGE_BOT_RISK_STARTED
});

export const changeBotRiskSuccess = serverReport => ({
    type: CHANGE_BOT_RISK_SUCCESS,
    payload: serverReport
});

export const changeBotRiskFailure = error => ({
    type: CHANGE_BOT_RISK_FAILURE,
    payload: error
});

export const botInvest = (id, data) => {
    let url = host + api.bot.open + `/${id}/invest`;

    return dispatch => {
        dispatch(botInvestStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(botInvestSuccess(res.data.response));
                dispatch(getBotMainData());
            })
            .catch(err => {
                dispatch(botInvestFailure(err?.response?.data?.errors));
            });
    };
};

const botInvestStarted = () => ({
    type: BOT_INVEST_STARTED
});

export const botInvestSuccess = serverReport => ({
    type: BOT_INVEST_SUCCESS,
    payload: serverReport
});

export const botInvestFailure = error => ({
    type: BOT_INVEST_FAILURE,
    payload: error
});

export const openBot = (data) => {
    let url = host + api.bot.open;

    return dispatch => {
        dispatch(openBotStarted());
        axios.post(url, data,headWT())
            .then(res => {
                dispatch(openBotSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(openBotFailure(err?.response?.data?.errors));
            });
    };
};

const openBotStarted = () => ({
    type: OPEN_BOT_STARTED
});

export const openBotSuccess = serverReport => ({
    type: OPEN_BOT_SUCCESS,
    payload: serverReport
});

export const openBotFailure = error => ({
    type: OPEN_BOT_FAILURE,
    payload: error
});

export const controlBot = (id) => {
    let url = host + api.bot.open + `/${id}/start-stop`;

    return dispatch => {
        dispatch(controlBotStarted());
        axios.post(url, null, headWT())
            .then(res => {
                dispatch(controlBotSuccess(res.data.response));
                dispatch(getBotMainData());
            })
            .catch(err => {
                dispatch(controlBotFailure(err?.response));
            });
    };
};

const controlBotStarted = () => ({
    type: CONTROL_BOT_STARTED
});

export const controlBotSuccess = serverReport => ({
    type: CONTROL_BOT_SUCCESS,
    payload: serverReport
});

export const controlBotFailure = error => ({
    type: CONTROL_BOT_FAILURE,
    payload: error
});

export const setBotReinvest = (id) => {
    let url = host + api.bot.open + `/${id}/auto-reinvest`;

    return dispatch => {
        dispatch(setBotReinvestStarted());
        axios.post(url, null, headWT())
            .then(res => {
                dispatch(setBotReinvestSuccess(res.data.response));
                dispatch(getBotMainData());
            })
            .catch(err => {
                dispatch(setBotReinvestFailure(err?.response));
            });
    };
};

const setBotReinvestStarted = () => ({
    type: SET_BOT_REINVEST_STARTED
});

export const setBotReinvestSuccess = serverReport => ({
    type: SET_BOT_REINVEST_SUCCESS,
    payload: serverReport
});

export const setBotReinvestFailure = error => ({
    type: SET_BOT_REINVEST_FAILURE,
    payload: error
});

export const getBotHistory = (page) => {
    const url = `${host}${api.bot.history}?page=${page}`;

    return dispatch => {
        dispatch(getBotHistoryStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getBotHistorySuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getBotHistoryFailure(err?.response));
            });
    };
};

const getBotHistoryStarted = () => ({
    type: GET_BOT_HISTORY_STARTED
});

export const getBotHistorySuccess = serverReport => ({
    type: GET_BOT_HISTORY_SUCCESS,
    payload: serverReport
});

export const getBotHistoryFailure = error => ({
    type: GET_BOT_HISTORY_FAILURE,
    payload: error
});

export const setStandardWalletCryptoModalStatus = status => ({
    type: SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS,
    payload: status
});

export const getIndicators = (id, timeframe) => {
    let url = host + api.walletApi.indicators + `/${id}/indicators?timeframe=${timeframe}`;


    return dispatch => {
        dispatch(getIndicatorsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getIndicatorsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getIndicatorsFailure(err));
            });
    };
};

const getIndicatorsStarted = () => ({
    type: GET_INDICATORS_STARTED
});

export const getIndicatorsSuccess = serverReport => ({
    type: GET_INDICATORS_SUCCESS,
    payload: serverReport
});

export const getIndicatorsFailure = error => ({
    type: GET_INDICATORS_FAILURE,
    payload: error
});


export const getCryptoNews = (id) => {
    let url = host + api.walletApi.indicators + `/${id}/news`;


    return dispatch => {
        dispatch(getCryptoNewsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getCryptoNewsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getCryptoNewsFailure(err));
            });
    };
};

const getCryptoNewsStarted = () => ({
    type: GET_CRYPTO_NEWS_STARTED
});

export const getCryptoNewsSuccess = serverReport => ({
    type: GET_CRYPTO_NEWS_SUCCESS,
    payload: serverReport
});

export const getCryptoNewsFailure = error => ({
    type: GET_CRYPTO_NEWS_FAILURE,
    payload: error
});

export const getAvailableCurrencies = () => {
    let url = host + api.walletApi.availableCurrencies;

    let creatableData = [];

    return dispatch => {
        dispatch(getAvailableCurrenciesStarted());
        axios.get(url, headWT())
            .then(res => {
                res.data.response?.forEach((item)=>{
                    if (item.creatable){
                        creatableData.push(item)
                    }
                })
                dispatch(getAvailableCurrenciesSuccess(res.data.response));
                dispatch(setCreateWalletCurrencies(creatableData));
            })
            .catch(err => {
                dispatch(getAvailableCurrenciesFailure(err));
            });
    };
};

const getAvailableCurrenciesStarted = () => ({
    type: GET_AVAILABLE_CURRENCIES_STARTED
});

export const getAvailableCurrenciesSuccess = serverReport => ({
    type: GET_AVAILABLE_CURRENCIES_SUCCESS,
    payload: serverReport
});

export const getAvailableCurrenciesFailure = error => ({
    type: GET_AVAILABLE_CURRENCIES_FAILURE,
    payload: error
});

export const setCreateWalletCurrencies = cur => ({
    type: SET_CREATE_WALLET_CURRENCIES,
    payload: cur
});

export const setWalletCreateFormStatus = status => ({
    type: SET_WALLET_CREATE_FORM_STATUS,
    payload: status
});

export const createNewWallet = (data) => {
    const {net, wallet_picture, wallet_picture_type, name} = data;
    let url = host + api.walletApi.createWallet + `/${net.id}`;
    const correctData = {
        name: name,
        color: wallet_picture_type,
        background: wallet_picture
    }

    return dispatch => {
        dispatch(createWalletStarted());
        axios.post(url, correctData, headWT())
            .then(res => {
                dispatch(createWalletSuccess(res.data.response));
                dispatch(getWalletAssets());
                // dispatch(getAvailableCurrencies());
            })
            .catch(err => {
                dispatch(createWalletFailure(err?.response?.data.errors));
            });
    };
};

const createWalletStarted = () => ({
    type: CREATE_WALLET_STARTED
});

export const createWalletSuccess = serverReport => ({
    type: CREATE_WALLET_SUCCESS,
    payload: serverReport
});

export const createWalletFailure = error => ({
    type: CREATE_WALLET_FAILURE,
    payload: error
});

export const getWalletAssets = (blockFilterStatus) => {
    let url;

    if (blockFilterStatus){
        url = host + api.walletApi.createWallet + `?blockchain=1`
    } else {
        url = host + api.walletApi.createWallet
    }
    let walletWithBalance = [];
    let refillableWallets = [];

    return dispatch => {
        dispatch(getWalletAssetsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletAssetsSuccess(res.data.response));
                res.data.response.forEach((item)=>{
                    if (Number(item.amount) > 0 && item.currency.withdrawable){
                        walletWithBalance.push(item)
                    }
                    if (item.currency.refillable){
                        refillableWallets.push(item)
                    }
                })
                dispatch(getWalletAssetsWithBalance(walletWithBalance))
                dispatch(getRefillableWallets(refillableWallets))
            })
            .catch(err => {
                dispatch(getWalletAssetsFailure(err));
            });
    };
};

const getWalletAssetsStarted = () => ({
    type: GET_WALLET_ASSETS_STARTED
});

export const getWalletAssetsSuccess = serverReport => ({
    type: GET_WALLET_ASSETS_SUCCESS,
    payload: serverReport
});

export const getWalletAssetsFailure = error => ({
    type: GET_WALLET_ASSETS_FAILURE,
    payload: error
});

const getWalletAssetsWithBalance = data => ({
    type: GET_WALLET_ASSETS_WITH_BALANCE,
    payload: data
});

const getRefillableWallets = data => ({
    type: GET_REFILLABLE_WALLETS,
    payload: data
});

export const setCurrentWallet = wallet => ({
    type: SET_CURRENT_WALLET,
    payload: wallet
});

export const createCurrentWallet = (id) => {
    let url = host + api.walletApi.createWallet + `/${id}`;

    return dispatch => {
        dispatch(createCurrentWalletStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(createCurrentWalletSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(createCurrentWalletFailure(err));
            });
    };
};

const createCurrentWalletStarted = () => ({
    type: CREATE_CURRENT_WALLET_STARTED
});

export const createCurrentWalletSuccess = serverReport => ({
    type: CREATE_CURRENT_WALLET_SUCCESS,
    payload: serverReport
});

export const createCurrentWalletFailure = error => ({
    type: CREATE_CURRENT_WALLET_FAILURE,
    payload: error
});

export const setWalletTakeFormStatus = status => ({
    type: SET_WALLET_TAKE_FORM_STATUS,
    payload: status
});

export const setWalletSendFormStatus = status => ({
    type: SET_WALLET_SEND_FORM_STATUS,
    payload: status
});

export const getTransferCommission = (id, wallet, sum) => {
    let url = host + api.walletApi.transactionCommission + `/${id}/transfer/?wallet=${wallet}&amount=${sum}`;


    return dispatch => {
        dispatch(getTransferCommissionStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getTransferCommissionSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getTransferCommissionFailure(err));
            });
    };
};

const getTransferCommissionStarted = () => ({
    type: GET_TRANSFER_COMMISSION_STARTED
});

export const getTransferCommissionSuccess = serverReport => ({
    type: GET_TRANSFER_COMMISSION_SUCCESS,
    payload: serverReport
});

export const getTransferCommissionFailure = error => ({
    type: GET_TRANSFER_COMMISSION_FAILURE,
    payload: error
});

export const sendTransactions = (id, data) => {
    let url = host + api.walletApi.createWallet + `/${id}/send`;

    return dispatch => {
        dispatch(sendTransactionsStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(sendTransactionsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(sendTransactionsFailure(err));
            });
    };
};

const sendTransactionsStarted = () => ({
    type: SEND_TRANSACTIONS_STARTED
});

export const sendTransactionsSuccess = serverReport => ({
    type: SEND_TRANSACTIONS_SUCCESS,
    payload: serverReport
});

export const sendTransactionsFailure = error => ({
    type: SEND_TRANSACTIONS_FAILURE,
    payload: error
});

export const getWalletGraphHistory = (id) => {
    let url = host + api.walletApi.historyCurrencies + `/${id}/history`;

    return dispatch => {
        dispatch(getWalletGraphHistoryStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletGraphHistorySuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getWalletGraphHistoryFailure(err));
            });
    };
};

const getWalletGraphHistoryStarted = () => ({
    type: GET_WALLET_GRAPH_HISTORY_STARTED
});

export const getWalletGraphHistorySuccess = serverReport => ({
    type: GET_WALLET_GRAPH_HISTORY_SUCCESS,
    payload: serverReport
});

export const getWalletGraphHistoryFailure = error => ({
    type: GET_WALLET_GRAPH_HISTORY_FAILURE,
    payload: error
});


export const getOmtPeriods = () => {
    let url = host + api.walletApi.periods;

    return dispatch => {
        dispatch(getOmtPeriodsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getOmtPeriodsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getOmtPeriodsFailure(err));
            });
    };
};

const getOmtPeriodsStarted = () => ({
    type: GET_OMT_PERIODS_STARTED
});

export const getOmtPeriodsSuccess = serverReport => ({
    type: GET_OMT_PERIODS_SUCCESS,
    payload: serverReport
});

export const getOmtPeriodsFailure = error => ({
    type: GET_OMT_PERIODS_FAILURE,
    payload: error
});

export const getAvailableWallets = () => {
    let url = host + api.walletApi.createWallet;


    return dispatch => {
        dispatch(getAvailableWalletsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAvailableWalletsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAvailableWalletsFailure(err));
            });
    };
};

const getAvailableWalletsStarted = () => ({
    type: GET_AVAILABLE_WALLETS_STARTED
});

export const getAvailableWalletsSuccess = serverReport => ({
    type: GET_AVAILABLE_WALLETS_SUCCESS,
    payload: serverReport
});

export const getAvailableWalletsFailure = error => ({
    type: GET_AVAILABLE_WALLETS_FAILURE,
    payload: error
});

export const getCurrentWallet = (id) => {
    let url = host + api.walletApi.createWallet + `/${id}`;

    return dispatch => {
        dispatch(getCurrentWalletStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getCurrentWalletSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getCurrentWalletFailure(err));
            });
    };
};

const getCurrentWalletStarted = () => ({
    type: GET_CURRENT_WALLET_STARTED
});

export const getCurrentWalletSuccess = serverReport => ({
    type: GET_CURRENT_WALLET_SUCCESS,
    payload: serverReport
});

export const getCurrentWalletFailure = error => ({
    type: GET_CURRENT_WALLET_FAILURE,
    payload: error
});

export const getCurrentWalletTransactions = (id) => {
    let url = host + api.walletApi.transactions + `/?currency_id=${id}`;

    return dispatch => {
        dispatch(getCurrentWalletTransactionsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getCurrentWalletTransactionsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getCurrentWalletTransactionsFailure(err));
            });
    };
};

const getCurrentWalletTransactionsStarted = () => ({
    type: GET_CURRENT_WALLET_TRANSACTIONS_STARTED
});

export const getCurrentWalletTransactionsSuccess = serverReport => ({
    type: GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS,
    payload: serverReport
});

export const getCurrentWalletTransactionsFailure = error => ({
    type: GET_CURRENT_WALLET_TRANSACTIONS_FAILURE,
    payload: error
});

export const balanceTransactions = (isWithdraw, type, id, amount) => {
    let url;
    if(isWithdraw){
        url = host + api.walletApi.createWallet + `/${id}/${type}/withdraw`
    } else {
        url = host + api.walletApi.createWallet + `/${id}/${type}`
    }

    return dispatch => {
        dispatch(balanceTransactionsStarted());
        axios.post(url, {amount: amount}, headWT())
            .then(res => {
                dispatch(balanceTransactionsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(balanceTransactionsFailure(err));
            });
    };
};

const balanceTransactionsStarted = () => ({
    type: BALANCE_TRANSACTIONS_STARTED
});

export const balanceTransactionsSuccess = serverReport => ({
    type: BALANCE_TRANSACTIONS_SUCCESS,
    payload: serverReport
});

export const balanceTransactionsFailure = error => ({
    type: BALANCE_TRANSACTIONS_FAILURE,
    payload: error
});

export const setStakingBalanceStatus = status => ({
    type: SET_STAKING_BALANCE_STATUS,
    payload: status
});

export const getOneCurrentWallet = (id) => {
    let url = host + api.walletApi.createWallet + `/${id}`;

    return dispatch => {
        dispatch(getOneCurrentWalletStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getOneCurrentWalletSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getOneCurrentWalletFailure(err));
            });
    };
};

const getOneCurrentWalletStarted = () => ({
    type: GET_ONE_CURRENT_WALLET_STARTED
});

export const getOneCurrentWalletSuccess = serverReport => ({
    type: GET_ONE_CURRENT_WALLET_SUCCESS,
    payload: serverReport
});

export const getOneCurrentWalletFailure = error => ({
    type: GET_ONE_CURRENT_WALLET_FAILURE,
    payload: error
});

export const getAvailableStaking = () => {
    let url = host + api.walletApi.stakingMain;

    return dispatch => {
        dispatch(getAvailableStakingStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getAvailableStakingSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getAvailableStakingFailure(err));
            });
    };
};

const getAvailableStakingStarted = () => ({
    type: GET_AVAILABLE_STAKING_STARTED
});

export const getAvailableStakingSuccess = serverReport => ({
    type: GET_AVAILABLE_STAKING_SUCCESS,
    payload: serverReport
});

export const getAvailableStakingFailure = error => ({
    type: GET_AVAILABLE_STAKING_FAILURE,
    payload: error
});

export const openStaking = (data) => {
    const {sum, days, successCallback} = data;
    let url = host + api.walletApi.stakingOpen;

    const correctData = {
        days: days,
        amount: sum
    }

    return dispatch => {
        dispatch(openStakingStarted());
        axios.post(url, correctData, headWT())
            .then(res => {
                dispatch(openStakingSuccess(res.data.response));
                dispatch(getAvailableStaking());
                successCallback();
            })
            .catch(err => {
                dispatch(openStakingFailure(err));
                if (err.response.status === 400 && err.response.data.message){
                    toast(err.response.data.message)
                }
            });
    };
};

const openStakingStarted = () => ({
    type: OPEN_STAKING_STARTED
});

export const openStakingSuccess = serverReport => ({
    type: OPEN_STAKING_SUCCESS,
    payload: serverReport
});

export const openStakingFailure = error => ({
    type: OPEN_STAKING_FAILURE,
    payload: error
});

export const setStakingFormOmtStatus = status => ({
    type: SET_STAKING_FORM_OMT_STATUS,
    payload: status
});

export const getStakingCommission = (amount) => {
    let url;
    if (amount){
        url = host + api.walletApi.stakingCommission + `/create_stake/?amount=${amount}`;
    } else {
        url = host + api.walletApi.stakingCommission + `/create_stake`;
    }


    return dispatch => {
        dispatch(getStakingCommissionStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getStakingCommissionSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getStakingCommissionFailure(err));
            });
    };
};

const getStakingCommissionStarted = () => ({
    type: GET_STAKING_COMMISSION_STARTED
});

export const getStakingCommissionSuccess = serverReport => ({
    type: GET_STAKING_COMMISSION_SUCCESS,
    payload: serverReport
});

export const getStakingCommissionFailure = error => ({
    type: GET_STAKING_COMMISSION_FAILURE,
    payload: error
});

export const setStakingWithdrawFormStatus = status => ({
    type: SET_STAKING_WITHDRAW_FORM_STATUS,
    payload: status
});

export const getStakingAward = (id) => {
    // let url = host + api.walletApi.stakingCommission + `/${id}/make_staking_award`;
    let url = host + api.walletApi.stakingCommission + `/make_staking_award`;


    return dispatch => {
        dispatch(getStakingAwardStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getStakingAwardSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getStakingAwardFailure(err));
            });
    };
};

const getStakingAwardStarted = () => ({
    type: GET_STAKING_AWARD_STARTED
});

export const getStakingAwardSuccess = serverReport => ({
    type: GET_STAKING_AWARD_SUCCESS,
    payload: serverReport
});

export const getStakingAwardFailure = error => ({
    type: GET_STAKING_AWARD_FAILURE,
    payload: error
});

export const withdrawOmtStaking = (data) => {
    const {id, successCallback} = data;
    let url = host + api.walletApi.stakingWithdraw + `/${id}/get-award`;


    return dispatch => {
        dispatch(withdrawOmtStakingStarted());
        axios.post(url, null, headWT())
            .then(res => {
                dispatch(withdrawOmtStakingSuccess(res.data.response));
                successCallback();
            })
            .catch(err => {
                dispatch(withdrawOmtStakingFailure(err));
            });
    };
};

const withdrawOmtStakingStarted = () => ({
    type: WITHDRAW_OMT_STAKING_STARTED
});

export const withdrawOmtStakingSuccess = serverReport => ({
    type: WITHDRAW_OMT_STAKING_SUCCESS,
    payload: serverReport
});

export const withdrawOmtStakingFailure = error => ({
    type: WITHDRAW_OMT_STAKING_FAILURE,
    payload: error
});

export const withdrawStaking = (data) => {
    const {sum, id, successCallback} = data;
    let url = host + api.walletApi.stakingOpen + `/${id}/withdraw`;

    const correctData = {
        amount: sum
    }

    return dispatch => {
        dispatch(withdrawStakingStarted());
        axios.post(url, correctData, headWT())
            .then(res => {
                dispatch(withdrawStakingSuccess(res.data.response));
                dispatch(getAvailableStaking());
                successCallback()
            })
            .catch(err => {
                dispatch(withdrawStakingFailure(err));
                // временно, ошибки валидации нужно показать под полей для ввода
                if (err?.response?.status === 422) {
                    const message = err.response.data?.message;
                    if (message) {
                        toast.error(message)
                    }
                }

            });
    };
};

const withdrawStakingStarted = () => ({
    type: WITHDRAW_STAKING_STARTED
});

export const withdrawStakingSuccess = serverReport => ({
    type: WITHDRAW_STAKING_SUCCESS,
    payload: serverReport
});

export const withdrawStakingFailure = error => ({
    type: WITHDRAW_STAKING_FAILURE,
    payload: error
});


export const exchange = (data) => {
    let url = host + api.walletApi.exchange;

    return dispatch => {
        dispatch(exchangeStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(exchangeSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(exchangeFailure(err));
            });
    };
};

export const exchangeStarted = () => ({
    type: EXCHANGE_STARTED
});

export const exchangeSuccess = serverReport => ({
    type: EXCHANGE_SUCCESS,
    payload: serverReport
});

export const exchangeFailure = error => ({
    type: EXCHANGE_FAILURE,
    payload: error
});

export const getExchangeHistory = () => {
    let url = host + api.walletApi.exchangeHistory;

    return dispatch => {
        dispatch(getExchangeHistoryStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getExchangeHistorySuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getExchangeHistoryFailure(err));
            });
    };
};

const getExchangeHistoryStarted = () => ({
    type: GET_EXCHANGE_HISTORY_STARTED
});

export const getExchangeHistorySuccess = serverReport => ({
    type: GET_EXCHANGE_HISTORY_SUCCESS,
    payload: serverReport
});

export const getExchangeHistoryFailure = error => ({
    type: GET_EXCHANGE_HISTORY_FAILURE,
    payload: error
});

export const getExchangeData = () => {
    let url = host + api.walletApi.exchange;

    return dispatch => {
        dispatch(getExchangeDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getExchangeDataSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getExchangeDataFailure(err));
            });
    };
};

const getExchangeDataStarted = () => ({
    type: GET_EXCHANGE_DATA_STARTED
});

export const getExchangeDataSuccess = serverReport => ({
    type: GET_EXCHANGE_DATA_SUCCESS,
    payload: serverReport
});

export const getExchangeDataFailure = error => ({
    type: GET_EXCHANGE_DATA_FAILURE,
    payload: error
});

export const getWalletTransactions = (page) => {
    let url = `${host}${api.walletApi.transactions}?page=${page}`;

    return dispatch => {
        dispatch(getWalletTransactionsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletTransactionsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getWalletTransactionsFailure(err));
            });
    };
};

const getWalletTransactionsStarted = () => ({
    type: GET_WALLET_TRANSACTIONS_STARTED
});

export const getWalletTransactionsSuccess = serverReport => ({
    type: GET_WALLET_TRANSACTIONS_SUCCESS,
    payload: serverReport
});

export const getWalletTransactionsFailure = error => ({
    type: GET_WALLET_TRANSACTIONS_FAILURE,
    payload: error
});

export const setBotModalStatus = status => ({
    type: SET_BOT_MODAL_STATUS,
    payload: status
});

export const setCustomSettings = (botId, data) => {
    let url = host + api.bot.open + `/${botId}/custom-setting`;


    return dispatch => {
        dispatch(setCustomSettingsStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(setCustomSettingsSuccess(res.data.response));
                dispatch(getCustomSettings());
            })
            .catch(err => {
                dispatch(setCustomSettingsFailure(err));
            });
    };
};

const setCustomSettingsStarted = () => ({
    type: SET_CUSTOM_SETTINGS_STARTED
});

export const setCustomSettingsSuccess = serverReport => ({
    type: SET_CUSTOM_SETTINGS_SUCCESS,
    payload: serverReport
});

export const setCustomSettingsFailure = error => ({
    type: SET_CUSTOM_SETTINGS_FAILURE,
    payload: error
});


export const buySubscribe = (data) => {
    let url = host + api.bot.buySubscribe;


    return dispatch => {
        dispatch(buySubscribeStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(buySubscribeSuccess(res.data.response));
                dispatch(getBotMainData());
                dispatch(setBotSubscribeModalStatus(false));
                toast('Покупка подписки на Honee 2.0 брабатывается. Ожидайте завершения транзакции!')
            })
            .catch(err => {
                dispatch(buySubscribeFailure(err));
            });
    };
};

const buySubscribeStarted = () => ({
    type: BUY_SUBSCRIBE_STARTED
});

export const buySubscribeSuccess = serverReport => ({
    type: BUY_SUBSCRIBE_SUCCESS,
    payload: serverReport
});

export const buySubscribeFailure = error => ({
    type: BUY_SUBSCRIBE_FAILURE,
    payload: error
});


export const getBotProfit = () => {
    let url = host + api.cabinet.botProfit;

    return dispatch => {
        dispatch(getBotProfitStarted());
        axios.get(url, headWT())
            .then(res => {
                let maxCounter = 0;
                const correctData = {
                    data: res.data.response,
                    maxValue: 0
                }

                res.data.response.forEach((item)=>{
                    if (maxCounter > item.profit){
                        maxCounter = item.profit
                    }
                })

                correctData.maxValue = maxCounter;
                dispatch(getBotProfitSuccess(correctData));
            })
            .catch(err => {
                dispatch(getBotProfitFailure(err));
            });
    };
};

const getBotProfitStarted = () => ({
    type: GET_BOT_PROFIT_STARTED
});

export const getBotProfitSuccess = serverReport => ({
    type: GET_BOT_PROFIT_SUCCESS,
    payload: serverReport
});

export const getBotProfitFailure = error => ({
    type: GET_BOT_PROFIT_FAILURE,
    payload: error
});

export const getSubscribeCommission = (amount) => {
    let url = host + api.walletApi.subscribeCommission + `/subscription?amount=${amount}`;


    return dispatch => {
        dispatch(getSubscribeCommissionStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getSubscribeCommissionSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getSubscribeCommissionFailure(err));
            });
    };
};

const getSubscribeCommissionStarted = () => ({
    type: GET_SUBSCRIBE_COMMISSION_STARTED
});

export const getSubscribeCommissionSuccess = serverReport => ({
    type: GET_SUBSCRIBE_COMMISSION_SUCCESS,
    payload: serverReport
});

export const getSubscribeCommissionFailure = error => ({
    type: GET_SUBSCRIBE_COMMISSION_FAILURE,
    payload: error
});

export const setGreeting = (status) => {
    let url = host + api.cabinet.setGreeting;


    return dispatch => {
        dispatch(setGreetingStarted());
        axios.post(url, {gift_accepted: status}, headWT())
            .then(res => {
                dispatch(setGreetingSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(setGreetingFailure(err));
            });
    };
};

const setGreetingStarted = () => ({
    type: SET_GREETING_STARTED
});

export const setGreetingSuccess = serverReport => ({
    type:  SET_GREETING_SUCCESS,
    payload: serverReport
});

export const setGreetingFailure = error => ({
    type:  SET_GREETING_FAILURE,
    payload: error
});


