import React, {useState} from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import Logo from "../_UI/logo";
import LangSwitcher from "../langSwitcher";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";


const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    const [burgerBtnStatus, setBurgerBtnStatus] = useState(false);

    const buyBtnHandler = () =>{
        if (authStatus.phrase){
            if (authStatus.server){
                navigate('/exchange');
            } else {
                navigate('/server');
            }
        } else {
            if (authStatus.authorization){
                navigate('/confirmation');
            } else {
                navigate('/sign-in');
            }
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} ${burgerBtnStatus ? Styles.contentWrapWAbs : null}`}>
                <Logo/>
                <div className={`${Styles.burgerBtn}`} onClick={()=>{setBurgerBtnStatus(!burgerBtnStatus)}}>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.absMobileNavigation}`}>
                    <div className={`${Styles.navMWrap} ${Styles.pageContainer}`}>
                        <div className={`${Styles.navMBox}`}>
                            {/*<div className={`${Styles.navMBoxItem}`}>Menu</div>*/}
                            {/*<div className={`${Styles.navMBoxItem}`}>Menu</div>*/}
                            <div className={`${Styles.navMBoxItem}`} onClick={()=>{navigate(`/news/page/1`)}}>{t('Новости')}</div>
                            <div className={`${Styles.navMBoxItem}`} onClick={()=>{navigate('/white-paper/executive-summary')}}>WhitePaper</div>
                        </div>
                        <div className={`${Styles.button}`} onClick={()=>{buyBtnHandler()}}>{t('Купить')} Honee</div>
                    </div>
                    <div className={`${Styles.buttonsWrapBorder}`}>
                        <div className={`${Styles.buttonsWrap} ${Styles.pageContainer}`}>
                            {authStatus.authorization ?
                                <div className={`${Styles.authorizationWrap} ${Styles.authorizationWrapActive}`}>
                                    <div className={`${Styles.buttonWB}`} onClick={()=>{navigate('/honee')}}>{t('Кабинет')}</div>
                                </div> :
                                <div className={`${Styles.authorizationWrap}`}>
                                    <div className={`${Styles.buttonWB}`} onClick={()=>{navigate('/sign-in')}}>{t('Вход')}</div>
                                    <div className={`${Styles.button}`} onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`${Styles.langWrap} ${Styles.pageContainer}`}>
                        <LangSwitcher/>
                    </div>
                </div>
                <div className={`${Styles.desktopNavigationWrap}`}>
                    <div className={`${Styles.navWrap}`}>
                        <div className={`${Styles.navBox}`}>
                            {/*<div className={`${Styles.navBoxItem}`}>Menu</div>*/}
                            {/*<div className={`${Styles.navBoxItem}`}>Menu</div>*/}
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate(`/news/page/1`)}}>{t('Новости')}</div>
                            <div className={`${Styles.navBoxItem}`} onClick={()=>{navigate('/white-paper/executive-summary')}}>WhitePaper</div>
                        </div>
                        <div className={`${Styles.button}`} onClick={()=>{buyBtnHandler()}}>{t('Купить')} Honee</div>
                    </div>
                </div>
                <div className={`${Styles.desktopButtonsWrap}`}>
                    {authStatus.authorization ?
                        <div className={`${Styles.authorizationWrap} ${Styles.authorizationWrapActive}`}>
                            <div className={`${Styles.button}`} onClick={()=>{navigate('/honee')}}>{t('Кабинет')}</div>
                        </div> :
                        <div className={`${Styles.authorizationWrap}`}>
                            <div className={`${Styles.buttonWB}`} onClick={()=>{navigate('/sign-in')}}>{t('Вход')}</div>
                            <div className={`${Styles.button}`} onClick={()=>{navigate('/sign-up')}}>{t('Регистрация')}</div>
                        </div>
                    }
                    <div className={`${Styles.langWrap}`}>
                        <LangSwitcher isVertical={true}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;