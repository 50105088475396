import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {resetPassword, resetPasswordFailure, resetPasswordSuccess} from "../../store/main/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";


const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formErr = useSelector(store => store.main.resetPasswordStatusErr);
    const formSuccess = useSelector(store => store.main.resetPasswordStatus);

    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [backup_phrase, setBackup_phrase] = useState('');



    const sendFormHandler = () =>{
        dispatch(resetPassword({backup_phrase, password, password_confirmation}));
    }

    useEffect(()=>{
        if (formSuccess){
            navigate('/sign-in');
            dispatch(resetPasswordSuccess(false));
        }
    },[formSuccess, navigate, dispatch])

    return (
        <section className={`${Styles.container} ${Styles.pageContainer}`}>
            <Seo title={'Reset your password'}/>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Сброс пароля')}</div>
                <div className={`${Styles.inputsBox}`}>
                    <div className={`${Styles.inputWrap} ${formErr?.backup_phrase ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Фраза востановления')}</div>
                        <input
                            type="text"
                            placeholder='Your backup phrase'
                            value={backup_phrase}
                            onChange={(e)=>{setBackup_phrase(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.backup_phrase){
                                    delete formErr.backup_phrase;
                                    dispatch(resetPasswordFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.backup_phrase ? formErr.backup_phrase : null}</div>
                    </div>
                    <div className={`${Styles.inputWrap} ${formErr?.password ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Пароль')}</div>
                        <input
                            type="password"
                            placeholder='********'
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.password){
                                    delete formErr.password;
                                    dispatch(resetPasswordFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.password?.[0] ? formErr.password[0] : null}</div>
                    </div>
                    <div className={`${Styles.inputWrap} ${formErr?.password_confirmation ? Styles.inputWrapWithErr : null}`}>
                        <div className={`${Styles.inputTitle}`}>{t('Подтвердите пароль')}</div>
                        <input
                            type="password"
                            placeholder='********'
                            value={password_confirmation}
                            onChange={(e)=>{setPassword_confirmation(e.target.value)}}
                            onKeyDown={(event)=>{
                                if (formErr.password_confirmation){
                                    delete formErr.password_confirmation;
                                    dispatch(resetPasswordFailure(formErr));
                                }
                            }}
                        />
                        <div className={`${Styles.inputErr}`}>{formErr?.password_confirmation?.[0] ? formErr.password_confirmation[0] : null}</div>
                    </div>
                </div>
                <div className={`${Styles.links}`}>{t('Еще нет аккаунта?')} <span onClick={()=>{navigate('/sign-up')}}>{t('Зарегестрируйтесь')}</span></div>
                <div className={`${Styles.mainBtn}`} onClick={()=>{sendFormHandler()}}>{t('Сброс пароля')}</div>
            </div>
        </section>
    );
};

export default ResetPasswordPage;
