import React from "react";
import Styles from './styles.module.scss';

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import newsReadIcon from "../../theme/assets/svg/omt_news_read_icon.svg";
import Moment from "react-moment";
import DOMPurify from "dompurify";
import {linkTranslate} from "../../helpers/linkTranslite";
import {useNavigate} from "react-router-dom";


const PostMostRead = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mostReadNews = useSelector(store => store.main.topNews);

    const postLinkHandler = (title, id) => {
        const correctUrl = linkTranslate(title, id);
        navigate(`/news/${correctUrl}`);
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title2} ${Styles.titleWSF}`}>{t('Самые читаемые')}</div>
                <div className={`${Styles.contentBox}`}>
                    {mostReadNews.length > 0 ? mostReadNews.map((item, index)=>{
                        return (
                            <div className={`${Styles.contentBoxItem}`} key={'mostReadNews' + index} onClick={()=>{postLinkHandler(item.title, item.id)}}>
                                <div className={`${Styles.dateWrap}`}>
                                    <div className={`${Styles.date}`}><Moment format="DD/MM/YYYY">{item.date}</Moment></div>
                                    <div className={`${Styles.read}`}>
                                        <div className={`${Styles.readImgWrap}`}>
                                            <img src={newsReadIcon} alt={item.title}/>
                                        </div>
                                        {item.read_time} minutes
                                    </div>
                                </div>
                                <div className={`${Styles.title3} ${Styles.titleWSF}`}>{item.title}</div>
                                <div
                                    className={`${Styles.contentBoxItemDescription}`}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(item.content),
                                    }}
                                />
                                <div className={`${Styles.contentBoxItemCategory}`}>{item.category?.name}</div>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        </section>
    );
};

export default PostMostRead;
