import { combineReducers } from 'redux';
import {mainReducer} from "./main/reducer";
import {cabinetReducer} from "./cabinet/reducer";



export const rootReducer = combineReducers({
    main: mainReducer,
    cabinet: cabinetReducer
})
