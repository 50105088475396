import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import SupportMain from "../../components/supportMain";


const SupportPage = () => {
    const navigate = useNavigate();
    const authStatus = useSelector(store => store.main.authorizationStatus);
    const { t } = useTranslation();

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Поддержка')}/>
            <div className={`${Styles.contentWrap}`}>
                <SupportMain/>
            </div>
        </section>
    );
};

export default SupportPage;