import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";



const MainHello = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title2}`}>
                    <span>{t('Инновационные технологии')}</span> {t('для максимальной прибыли и безопасности')}
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>{t('Мощь ИИ и блокчейна в каждой сделке')}</div>
                        <div className={`${Styles.contentBoxItemNote}`}>{t('ИИ анализирует курсы криптовалют на десятках бирж в реальном времени и находит лучшие арбитражные сделки с учетом всех комиссий')}.</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>{t('Полная автоматизация')}</div>
                        <div className={`${Styles.contentBoxItemNote}`}>{t('Вы задаете параметры — система сама выполняет сделки и приносит прибыль')}.</div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>{t('Безопасность и надежность')}</div>
                        <div className={`${Styles.contentBoxItemNote}`}>{t('Ваши данные и сделки защищены на уровне блокчейна и современных технологий безопасности')}.</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainHello;