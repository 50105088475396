import React, {useCallback, useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import dropArrow from '../../theme/assets/svg/omt_index_drop_arrow.svg';
import ReactECharts from "echarts-for-react";
import {getIndexSwapPrediction} from "../../store/cabinet/action";



const IndexPrediction = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const indexSwapPrediction = useSelector(store => store.cabinet.indexSwapPrediction);


    const [withPrediction, setWithPrediction] = useState(false);
    const [currentDays, setCurrentDays] = useState(360);
    const [currentCrypto, setCurrentCrypto] = useState('');
    const [currentCryptoList, setCurrentCryptoList] = useState([]);
    const [cryptoDropdownStatus, setCryptoDropdownStatus] = useState(false);
    const [correctData, setCorrectData] = useState(false);


    // const dateHandler = (sArr, pArr) =>{
    //     console.log(sArr.concat(pArr));
    //     sArr.splice(sArr.length - 1, 1);
    //     console.log(sArr.concat(pArr));
    //
    //     return sArr.concat(pArr)
    // }
    // if (correctData){
    //     console.log(dateHandler(correctData.omt20.s.names, correctData.omt20.p.names))
    // }

    const option = {
        grid: {
            left: '0%',
            right: '0%',
            bottom: '10%',
            top: '10%',
            containLabel: true
        },
        xAxis: {
            show: false,
            type: 'category',
            // data: correctData ? correctData.omt20.s.names.splice(0, correctData.omt20.s.names.length - 1).concat(correctData.omt20.p.names) : [null],
            axisLabel: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: '#8C9497'
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}%',
                textStyle: {
                    color: '#ffffff'
                }
            },
            splitLine: {
                lineStyle: {
                    color: '#8C9497'
                }
            }
        },
        series: [
            {
                data: correctData ? correctData.omt20.s.values.concat(correctData.omt20.p.values.map(item=>null)) : [null],
                type: 'line',
                lineStyle: {
                    color: '#FFC200'
                },
                showSymbol: false,
                symbol: 'none',
                smooth: true
            },
            {
                data: correctData && withPrediction ? correctData.omt20.s.values.map(item=>null).splice(0, correctData.omt20.s.values.length - 1).concat(correctData.omt20.p.values) : [null],
                type: 'line',
                lineStyle: {
                    type: 'dashed',
                    color: '#FFC200'
                },
                showSymbol: false,
                symbol: 'none',
                smooth: true
            },
            {
                data: correctData ? correctData.crypto.s.values.concat(correctData.crypto.p.values.map(item=>null)) : [null],
                type: 'line',
                lineStyle: {
                    color: '#F7931A'
                },
                showSymbol: false,
                symbol: 'none',
                smooth: true
            },
            {
                data: correctData && withPrediction ? correctData.crypto.s.values.map(item=>null).splice(0, correctData.crypto.s.values.length - 1).concat(correctData.crypto.p.values) : [null],
                type: 'line',
                lineStyle: {
                    type: 'dashed',
                    color: '#F7931A'
                },
                showSymbol: false,
                symbol: 'none',
                smooth: true
            }
        ],
        // dataZoom: [
        //     {
        //         startValue: '2024-02-13',
        //         endValue: '2024-07-11'
        //     },
        //     {
        //         type: 'inside'
        //     }
        // ],
    };


    const graphDataHandler = useCallback((data)  =>{
        let crypto = [];
        // let names = [];
        // let values = [];
        // let maxValue = 0;

        let omt20nameS = [];
        let omt20valueS = [];
        let omt20nameP = [];
        let omt20valueP = [];
        let cryptoNameS = [];
        let cryptoValueS = [];
        let cryptoNameP = [];
        let cryptoValueP = [];


        for (let key in data) {
            if(data.hasOwnProperty(key)){
                crypto.push(key);
            }
        }

        if (crypto.length > 0){
            setCurrentCryptoList(crypto)
            if (currentCrypto === ''){
                setCurrentCrypto(crypto[0])
            }
        }


        data['OMT20'].standard.forEach((item, index)=>{
            omt20nameS.push(item.date);
            omt20valueS.push(item.value);

            if (data['OMT20'].standard.length - 1 === index){
                omt20nameP.push(item.date);
                omt20valueP.push(item.value);
            }
        })

        data['OMT20'].prediction.forEach((item)=>{
            omt20nameP.push(item.date);
            omt20valueP.push(item.value);
        })

        data[currentCrypto === '' ? crypto[0] : currentCrypto].standard.forEach((item, index)=>{
            cryptoNameS.push(item.date);
            cryptoValueS.push(item.value);

            if (data[currentCrypto === '' ? crypto[0] : currentCrypto].standard.length - 1 === index){
                cryptoNameP.push(item.date);
                cryptoValueP.push(item.value);
            }
        })

        data[currentCrypto === '' ? crypto[0] : currentCrypto].prediction.forEach((item)=>{
            cryptoNameP.push(item.date);
            cryptoValueP.push(item.value);
        })

        // for (let key in data) {
        //     if(data.hasOwnProperty(key)){
        //         names.push(key);
        //         values.push(data[key]);
        //     }
        // }
        //
        if (omt20nameS.length > 0 && omt20valueS.length > 0 && omt20nameP.length > 0 && omt20valueP.length > 0){
            setCorrectData({
                omt20: {
                    s: {names: omt20nameS, values: omt20valueS},
                    p: {names: omt20nameP, values: omt20valueP}
                },
                crypto: {
                    s: {names: cryptoNameS, values: cryptoValueS},
                    p: {names: cryptoNameP, values: cryptoValueP}
                }
            })
        }
    }, [currentCrypto])

    useEffect(()=>{
        if (indexSwapPrediction){
            graphDataHandler(indexSwapPrediction)
        }
    },[graphDataHandler, indexSwapPrediction])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.titleBox}`}>
                            <div className={`${Styles.titleWSF}`}>Index <span className={`${Styles.titleWSF}`}>OMT20</span> {t('c')}</div>
                            <div className={`${Styles.cryptoBtn}`}>
                                <div className={`${Styles.cryptoBtnClick}`} onClick={()=>{setCryptoDropdownStatus(!cryptoDropdownStatus)}}>
                                    <div className={`${Styles.cryptoBtnText}`}>{currentCrypto.length > 0 ? currentCrypto : 'Load'}</div>
                                    <div className={`${Styles.cryptoBtnImgWrap}`}>
                                        <img src={dropArrow} alt="drop arrow"/>
                                    </div>
                                </div>
                                <div className={`${Styles.cryptoBtnDropdown} ${cryptoDropdownStatus ? Styles.cryptoBtnDropdownActive : null}`}>
                                    {currentCryptoList.length > 0 ? currentCryptoList.map((item, index)=>{
                                        if (item === 'OMT20'){
                                            return null
                                        } else {
                                            return (
                                                <div
                                                    className={`${Styles.cryptoBtnDropdownItem} ${item === currentCrypto ? Styles.cryptoBtnDropdownItemActive : null}`}
                                                    key={'cryptoDrop' + index}
                                                    onClick={()=>{
                                                        setCryptoDropdownStatus(false);
                                                        setCurrentCrypto(item);
                                                    }}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        }
                                    }) : null}
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.predictionBox}`}>
                            <div className={`${Styles.predictionBoxItem} ${Styles.titleWSF} ${withPrediction ? null : Styles.predictionBoxItemActive}`} onClick={()=>{setWithPrediction(false)}}>{t('Сравнение')}</div>
                            <div className={`${Styles.predictionBoxItem} ${Styles.titleWSF} ${withPrediction ? Styles.predictionBoxItemActive : null}`} onClick={()=>{setWithPrediction(true)}}>{t('Прогноз')}</div>
                        </div>
                    </div>
                    <div className={`${Styles.graphWrap}`}>
                        <ReactECharts option={option} style={{ height: '260px', width: '100%'}}/>
                    </div>
                    <div className={`${Styles.timeWrap}`}>
                        <div className={`${Styles.timeTitle} ${Styles.titleWSF}`}>{t('Временной отрезок')}:</div>
                        <div className={`${Styles.timeBox}`}>
                            {/*<div className={`${Styles.timeBoxItem} ${currentDays === 1 ? Styles.timeBoxItemActive : null}`} onClick={()=>{setCurrentDays(1); dispatch(getIndexSwapPrediction(2))}}>{t('День')}</div>*/}
                            {/*<div className={`${Styles.timeBoxItem} ${currentDays === 7 ? Styles.timeBoxItemActive : null}`} onClick={()=>{setCurrentDays(7); dispatch(getIndexSwapPrediction(7))}}>{t('Неделя')}</div>*/}
                            <div className={`${Styles.timeBoxItem} ${Styles.titleWSF} ${currentDays === 30 ? Styles.timeBoxItemActive : null}`} onClick={()=>{setCurrentDays(30); dispatch(getIndexSwapPrediction(30))}}>{t('Месяц')}</div>
                            <div className={`${Styles.timeBoxItem} ${Styles.titleWSF} ${currentDays === 90 ? Styles.timeBoxItemActive : null}`} onClick={()=>{setCurrentDays(90); dispatch(getIndexSwapPrediction(90))}}>{t('Сезон')}</div>
                            <div className={`${Styles.timeBoxItem} ${Styles.titleWSF} ${currentDays === 360 ? Styles.timeBoxItemActive : null}`} onClick={()=>{setCurrentDays(360); dispatch(getIndexSwapPrediction(360))}}>{t('Год')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndexPrediction;
