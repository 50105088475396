import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import {choiceServer, getServer, setServerModalStatus} from "../../store/main/action";
import {toast} from "react-toastify";
import ServerModal from "../../components/serverModal";


const ServerPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const server = useSelector(store => store.main.server);
    const authStatus = useSelector(store => store.main.authorizationStatus);

    const [currentRegionId, setCurrentRegionId] = useState(server?.regions?.[0]?.id ? server?.regions?.[0]?.id : 0);
    const [currentServerId, setCurrentServerId] = useState(false);

    const percentBarHandler = (currentValue, totalValue) =>{
        const percent = (currentValue / totalValue) * 100;
        if (percent > 100){
            return 100
        } else if (percent <= 100 && percent >= 5){
            return percent
        } else if (percent < 5 && percent > 0){
            return 5
        } else {
            return 0
        }
    }

    const changeRegionHandler = (regionId) =>{
        setCurrentServerId(false);
        setCurrentRegionId(regionId)
    }

    const mainBtnHandler = (id) =>{
        dispatch(choiceServer(id));
    }


    useEffect(()=>{
        if (authStatus.phrase){
            if (authStatus.server){
                navigate('/honee');
            } else {
                navigate('/server');
            }
        } else {
            if (authStatus.authorization){
                navigate('/confirmation');
            } else {
                navigate('/sign-in');
            }
        }
    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getServer());
    },[dispatch])
    
    useEffect(()=>{
        if (server){
            if (server.partner_server_id && server.partner_server_region_id){
                setCurrentRegionId(server.partner_server_region_id);
                setCurrentServerId(server.partner_server_id);
                dispatch(setServerModalStatus(true))
            } else {
                setCurrentRegionId(server?.regions?.[0]?.id ? server?.regions?.[0]?.id : 0);
            }
        }
    }, [dispatch, server])


    return (
        <section className={`${Styles.container} ${Styles.pageContainer}`}>
            <Seo title={t('Выбор сервера')}/>
            <ServerModal/>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.title}`}>{t('Выбор сервера')}!</div>
                    <div className={`${Styles.serverWrap}`}>
                        <div className={`${Styles.regionWrap}`}>
                            <div className={`${Styles.regionTitle}`}>{t('Выберите регион')}:</div>
                            <div className={`${Styles.regionBox}`}>
                                {server?.regions?.length > 0 ? server.regions.map((item, index)=>{
                                    return (
                                        <div
                                            className={`${Styles.regionItem} ${currentRegionId === item.id ? Styles.regionItemActive : null}`}
                                            key={'serverRegion' + index}
                                            onClick={()=>{
                                                changeRegionHandler(item.id)
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </div>
                        <div className={`${Styles.detailsWrap}`}>
                            <div className={`${Styles.detailsTitle}`}>{t('Выберите сервер')}:</div>
                            <div className={`${Styles.detailsBox}`}>
                                {server?.regions?.map((item) => {
                                    if (currentRegionId === item.id) {
                                        return item.servers.map((itemS, indexS) => (
                                            <div
                                                className={`${Styles.detailsBoxItem} ${currentServerId === itemS.id ? Styles.detailsBoxItemActive : null}`}
                                                key={'choiceServer' + indexS}
                                                onClick={()=>{
                                                    if (itemS.used_places >= itemS.total_places){
                                                        toast(t('Сервер переполнен. Выберите другой сервер'))
                                                    } else {
                                                        setCurrentServerId(itemS.id)
                                                    }
                                                }}
                                            >
                                                {server.partner_server_id === itemS.id ?
                                                    <div className={`${Styles.serverInviterAbs}`}>{t('Ваш пригласитель на этом сервере')}</div> : null
                                                }
                                                <div className={`${Styles.serverName}`}>{itemS.name}</div>
                                                <div className={`${Styles.serverBarWrap}`}>
                                                    <div className={`${Styles.serverBarTitle} ${Styles.titleWSF}`}></div>
                                                    <div className={`${Styles.serverBarBox}`}>
                                                        <div className={`${Styles.serverBarBoxValue}`}>
                                                            {itemS.used_places} / {itemS.total_places}
                                                        </div>
                                                        <div className={`${Styles.serverBarAbs}`} style={{width: `${percentBarHandler(itemS.used_places, itemS.total_places)}%`}}/>
                                                    </div>
                                                </div>
                                                <div className={`${Styles.serverDataWrap}`}>
                                                    <div className={`${Styles.serverDataName} ${Styles.titleWSF}`}>{t('Процент Доходности')}</div>
                                                    <div className={`${Styles.serverDataValue}`}>{itemS.percent * 1}</div>
                                                </div>
                                                <div className={`${Styles.serverDataWrap}`}>
                                                    <div className={`${Styles.serverDataName} ${Styles.titleWSF}`}>{t('Инвестировано')}</div>
                                                    <div className={`${Styles.serverDataValue}`}>{itemS.invested * 1} USDT</div>
                                                </div>
                                                <div className={`${Styles.serverDataWrap}`}>
                                                    <div className={`${Styles.serverDataName} ${Styles.titleWSF}`}>{t('Средний уровень бота')}</div>
                                                    <div className={`${Styles.serverDataValue}`}>{itemS.avg_bot_level * 1}</div>
                                                </div>
                                            </div>
                                        ));
                                    }
                                    return null;
                                })}
                            </div>
                            <div className={`${Styles.mainBtn} ${currentServerId ? Styles.mainBtnActive : null}`} onClick={()=>{mainBtnHandler(currentServerId)}}>{t('Подтвердить выбор')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServerPage;
