import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {scroller} from 'react-scroll';
// import {Element, scroller} from 'react-scroll';
// import {getNews, setLinkScrollStatus} from "../../store/main/action";
import Styles from './styles.module.scss'
// import {useTranslation} from "react-i18next";
import MainHello from "../../components/mainHello";
import CryptoMarquee from "../../components/cryptoMarquee";
import MainFuture from "../../components/mainFuture";
import MainRegister from "../../components/mainRegister";
import MainWork from "../../components/mainWork";
import MainAbout from "../../components/mainAbout";
import MainNews from "../../components/mainNews";
import {getLatestNews, getOmtPrice} from "../../store/main/action";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";



const MainPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // const { t } = useTranslation();

    const linkScrollStatus = useSelector(store => store.main.linkScrollStatus);

    const scrollHandler = (element) =>{
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    useEffect(()=>{
        if (linkScrollStatus){
            scrollHandler(linkScrollStatus);
            // dispatch(setLinkScrollStatus(false));
        }
    },[dispatch, linkScrollStatus])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    useEffect(()=>{
        dispatch(getOmtPrice());
        dispatch(getLatestNews());
    },[dispatch])

    // useEffect(()=>{
    //     dispatch(getNews(8));
    // },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Простота прибыли для всех')}/>
            <MainHello/>
            <CryptoMarquee/>
            <MainFuture/>
            <MainRegister/>
            <MainWork/>
            <MainAbout/>
            <MainNews/>
        </section>
    );
};

export default MainPage;