import React, {useCallback, useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getTransferCommission, getTransferCommissionSuccess,
    sendTransactions,
    sendTransactionsSuccess
} from "../../../store/cabinet/action";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loader from "../loader";
import CommissionAlert from "../../commissionAlert";



const WalletSendStep2 = (props) => {
    const {currentToken, closeModal} = props; // eslint-disable-line
    const sendTransactionsStatus = useSelector(store => store.cabinet.sendTransactionsStatus);
    const sendTransactionsStatusStarted = useSelector(store => store.cabinet.sendTransactionsStatusStarted);
    const transferCommission = useSelector(store => store.cabinet.transferCommission);
    const transferCommissionStarted = useSelector(store => store.cabinet.transferCommissionStarted);

    // console.log(transferCommission)
    // console.log(currentToken)

    // const currencyCommission = {fee: 0.002011};

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [address, setAddress] = useState('');
    const [sum, setSum] = useState('');
    const [isFirstCommission, setIsFirstCommission] = useState(true);
    
    const clearForm = () =>{
        setAddress('');
        setSum('');
        dispatch(getTransferCommissionSuccess(false));
    }

    const isReadyToGetCommission = useCallback(()=>{
        return address.length > 30 && currentToken?.currency?.id && Number(sum) >= Number(currentToken?.currency?.min_send_amount)
    },[address.length, currentToken, sum])

    const isReadyToSendCrypto = () =>{
        if (transferCommission?.currency?.short_name === currentToken?.currency?.short_name){
            return isReadyToGetCommission() && Number(sum) >= Number(currentToken?.currency?.min_send_amount) && Number(sum) <= (Number(currentToken?.amount) - Number(transferCommission?.commission))
        } else {
            return isReadyToGetCommission() && isCommissionSuccess() && Number(sum) >= Number(currentToken?.currency?.min_send_amount) && Number(sum) <= Number(currentToken?.amount)
        }
    }

    const isCommissionSuccess = () =>{
        return Number(transferCommission?.wallet?.amount) >= transferCommission?.commission
    }

    const commissionContent = () =>{
        if (transferCommission?.currency?.short_name === currentToken?.currency?.short_name){
            return null
        } else {
            if (isReadyToGetCommission() && (transferCommission?.commission || transferCommission?.commission === 0)){
                return isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span>
            } else {
                return null
            }
        }
    }

    const commissionText = () =>{
        if (address.length > 30){
            if (Number(sum) >= Number(currentToken?.currency?.min_send_amount)){
                return null
            } else {
                return t('Введите сумму')
            }
        } else {
            return t('Введите адрес')
        }
    }

    useEffect(()=>{
        if (isReadyToGetCommission()){
            if (isFirstCommission){
                dispatch(getTransferCommission(currentToken?.currency?.id, address, sum));
                setIsFirstCommission(false);
            }

            const interval = setInterval(()=>{
                if (currentToken?.currency?.id){
                    dispatch(getTransferCommission(currentToken?.currency?.id, address, sum));
                }
            }, 5000)
            return ()=>{
                clearInterval(interval)
            }
        }
    },[dispatch, currentToken, isReadyToGetCommission, address, isFirstCommission, sum])

    useEffect(()=>{
        if (sendTransactionsStatus){
            toast(`${t('Ваш перевод на сумму')} ${sendTransactionsStatus.amount} ${currentToken?.currency?.short_name} ${t('успешно отправлен. Ожидайте завершения транзакции!')}`);
            clearForm();
            closeModal();
            dispatch(sendTransactionsSuccess(false));
        }
    },[sendTransactionsStatus]) // eslint-disable-line

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Отправить')} {currentToken?.currency?.short_name ? currentToken?.currency?.short_name : ''}</div>
                {currentToken?.currency?.short_name === 'TRX' ? <CommissionAlert/> : null}
                <div className={`${Styles.otherInputsWrap}`}>
                    <div className={`${Styles.otherInputsBox}`}>
                        <div className={`${Styles.otherInputsBoxTitle}`}>{t('Адрес вывода')}</div>
                        <input type="text" onChange={(e)=>{setAddress(e.target.value)}} value={address}/>
                    </div>
                    <div className={`${Styles.otherInputsBox}`}>
                        <div className={`${Styles.otherInputsBoxTitle}`}>{t('Сумма на вывод')}</div>
                        <input
                            type="number"
                            // max={Number(currentToken?.balance)}
                            // min={0}
                            placeholder={`${t('От')} ${currentToken?.currency?.min_send_amount * 1} ${currentToken?.currency?.short_name}`}
                            onChange={(e)=>{
                                if (e.target.value < 0){
                                    setSum(0)
                                } else if (e.target.value > Number(currentToken?.amount * 1)){
                                    setSum(Number(currentToken?.amount).toFixed(currentToken?.currency?.decimals) * 1)
                                } else {
                                    setSum(e.target.value)
                                }
                            }}
                            className={`${Styles.inputSum}`}
                            value={sum}
                        />
                        <div className={`${Styles.inputAbsBtn}`} onClick={()=>{
                            if (transferCommission?.currency?.short_name === currentToken?.currency?.short_name){
                                if ((Number(currentToken?.amount).toFixed(2) * 1 - transferCommission?.commission) < 0){
                                    setSum(0)
                                } else {
                                    setSum(Number(Number(currentToken?.amount).toFixed(2) * 1 - transferCommission?.commission).toFixed(6))
                                }
                            } else {
                                setSum(Number(currentToken?.amount).toFixed(6) * 1)
                            }
                        }}>
                            {t('Все')}
                        </div>
                        <div className={`${Styles.inputNote}`}>{t('Доступный баланс')}: <span>{Number(currentToken?.amount).toFixed(currentToken?.currency?.decimals) * 1} {currentToken?.currency?.short_name}</span></div>
                    </div>
                </div>
                    <div className={`${Styles.taxWrap}`}>
                        <div className={`${Styles.taxWrapRow}`}>
                            <div className={`${Styles.taxWrapRowName}`}>{t('Комиссия сети:')}</div>
                            <div className={`${Styles.taxWrapRowValue} ${transferCommissionStarted ? Styles.taxWrapRowValueHide : null}`}>
                                {(transferCommission?.commission || transferCommission?.commission === 0) && isReadyToGetCommission() ? `${transferCommission.commission} ${transferCommission?.currency?.short_name}` : commissionText()} {commissionContent()}
                            </div>
                        </div>
                        {/*<div className={`${Styles.taxWrapRow}`}>*/}
                        {/*    <div className={`${Styles.taxWrapRowName}`}>{t('Итого с вычетом комиссии:')}</div>*/}
                        {/*    <div className={`${Styles.taxWrapRowValue}`}>{currencyCommission && sum > 0 ? `${Number(sum - currencyCommission?.fee).toFixed(currentToken?.currency?.decimals) * 1} ${currentToken?.currency?.short_name}` : 'Enter amount'}</div>*/}
                        {/*</div>*/}
                    </div>
                <div
                    className={`${Styles.btn} ${isReadyToSendCrypto() ? Styles.btnActive : null} ${sendTransactionsStatusStarted ? Styles.mainBtnLoading : null}`}
                    onClick={()=>{
                        if (isReadyToSendCrypto() && !sendTransactionsStatusStarted){
                            dispatch(sendTransactions(currentToken.currency.id, {wallet: address, amount: sum}))
                        }
                    }}
                >
                    <Loader status={!!sendTransactionsStatusStarted}/>
                    <div className={`${Styles.textWrap}`}>{t('Отправить')}</div>
                </div>
            </div>
        </section>
    );
};

export default WalletSendStep2;
