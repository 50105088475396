import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ReactECharts from "echarts-for-react";
import {spaceHandler} from "../../helpers/dotsHandler";
import questionIcon from '../../theme/assets/svg/omt_question_icon.svg';
import CryptoIcon from "../_UI/cryptoIcon";
import {useNavigate} from "react-router-dom";



const IndexMain = (props) => {
    const {link} = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const indexMainData = useSelector(store => store.cabinet.indexMainData);

    const customColors = ['#FFD600', '#F7931A', '#00FF7A', '#CA8622', '#45aadc'];
    
    const [graphData, setGraphData] = useState([]);
    
    useEffect(()=>{
        if (indexMainData){
            let correctData = [];
            let currenciesPercent = 0;
            indexMainData.currencies.forEach((item)=>{
                currenciesPercent = currenciesPercent + item.percent
                correctData.push({value: item.percent, name: item.code})
            })

            if (currenciesPercent < 100){
                correctData.push({value: 100 - currenciesPercent, name: 'Other'})
            }

            setGraphData(correctData);
        }
    },[indexMainData])

    const option = {
        color: customColors,
        tooltip: {
            trigger: 'item',
            formatter: function(params) {
                const borderColor = params.color; // Получаем цвет из параметров события
                return (
                    `<div style="background-color: rgba(54,40,5,255); color: white; padding: 10px; border: 10px solid ${borderColor};">
                        ${params.seriesName}<br/>
                        ${params.name}: <span style="font-weight: bold;">${params.value}%</span>
                    </div>`
                );
            },
            extraCssText: 'padding: 0;'
        },
        legend: {
            show: false,
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'Index OMT20',
                type: 'pie',
                radius: ['50%', '80%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#241604',
                    borderWidth: 5
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [...graphData.slice(0, 4), {value: graphData.slice(4).reduce((sum, item) => sum + item.value, 0), name: 'Other'}]
            }
        ]
    };

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title2} ${Styles.titleWSF}`}>Index <span className={`${Styles.titleWSF}`}>OMT20</span></div>
                        <div className={`${Styles.questionIconWrap}`} onClick={()=>{navigate(link)}}>
                            <img src={questionIcon} alt="question"/>
                        </div>
                    </div>
                    <div className={`${Styles.graphWrap}`}>
                        {graphData.length > 0 ?
                            <div className={`${Styles.graphBox}`}>
                                <ReactECharts option={option} style={{ height: '100%', width: '100%'}}/>
                                <div className={`${Styles.absData}`}>
                                    <div className={`${Styles.absDataTitle}`}>{t('Цена')}:</div>
                                    <div className={`${Styles.absDataValue}`}>{spaceHandler(indexMainData?.index?.rate * 1, true)}</div>
                                </div>
                            </div> :
                            <div className={`${Styles.graphLoad}`}>
                                <div className={`${Styles.graphLoadText}`}>Loading...</div>
                            </div>
                        }
                    </div>
                    <div className={`${Styles.listWrap}`}>
                        <div className={`${Styles.listTitleRow}`}>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>
                                {t('Токен')}<br/>
                                %
                            </div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>
                                {t('Цена')}<br/>
                                $/%
                            </div>
                        </div>
                        <div className={`${Styles.listBox}`}>
                            {indexMainData?.currencies?.length > 0 ? indexMainData.currencies.map((item, index)=>{
                                return (
                                    <div className={`${Styles.listBoxItem}`} key={'indexCurrencies' + index}>
                                        <div className={`${Styles.cryptoBox}`}>
                                            <div className={`${Styles.iconWrap}`}>
                                                <CryptoIcon short_name={item.code} indexSize={true}/>
                                            </div>
                                            <div className={`${Styles.nameWrap}`}>
                                                <div className={`${Styles.name}`}>{item.code}</div>
                                                <div className={`${Styles.percent}`} style={{color: `${customColors[index]}`}}><span>.</span>{item.percent}%</div>
                                            </div>
                                        </div>
                                        <div className={`${Styles.infoBox}`}>
                                            <div className={`${Styles.rate}`}>{spaceHandler(item.rate * 1, true)}</div>
                                            <div className={`${Styles.change} ${item.percent_change_24h > 0 ? Styles.colorGreen : Styles.colorRed}`}>{((item.rate * item.percent_change_24h) / 100).toFixed(6)}$ / {item.percent_change_24h * 1}%</div>
                                        </div>
                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndexMain;
