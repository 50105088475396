import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'

import copyIcon from '../../theme/assets/svg/omt_settings_copy_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {changePassword, changePasswordFailure, changePasswordSuccess} from "../../store/cabinet/action";
import {toast} from "react-toastify";



const SettingsMain = () => {
    const userData = useSelector(store => store.main.userData);
    const changePasswordStatus = useSelector(store => store.cabinet.changePasswordStatus);
    const changePasswordStatusErr = useSelector(store => store.cabinet.changePasswordStatusErr);


    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [old_password, setOld_password] = useState('');


    const changeBtnHandler = () =>{
        dispatch(changePassword({password, old_password, password_confirmation}));
    }

    useEffect(()=>{
        if (changePasswordStatus){
            setPassword('');
            setPassword_confirmation('');
            setOld_password('');
            dispatch(changePasswordFailure(false));
            dispatch(changePasswordSuccess(false));
            toast(t('Ваш пароль успешно изменен!'))
        }
    },[changePasswordStatus, dispatch, t])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={` ${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrapItem} ${Styles.contentWrapItemReposition}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Основная информация')}</div>
                    <div className={`${Styles.contentWrapItemBox}`}>
                        <div className={`${Styles.inputWrap} ${Styles.inputWrapWithCopyBtn}`}>
                            <div className={`${Styles.inputTitle}`}>ID</div>
                            <div className={`${Styles.fakeInput}`}>
                                20931
                                <div className={`${Styles.fakeInputCopyBtn}`}>
                                    <img src={copyIcon} alt="copy id btn"/>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.inputWrap} ${Styles.inputWrapZero}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Имя пользователя')}</div>
                            <div className={`${Styles.fakeInput}`}>FriedBrock</div>
                        </div>
                        <div className={`${Styles.inputWrap}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Почта')}</div>
                            <div className={`${Styles.fakeInput}`}>{userData?.email}</div>
                        </div>
                        <div className={`${Styles.inputWrap} ${Styles.inputWrapWithSaveBtn} ${Styles.inputWrapZero}`}>
                            {/*<div className={`${Styles.inputTitle}`}>{t('Новое имя пользователя')}</div>*/}
                            {/*<input type="text" readOnly/>*/}
                            {/*<div className={`${Styles.inputSaveBtn}`}>{t('Сохранить')}</div>*/}
                        </div>
                    </div>
                </div>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Пароль')}</div>
                    <div className={`${Styles.contentWrapItemBox}`}>
                        <div className={`${Styles.inputWrap} ${changePasswordStatusErr?.old_password ? Styles.inputWrapWithErr : null}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Старый пароль')}</div>
                            <input
                                type="password"
                                placeholder='***********'
                                onChange={(e)=>{setOld_password(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (changePasswordStatusErr.old_password){
                                        delete changePasswordStatusErr.old_password;
                                        dispatch(changePasswordFailure(changePasswordStatusErr));
                                    }
                                }}
                                value={old_password}
                            />
                            <div className={`${Styles.inputErr}`}>{changePasswordStatusErr?.old_password ? changePasswordStatusErr.old_password : null}</div>
                        </div>
                        <div className={`${Styles.inputWrap} ${changePasswordStatusErr?.password ? Styles.inputWrapWithErr : null}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Новый пароль')}</div>
                            <input
                                type="password"
                                placeholder='***********'
                                onChange={(e)=>{setPassword(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (changePasswordStatusErr.password){
                                        delete changePasswordStatusErr.password;
                                        dispatch(changePasswordFailure(changePasswordStatusErr));
                                    }
                                }}
                                value={password}
                            />
                            <div className={`${Styles.inputErr}`}>{changePasswordStatusErr?.password?.[0] ? changePasswordStatusErr.password?.[0] : null}</div>
                        </div>
                        <div className={`${Styles.inputWrap} ${changePasswordStatusErr?.password_confirmation ? Styles.inputWrapWithErr : null}`}>
                            <div className={`${Styles.inputTitle}`}>{t('Повторить новый пароль')}</div>
                            <input
                                type="password"
                                placeholder='***********'
                                onChange={(e)=>{setPassword_confirmation(e.target.value)}}
                                onKeyDown={(event)=>{
                                    if (changePasswordStatusErr.password_confirmation){
                                        delete changePasswordStatusErr.password_confirmation;
                                        dispatch(changePasswordFailure(changePasswordStatusErr));
                                    }
                                }}
                                value={password_confirmation}
                            />
                            <div className={`${Styles.inputErr}`}>{changePasswordStatusErr?.password_confirmation ? changePasswordStatusErr.password_confirmation : null}</div>
                        </div>
                    </div>
                    <div className={`${Styles.button}`} onClick={()=>{changeBtnHandler()}}>{t('Изменить')}</div>
                </div>
            </div>
        </section>
    );
};

export default SettingsMain;
