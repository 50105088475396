import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

// import bitgetIcon from '../../theme/assets/svg/honee_main_bitget_icon.svg';
// import mexcIcon from '../../theme/assets/svg/honee_main_mexc_icon.svg';
import p2bIcon from '../../theme/assets/svg/h2_p2b_icon.svg';
import {useSelector} from "react-redux";


const TopHeader = () => {
    const { t } = useTranslation();
    const omtPrice = useSelector(store => store.main.omtPrice);
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer} `}>
                <div className={`${Styles.priceWrap}`}>
                    <div className={`${Styles.priceWrapName}`}>{t('Цена OMT')}</div>
                    <div className={`${Styles.priceWrapValue}`}>$ {omtPrice?.rate ? omtPrice?.rate * 1 : '0'}</div>
                </div>
                <div className={`${Styles.tradeWrap}`}>
                    <div className={`${Styles.tradeWrapName}`}>{t('Торгуй OMT')}</div>
                    <div className={`${Styles.tradeWrapValueWrap}`}>
                        <div className={`${Styles.tradeWrapValueWrapItem}`} onClick={()=>{window.open("https://p2pb2b.com/trade/OMT_USDT/", "_blank")}}>
                            <img src={p2bIcon} alt="p2b"/>
                        </div>
                        {/*<div className={`${Styles.tradeWrapValueWrapItem}`}>*/}
                        {/*    <img src={mexcIcon} alt="mexc"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopHeader;