import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAonBalanceVolume = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('On Balance Volume (OBV)')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>On Balance Volume (OBV)</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Technical Description of On Balance Volume (OBV) Settings</div>
                                <div className={`${Styles.paragraph}`}>
                                    On Balance Volume (OBV) is a setting designed to measure the cumulative
                                    volume pressure on price changes. The core hypothesis behind OBV is that
                                    volume precedes price movement, and thus, volume dynamics can serve as a
                                    predictor of future price action. This indicator is particularly useful in volatile
                                    markets like cryptocurrencies, where volume can reflect the actions of large
                                    players, which may not be visible through price analysis alone.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>The Formulas for the Money Flow Index Are:</div>
                                <div className={`${Styles.paragraph}`}>
                                    The OBV setting is calculated based on the previous OBV value, the current
                                    trading volume, and the price change of the asset’s closing price. The formula
                                    for OBV calculation is as follows:
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>if (C<sub>t</sub> > C<sub>t-1</sub>) OBV<sub>t</sub></span> = OBV<sub>t-1</sub> + V<sub>t</sub><br/>
                                    <span className={Styles.colorOrange}>if (C<sub>t</sub> {'<'} C<sub>t-1</sub>) OBV<sub>t</sub></span> = OBV<sub>t-1</sub> - V<sub>t</sub><br/>
                                    <span className={Styles.colorOrange}>if (C<sub>t</sub> = C<sub>t-1</sub>) OBV<sub>t</sub></span> = OBV<sub>t-1</sub><br/>
                                    where: <br/>
                                    <span className={Styles.colorOrange}>OBV<sub>t</sub></span> = OBV value at the current time period (t)<br/>
                                    <span className={Styles.colorOrange}>OBV<sub>t-1</sub></span> = OBV value at the previous time period (t-1)<br/>
                                    <span className={Styles.colorOrange}>V<sub>t</sub></span> =  trading volume for the current period (t)<br/>
                                    <span className={Styles.colorOrange}>C<sub>t</sub></span> = closing price for the current period (t)<br/>
                                    <span className={Styles.colorOrange}>C<sub>t-1</sub></span> = closing price for the previous period (t-1)<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The calculation is based on the accumulation of volume: if the closing price is
                                    higher than the previous period, the volume is added to the current OBV value;
                                    if lower, the volume is subtracted. This creates a cumulative metric reflecting
                                    the strength of buying or selling pressure.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Application of OBV Settings on Different Timeframes (M5, M15, M30)</div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>M5 Timeframe (5 minutes)</div>
                                <div className={`${Styles.paragraph}`}>
                                    On short timeframes like M5, the OBV setting reacts to small changes in price
                                    and volume, making it useful for scalping or intraday trading. The key feature is
                                    that, on such short intervals, OBV is highly sensitive to minor volume
                                    fluctuations, resulting in more frequent but less significant signals.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>M15 Timeframe (15 minutes)</div>
                                <div className={`${Styles.paragraph}`}>
                                    On the M15 timeframe, the OBV setting smooths out short-term fluctuations
                                    and can more clearly identify short-term trends. This interval helps better
                                    understand whether price increases or decreases are supported by consistent
                                    volume, confirming or disproving the strength of the current movement.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>M30 Timeframe (30 minutes)</div>
                                <div className={`${Styles.paragraph}`}>
                                    On the M30 timeframe, the OBV setting highlights more significant trends,
                                    filtering out market noise and short bursts of activity. This makes it particularly
                                    useful for analyzing medium-term trends within a single trading day and allows
                                    for tracking more stable capital flows, reflecting the actions of large players
                                    (often referred to as “smart money”).
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.title2}`}>Conclusion</div>
                                <div className={`${Styles.paragraph}`}>
                                    On Balance Volume is a powerful setting for HoneeAI. Using OBV across
                                    different timeframes allows HoneeAI to differentiate between short-term
                                    fluctuations and longer-term trends. On shorter timeframes (M5), OBV can be
                                    helpful for quick speculative trades but requires additional signal filtering. On
                                    M15 and M30 timeframes, OBV helps identify more reliable entry and exit
                                    signals based on volume dynamics, making it suitable for intraday trading and
                                    analyzing the sentiment of large market participants.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAonBalanceVolume;