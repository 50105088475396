import axios from '../../helpers/interceptors';

import {
    TEST,
    SET_AUTHORIZATION_STATUS,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,
    SET_HASH_STATUS,
    SET_NEW_DEVICE_STATUS,
    LOGIN_WITH_HASH_STARTED,
    LOGIN_WITH_HASH_SUCCESS,
    LOGIN_WITH_HASH_FAILURE,
    SET_CONFIRMATION_STEP,
    GET_PHRASE_STARTED,
    GET_PHRASE_SUCCESS,
    GET_PHRASE_FAILURE,
    SET_PHRASE_STARTED,
    SET_PHRASE_SUCCESS,
    SET_PHRASE_FAILURE,
    CONFIRMATION_CLEAR,
    SET_MOBILE_WP_NAV_STATUS,
    SET_MOBILE_NAV_STATUS,
    GET_SERVER_STARTED,
    GET_SERVER_SUCCESS,
    GET_SERVER_FAILURE,
    CHOICE_SERVER_STARTED,
    CHOICE_SERVER_SUCCESS,
    CHOICE_SERVER_FAILURE,
    GET_OMT_PRICE_STARTED,
    GET_OMT_PRICE_SUCCESS,
    GET_OMT_PRICE_FAILURE,
    GET_LATEST_NEWS_STARTED,
    GET_LATEST_NEWS_SUCCESS,
    GET_LATEST_NEWS_FAILURE,
    GET_MAIN_NEWS_STARTED,
    GET_MAIN_NEWS_SUCCESS,
    GET_MAIN_NEWS_FAILURE,
    GET_POST_STARTED,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    GET_TOP_NEWS_STARTED,
    GET_TOP_NEWS_SUCCESS,
    GET_TOP_NEWS_FAILURE,
    SET_GREETING_MODAL_STATUS,
    SET_SERVER_MODAL_STATUS
} from "./types";

import {api} from "../../api";
import {clearCabinetState} from "../cabinet/action";
// // axios.defaults.withCredentials = true;
const host = api.host;


export const test = data => ({
    type: TEST,
    payload: data
});

export const setAuthorizationStatus = status => ({
    type: SET_AUTHORIZATION_STATUS,
    payload: status
});

export const setGreetingModalStatus = status => ({
    type: SET_GREETING_MODAL_STATUS,
    payload: status
});

export const setServerModalStatus = status => ({
    type: SET_SERVER_MODAL_STATUS,
    payload: status
});

export const setConfirmationStep = step => ({
    type: SET_CONFIRMATION_STEP,
    payload: step
});

export const setHashStatus = status => ({
    type: SET_HASH_STATUS,
    payload: status
});

export const setNewDeviceStatus = status => ({
    type: SET_NEW_DEVICE_STATUS,
    payload: status
});

export const confirmationClear = () => ({
    type: CONFIRMATION_CLEAR
});

export const setMobileWpNavStatus = status => ({
    type: SET_MOBILE_WP_NAV_STATUS,
    payload: status
});

export const setMobileNavStatus = status => ({
    type: SET_MOBILE_NAV_STATUS,
    payload: status
});

export const registration = (formData) => {
    let url = host + api.registration;

    return dispatch => {
        dispatch(registrationStarted());
        axios.post(url, formData)
            .then(res => {
                localStorage.setItem('honee2-token', res.data.response.token);
                dispatch(registrationSuccess(res.data.response));
                dispatch(setAuthorizationStatus({authorization: true, phrase: false, server: false}));
            })
            .catch(err => {
                dispatch(registrationFailure(err.response.data.errors));
            });
    };
};

const registrationStarted = () => ({
    type: REGISTRATION_STARTED
});

export const registrationSuccess = serverReport => ({
    type: REGISTRATION_SUCCESS,
    payload: serverReport
});

export const registrationFailure = error => ({
    type: REGISTRATION_FAILURE,
    payload: error
});


export const login = (formData) => {
    let url = host + api.login;


    return dispatch => {
        dispatch(loginStarted());
        axios.post(url, formData)
            .then(res => {
                if (res.data.response.token){
                    localStorage.setItem('honee2-token', res.data.response.token);
                    if (res.data.response.backup_phrase_copied){
                        localStorage.setItem('honee2-phrase-status', true);
                    }
                    if (res.data.response.server_applied){
                        localStorage.setItem('honee2-server', true);
                    }
                    dispatch(loginSuccess(res.data.response));
                    dispatch(setAuthorizationStatus({authorization: true, phrase: !!res.data.response.backup_phrase_copied, server: !!res.data.response.server_applied}));
                } else {
                    dispatch(setHashStatus(false));
                    dispatch(setNewDeviceStatus(res.data.response.hash));
                }
            })
            .catch(err => {
                dispatch(loginFailure(err.response.data.errors));
            });
    };
};

const loginStarted = () => ({
    type: LOGIN_STARTED
});

export const loginSuccess = serverReport => ({
    type: LOGIN_SUCCESS,
    payload: serverReport
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error
});


export const loginWithHash = (formData) => {
    let url = host + api.loginWH;


    return dispatch => {
        dispatch(loginWithHashStarted());
        axios.post(url, formData)
            .then(res => {
                localStorage.setItem('honee2-token', res.data.response.token);
                if (res.data.response.backup_phrase_copied){
                    localStorage.setItem('honee2-phrase-status', true);
                }
                if (res.data.response.server_applied){
                    localStorage.setItem('honee2-server', true);
                }
                dispatch(loginWithHashSuccess(res.data.response));
                dispatch(setAuthorizationStatus({authorization: true, phrase: !!res.data.response.backup_phrase_copied, server: !!res.data.response.server_applied}));
                dispatch(setNewDeviceStatus(false));
            })
            .catch(err => {
                if (err.response.status === 308){
                    dispatch(setNewDeviceStatus(false));
                    dispatch(setHashStatus(true));
                }
                dispatch(loginWithHashFailure(err.response.data.errors));
            });
    };
};

const loginWithHashStarted = () => ({
    type: LOGIN_WITH_HASH_STARTED
});

export const loginWithHashSuccess = serverReport => ({
    type: LOGIN_WITH_HASH_SUCCESS,
    payload: serverReport
});

export const loginWithHashFailure = error => ({
    type: LOGIN_WITH_HASH_FAILURE,
    payload: error
});


export const resetPassword = (formData) => {
    let url = host + api.resetPassword;

    return dispatch => {
        dispatch(resetPasswordStarted());
        axios.post(url, formData)
            .then(res => {
                dispatch(resetPasswordSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(resetPasswordFailure(err.response.data.errors));
            });
    };
};

const resetPasswordStarted = () => ({
    type: RESET_PASSWORD_STARTED
});

export const resetPasswordSuccess = serverReport => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: serverReport
});

export const resetPasswordFailure = error => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error
});


export const logout = () => {
    let url = host + api.logout;
    const token = localStorage.getItem('honee2-token');

    return dispatch => {
        dispatch(logoutStarted());
        axios.post(url, null, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.removeItem('honee2-token');
                localStorage.removeItem('honee2-phrase-status');
                localStorage.removeItem('honee2-server');
                dispatch(setAuthorizationStatus({authorization: false, phrase: false, server: false}));
                dispatch(confirmationClear());
                localStorage.removeItem('honee2-step');
                dispatch(clearCabinetState());
            })
            .catch(err => {
                localStorage.removeItem('honee2-token');
                localStorage.removeItem('honee2-phrase-status');
                localStorage.removeItem('honee2-server');
                dispatch(setAuthorizationStatus({authorization: false, phrase: false, server: false}));
                dispatch(logoutFailure(err));
                dispatch(confirmationClear());
                localStorage.removeItem('honee2-step');
                dispatch(clearCabinetState());
            });
    };
};

const logoutStarted = () => ({
    type: LOGOUT_STARTED
});

export const logoutSuccess = serverReport => ({
    type: LOGOUT_SUCCESS,
    payload: serverReport
});

export const logoutFailure = error => ({
    type: LOGOUT_FAILURE,
    payload: error
});

export const getUserData = () => {
    let url = host + api.user;
    const token = localStorage.getItem('honee2-token');

    return dispatch => {
        dispatch(getUserDataStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.setItem('honee2-phrase-status', true);
                dispatch(getUserDataSuccess(res.data.response));
                if (res.data.response.greeting === false){
                    dispatch(setGreetingModalStatus(!res.data.response.greeting))
                }
            })
            .catch(err => {
                dispatch(getUserDataFailure(err));
                if (err?.response?.status === 401){
                    localStorage.removeItem('honee2-phrase-status');
                    localStorage.removeItem('honee2-token');
                    localStorage.removeItem('honee2-server');
                    dispatch(setAuthorizationStatus({authorization: false, phrase: false, server: false}));
                    dispatch(confirmationClear());
                }
            });
    };
};

const getUserDataStarted = () => ({
    type: GET_USER_DATA_STARTED
});

export const getUserDataSuccess = serverReport => ({
    type: GET_USER_DATA_SUCCESS,
    payload: serverReport
});

export const getUserDataFailure = error => ({
    type: GET_USER_DATA_FAILURE,
    payload: error
});

export const getPhrase = () => {
    let url = host + api.phrase;
    const token = localStorage.getItem('honee2-token');

    return dispatch => {
        dispatch(getPhraseStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getPhraseSuccess(res.data.response.phrase));
            })
            .catch(err => {
                dispatch(getPhraseFailure(err));
            });
    };
};

const getPhraseStarted = () => ({
    type: GET_PHRASE_STARTED
});

export const getPhraseSuccess = serverReport => ({
    type: GET_PHRASE_SUCCESS,
    payload: serverReport
});

export const getPhraseFailure = error => ({
    type: GET_PHRASE_FAILURE,
    payload: error
});

export const setPhraseToConfirmation = (data) => {
    let url = host + api.phrase;
    const token = localStorage.getItem('honee2-token');

    return dispatch => {
        dispatch(setPhraseStarted());
        axios.post(url, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.setItem('honee2-phrase-status', true);
                dispatch(setAuthorizationStatus({authorization: true, phrase: true, server: false}));
                dispatch(setPhraseSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(setPhraseFailure(err.response.data.errors));
            });
    };
};

const setPhraseStarted = () => ({
    type: SET_PHRASE_STARTED
});

export const setPhraseSuccess = serverReport => ({
    type: SET_PHRASE_SUCCESS,
    payload: serverReport
});

export const setPhraseFailure = error => ({
    type: SET_PHRASE_FAILURE,
    payload: error
});

export const getServer = () => {
    let url = host + api.server;
    const token = localStorage.getItem('honee2-token');

    return dispatch => {
        dispatch(getServerStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getServerSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getServerFailure(err));
            });
    };
};

const getServerStarted = () => ({
    type: GET_SERVER_STARTED
});

export const getServerSuccess = serverReport => ({
    type: GET_SERVER_SUCCESS,
    payload: serverReport
});

export const getServerFailure = error => ({
    type: GET_SERVER_FAILURE,
    payload: error
});


export const choiceServer = (id) => {
    let url = host + api.server + `/${id}`;
    const token = localStorage.getItem('honee2-token');

    return dispatch => {
        dispatch(choiceServerStarted());
        axios.post(url, null,  {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.setItem('honee2-server', true);
                dispatch(setAuthorizationStatus({authorization: true, phrase: true, server: true}));
                dispatch(choiceServerSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(choiceServerFailure(err));
            });
    };
};

const choiceServerStarted = () => ({
    type: CHOICE_SERVER_STARTED
});

export const choiceServerSuccess = serverReport => ({
    type: CHOICE_SERVER_SUCCESS,
    payload: serverReport
});

export const choiceServerFailure = error => ({
    type: CHOICE_SERVER_FAILURE,
    payload: error
});


export const getOmtPrice = () => {
    let url = host + api.omtPrice;


    return dispatch => {
        dispatch(getOmtPriceStarted());
        axios.get(url)
            .then(res => {
                dispatch(getOmtPriceSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getOmtPriceFailure(err.response.data.errors));
            });
    };
};

const getOmtPriceStarted = () => ({
    type: GET_OMT_PRICE_STARTED
});

export const getOmtPriceSuccess = serverReport => ({
    type: GET_OMT_PRICE_SUCCESS,
    payload: serverReport
});

export const getOmtPriceFailure = error => ({
    type: GET_OMT_PRICE_FAILURE,
    payload: error
});

export const getLatestNews = () => {
    let url = host + api.news.latest;
    // let url = 'https://apis.omt.technology/api/v1/posts/latest'


    return dispatch => {
        dispatch(getLatestNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getLatestNewsSuccess(res.data.response.posts));
            })
            .catch(err => {
                dispatch(getLatestNewsFailure(err));
            });
    };
};

const getLatestNewsStarted = () => ({
    type: GET_LATEST_NEWS_STARTED
});

export const getLatestNewsSuccess = serverReport => ({
    type: GET_LATEST_NEWS_SUCCESS,
    payload: serverReport
});

export const getLatestNewsFailure = error => ({
    type: GET_LATEST_NEWS_FAILURE,
    payload: error
});

export const getMainNews = (page) => {
    let url;
    if (page){
        url = host + api.news.paginate + `?page=${page}`;
    } else {
        url = host + api.news.paginate;
    }

    // if (page){
    //     // eslint-disable-next-line no-useless-concat
    //     url = 'https://apis.omt.technology/api/v1/posts/pagination' + `?page=${page}`;
    // } else {
    //     url = 'https://apis.omt.technology/api/v1/posts/pagination'
    // }

    return dispatch => {
        dispatch(getMainNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getMainNewsSuccess(res.data.response.posts));
            })
            .catch(err => {
                dispatch(getMainNewsFailure(err));
            });
    };
};

const getMainNewsStarted = () => ({
    type: GET_MAIN_NEWS_STARTED
});

export const getMainNewsSuccess = serverReport => ({
    type: GET_MAIN_NEWS_SUCCESS,
    payload: serverReport
});

export const getMainNewsFailure = error => ({
    type: GET_MAIN_NEWS_FAILURE,
    payload: error
});

export const getPost = (id) => {
    let url = host + api.news.post + `/${id}`;
    // let url = 'https://apis.omt.technology/api/v1/posts' + `/${id}`;

    return dispatch => {
        dispatch(getPostStarted());
        axios.get(url)
            .then(res => {
                dispatch(getPostSuccess(res.data.response.post));
            })
            .catch(err => {
                dispatch(getPostFailure(err));
            });
    };
};

const getPostStarted = () => ({
    type: GET_POST_STARTED
});

export const getPostSuccess = serverReport => ({
    type: GET_POST_SUCCESS,
    payload: serverReport
});

export const getPostFailure = error => ({
    type: GET_POST_FAILURE,
    payload: error
});

export const getTopNews = () => {
    let url = host + api.news.top;
    // let url = 'https://apis.omt.technology/api/v1/posts/top';

    return dispatch => {
        dispatch(getTopNewsStarted());
        axios.get(url)
            .then(res => {
                dispatch(getTopNewsSuccess(res.data.response.posts));
            })
            .catch(err => {
                dispatch(getTopNewsFailure(err));
            });
    };
};

const getTopNewsStarted = () => ({
    type: GET_TOP_NEWS_STARTED
});

export const getTopNewsSuccess = serverReport => ({
    type: GET_TOP_NEWS_SUCCESS,
    payload: serverReport
});

export const getTopNewsFailure = error => ({
    type: GET_TOP_NEWS_FAILURE,
    payload: error
});







