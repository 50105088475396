import {
    TEST,
    SET_AUTHORIZATION_STATUS,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCESS,
    GET_USER_DATA_FAILURE,
    SET_HASH_STATUS,
    SET_NEW_DEVICE_STATUS,
    LOGIN_WITH_HASH_STARTED,
    LOGIN_WITH_HASH_SUCCESS,
    LOGIN_WITH_HASH_FAILURE,
    SET_CONFIRMATION_STEP,
    GET_PHRASE_STARTED,
    GET_PHRASE_SUCCESS,
    GET_PHRASE_FAILURE,
    SET_PHRASE_STARTED,
    SET_PHRASE_SUCCESS,
    SET_PHRASE_FAILURE,
    CONFIRMATION_CLEAR,
    SET_MOBILE_WP_NAV_STATUS,
    SET_MOBILE_NAV_STATUS,
    GET_SERVER_STARTED,
    GET_SERVER_SUCCESS,
    GET_SERVER_FAILURE,
    CHOICE_SERVER_STARTED,
    CHOICE_SERVER_SUCCESS,
    CHOICE_SERVER_FAILURE,
    GET_OMT_PRICE_STARTED,
    GET_OMT_PRICE_SUCCESS,
    GET_OMT_PRICE_FAILURE,
    GET_LATEST_NEWS_STARTED,
    GET_LATEST_NEWS_SUCCESS,
    GET_LATEST_NEWS_FAILURE,
    GET_MAIN_NEWS_STARTED,
    GET_MAIN_NEWS_SUCCESS,
    GET_MAIN_NEWS_FAILURE,
    GET_POST_STARTED,
    GET_POST_SUCCESS,
    GET_POST_FAILURE,
    GET_TOP_NEWS_STARTED,
    GET_TOP_NEWS_SUCCESS,
    GET_TOP_NEWS_FAILURE,
    SET_GREETING_MODAL_STATUS,
    SET_SERVER_MODAL_STATUS
} from "./types";


const initialState = {
    test: false,
    newDeviceStatus: false,
    hashIsNotAlive: false,
    authorizationStatus: {authorization: !!localStorage.getItem('honee2-token'), phrase: !!localStorage.getItem('honee2-phrase-status'), server: !!localStorage.getItem('honee2-server')},
    confirmationStep: localStorage.getItem('honee2-step') ? localStorage.getItem('honee2-step') : false,
    linkScrollStatus: false,
    registrationStarted: false,
    registration: false,
    registrationErr: false,
    loginStarted: false,
    login: false,
    loginErr: false,
    loginWithHashStarted: false,
    loginWithHash: false,
    loginWithHashErr: false,
    resetPasswordStatusStarted: false,
    resetPasswordStatus: false,
    resetPasswordStatusErr: false,
    logoutStarted: false,
    logout: false,
    logoutErr: false,
    userDataStarted: false,
    userData: false,
    userDataErr: false,
    getPhraseStarted: false,
    getPhrase: false,
    getPhraseErr: false,
    setPhraseStarted: false,
    setPhrase: false,
    setPhraseErr: false,
    mobileWpNavStatus: false,
    mobileNavStatus: false,
    serverStarted: false,
    server: false,
    serverErr: false,
    choiceServerStatusStarted: false,
    choiceServerStatus: false,
    choiceServerStatusErr: false,
    omtPriceStarted: false,
    omtPrice: false,
    omtPriceErr: false,
    latestNewsStarted: false,
    latestNews: false,
    latestNewsErr: false,
    mainNewsStarted: false,
    mainNews: false,
    mainNewsErr: false,
    postStarted: false,
    post: false,
    postErr: false,
    topNewsStarted: false,
    topNews: false,
    topNewsErr: false,
    greetingModalStatus: false,
    serverModalStatus: false
};

export function mainReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload
            };
        case SET_NEW_DEVICE_STATUS:
            return {
                ...state,
                newDeviceStatus: action.payload
            };
        case SET_HASH_STATUS:
            return {
                ...state,
                hashIsNotAlive: action.payload
            };
        case SET_CONFIRMATION_STEP:
            return {
                ...state,
                confirmationStep: action.payload
            };
        case SET_AUTHORIZATION_STATUS:
            return {
                ...state,
                authorizationStatus: action.payload
            };
        case REGISTRATION_STARTED:
            return {
                ...state,
                registrationStarted: true
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                registration: action.payload,
                registrationStarted: false
            };
        case REGISTRATION_FAILURE:
            return {
                ...state,
                registrationErr: action.payload,
                registrationStarted: false
            };
        case LOGIN_STARTED:
            return {
                ...state,
                loginStarted: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload,
                loginStarted: false
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loginErr: action.payload,
                loginStarted: false
            };
        case LOGIN_WITH_HASH_STARTED:
            return {
                ...state,
                loginWithHashStarted: true
            };
        case LOGIN_WITH_HASH_SUCCESS:
            return {
                ...state,
                loginWithHash: action.payload,
                loginWithHashStarted: false
            };
        case LOGIN_WITH_HASH_FAILURE:
            return {
                ...state,
                loginWithHashErr: action.payload,
                loginWithHashStarted: false
            };
        case RESET_PASSWORD_STARTED:
            return {
                ...state,
                resetPasswordStatusStarted: true
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordStatus: action.payload,
                resetPasswordStatusStarted: false
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordStatusErr: action.payload,
                resetPasswordStatusStarted: false
            };
        case LOGOUT_STARTED:
            return {
                ...state,
                logoutStarted: true
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logout: action.payload,
                logoutStarted: false
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                logoutErr: action.payload,
                logoutStarted: false
            };
        case GET_USER_DATA_STARTED:
            return {
                ...state,
                userDataStarted: true
            };
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                userDataStarted: false
            };
        case GET_USER_DATA_FAILURE:
            return {
                ...state,
                userDataErr: action.payload,
                userDataStarted: false
            };
        case GET_PHRASE_STARTED:
            return {
                ...state,
                getPhraseStarted: true
            };
        case GET_PHRASE_SUCCESS:
            return {
                ...state,
                getPhrase: action.payload,
                getPhraseStarted: false
            };
        case GET_PHRASE_FAILURE:
            return {
                ...state,
                getPhraseErr: action.payload,
                getPhraseStarted: false
            };
        case SET_PHRASE_STARTED:
            return {
                ...state,
                setPhraseStarted: true
            };
        case SET_PHRASE_SUCCESS:
            return {
                ...state,
                setPhrase: action.payload,
                setPhraseStarted: false
            };
        case SET_PHRASE_FAILURE:
            return {
                ...state,
                setPhraseErr: action.payload,
                setPhraseStarted: false
            };
        case CONFIRMATION_CLEAR:
            return {
                ...state,
                confirmationStep: false,
                getPhrase: false,
                getPhraseErr: false,
                setPhrase: false,
                setPhraseErr: false
            };
        case SET_MOBILE_WP_NAV_STATUS:
            return {
                ...state,
                mobileWpNavStatus: action.payload
            };
        case SET_MOBILE_NAV_STATUS:
            return {
                ...state,
                mobileNavStatus: action.payload
            };
        case GET_SERVER_STARTED:
            return {
                ...state,
                serverStarted: true
            };
        case GET_SERVER_SUCCESS:
            return {
                ...state,
                server: action.payload,
                serverStarted: false
            };
        case GET_SERVER_FAILURE:
            return {
                ...state,
                serverErr: action.payload,
                serverStarted: false
            };
        case CHOICE_SERVER_STARTED:
            return {
                ...state,
                choiceServerStatusStarted: true
            };
        case CHOICE_SERVER_SUCCESS:
            return {
                ...state,
                choiceServerStatus: action.payload,
                choiceServerStatusStarted: false
            };
        case CHOICE_SERVER_FAILURE:
            return {
                ...state,
                choiceServerStatusErr: action.payload,
                choiceServerStatusStarted: false
            };
        case GET_OMT_PRICE_STARTED:
            return {
                ...state,
                omtPriceStarted: true
            };
        case GET_OMT_PRICE_SUCCESS:
            return {
                ...state,
                omtPrice: action.payload,
                omtPriceStarted: false
            };
        case GET_OMT_PRICE_FAILURE:
            return {
                ...state,
                omtPriceErr: action.payload,
                omtPriceStarted: false
            };
        case GET_LATEST_NEWS_STARTED:
            return {
                ...state,
                latestNewsStarted: true
            };
        case GET_LATEST_NEWS_SUCCESS:
            return {
                ...state,
                latestNews: action.payload,
                latestNewsStarted: false
            };
        case GET_LATEST_NEWS_FAILURE:
            return {
                ...state,
                latestNewsErr: action.payload,
                latestNewsStarted: false
            };
        case GET_MAIN_NEWS_STARTED:
            return {
                ...state,
                mainNewsStarted: true
            };
        case GET_MAIN_NEWS_SUCCESS:
            return {
                ...state,
                mainNews: action.payload,
                mainNewsStarted: false
            };
        case GET_MAIN_NEWS_FAILURE:
            return {
                ...state,
                mainNewsErr: action.payload,
                mainNewsStarted: false
            };
        case GET_POST_STARTED:
            return {
                ...state,
                postStarted: true
            };
        case GET_POST_SUCCESS:
            return {
                ...state,
                post: action.payload,
                postStarted: false
            };
        case GET_POST_FAILURE:
            return {
                ...state,
                postErr: action.payload,
                postStarted: false
            };
        case GET_TOP_NEWS_STARTED:
            return {
                ...state,
                topNewsStarted: true
            };
        case GET_TOP_NEWS_SUCCESS:
            return {
                ...state,
                topNews: action.payload,
                topNewsStarted: false
            };
        case GET_TOP_NEWS_FAILURE:
            return {
                ...state,
                topNewsErr: action.payload,
                topNewsStarted: false
            };
        case SET_GREETING_MODAL_STATUS:
            return {
                ...state,
                greetingModalStatus: action.payload,
            };
        case SET_SERVER_MODAL_STATUS:
            return {
                ...state,
                serverModalStatus: action.payload,
            };
        default:
            return state;
    }
}

